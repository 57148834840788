<template>
  <div class="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-100">
    <div class="conteudo-modal-editar flex flex-col md:flex-row md:items-start gap-6">
      <form class="form-editar text-white w-full" @submit.prevent="putDadosEditado">
        <div class="flex justify-end mb-4">
          <button @click="$emit('fecharModal')">
            <i class="fas fa-close text-2xl text-corBase"></i>
          </button>
        </div>
        <h2 class="text-lg text-corBase font-bold mb-4">Editar Dados da Tipologia</h2>

        <div class="flex flex-col md:flex-row md:gap-6">
          <!-- Seção da Imagem -->
          <div class="w-full md:w-1/3 flex flex-col items-center mb-6 md:mb-0">
            <label class="text-corBase text-xl font-bold mb-2">Imagem:</label>
            <div
              class="imagePreviewWrapper bg-cover bg-center w-[200px] h-[200px] border border-gray-300 rounded-md mb-4"
              :style="{ 'background-image': `url(${tipologia.imagem?.url_documento || ''})` }"></div>
            <button @click.prevent="abrir_galeria" class="selecionar-arquivo py-2 px-4">
              <i class="fa-solid fa-image fa-lg"></i>
              <span v-if="!tipologia.imagem">Escolha uma Imagem</span>
              <span v-else>Trocar Imagem</span>
            </button>
          </div>

          <!-- Campos do Formulário -->
          <div class="w-full md:w-2/3 flex flex-col gap-4">
            <div>
              <label class="text-base text-corBase font-bold mb-1">Modelo:</label>
              <v-select v-model="tipologia.modelo" :options="listaModeloVariacao" label="text" class="w-full bg-white"
                placeholder="Selecione o modelo" :reduce="modelo => modelo.id" />
            </div>
            <div>
              <label class="text-base text-corBase font-bold mb-1">Código:</label>
              <input type="text" required class="input-campos" v-model="tipologia.codigo">
            </div>
            <div>
              <label class="text-base text-corBase font-bold mb-1">Descrição:</label>
              <input type="text" required class="input-campos" v-model="tipologia.nome">
            </div>
            <div>
              <label class="text-base text-corBase font-bold mb-1 mr-2">Ativo:</label>
              <span @click.prevent="toggleAtivo" class="text-center pt-1 text-xl cursor-pointer">
                <i v-if="tipologia.ativo" class="fa-solid fa-circle-check text-ativo"></i>
                <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
              </span>
            </div>
          </div>
        </div>

        <!-- Botão de Salvar -->
        <div class="flex justify-end mt-6">
          <button class="btn-formulario-salvar py-2 px-6" type="submit">Salvar</button>
        </div>
      </form>
    </div>

    <Galeria v-if="showGaleria" @fechar_galeria="abrir_galeria" @objetoClicado="atualizarFileInput" />
    <SucessoRapido v-if="sucesso" mensagem="Tipologia atualizada com sucesso!" />
    <ErroRapido v-if="erro" :mensagem="erroMessage" />
  </div>
</template>


<script>
import axios from "axios";
import vSelect from "vue-select";
import Galeria from "../../ged/Galeria.vue";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ErroRapido from "@/components/toasts/toast_erro/ErroRapido.vue";

export default {
  name: "EditarTipologia",

  data() {
    return {
      statusModal: false,
      imagem: {},
      tipologia: [],
      showGaleria: false,
      listaModeloVariacao: [],

      sucesso: false,
      erro: false,
      erroMessage: ''

    }
  },

  props: ['id_tipologia'],

  emits: ['fecharModal', 'atualizarLista'],

  components: {
    vSelect,
    Galeria,
    SucessoRapido,
    ErroRapido
  },

  created() {
    this.getListaProjetistaSistemista()
    this.getDadosTipologia()
  },

  // mounted() {
  //   window.addEventListener('keydown', this.onEscKeyPressed);

  // },

  // beforeDestroy() {
  //   window.removeEventListener('keydown', this.onEscKeyPressed);
  // },

  // watch: {
  //   statusModal(newStatus) {
  //     if (newStatus) {
  //       window.addEventListener('keydown', this.onEnterKeyPressed);
  //     } else {
  //       window.removeEventListener('keydown', this.onEnterKeyPressed);
  //     }
  //   }
  // },

  methods: {
    atualizarFileInput(file) {
      this.tipologia.imagem = file;
    },

    abrir_galeria() {
      this.showGaleria = !this.showGaleria
    },

    toggleAtivo(event) {
      event.preventDefault()
      this.tipologia.ativo = !this.tipologia.ativo
    },

    onEscKeyPressed(event) {
      if (event.key === 'Escape') {
        this.statusModal = false
        this.$parent.getListaTipologias()
      }
    },

    onEnterKeyPressed(event) {
      if (event.key === 'Enter') {
        this.putDadosEditado()
      }
    },

    async getDadosTipologia(){
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')
      try {
        const response = await axios.get(`/projeto/tipologia/${this.id_tipologia}`, {
          headers: {
            Authorization: `Token ${token}`
          }
        })

        this.tipologia = response.data
        this.tipologia.modelo = response.data.modelo.id
      }catch(error){
        console.error(error)
      }
    },

    async putDadosEditado() {
      const dadosAtualizados = new FormData();
      dadosAtualizados.append('ativo', this.tipologia.ativo)
      dadosAtualizados.append('nome', this.tipologia.nome)
      dadosAtualizados.append('modelo', this.tipologia.modelo)
      dadosAtualizados.append('codigo', this.tipologia.codigo)
      dadosAtualizados.append('imagem', this.tipologia.imagem.id)


      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')
      try {

        const response = await axios.patch(`projeto/tipologia/${this.tipologia.id}/`, dadosAtualizados, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        });
        // console.log(response);
        this.statusModal = false;

        this.sucesso = true
        setTimeout(() => {
          this.sucesso = false;
          this.$emit('fecharModal');
          this.$emit('atualizarLista')
        }, 3000);

      } catch (error) {
        console.log(error.response.data.codigo[0])
        if (error.response && error.response.data.codigo[0]) {
          this.erro = true
          this.erroMessage = error.response.data.codigo[0]
          setTimeout(() => {
            this.erro = false;
            this.erroMessage = ''
          }, 3000);
        } else {
          console.log(error.response.data.codigo[0]);
          this.erro = true
          this.erroMessage = 'Ocorreu um erro ao salvar a Tipologia.'
          setTimeout(() => {
            this.erro = false;
            this.erroMessage = ''
          }, 3000);
        }
      }
    },

    async getListaProjetistaSistemista() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');
      try {

        const response = await axios.get('projeto/modelo-variacao/?page_size=999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.listaModeloVariacao = response.data.results.map(item => ({
          id: item.id,
          text: item.descricao
        }));
        // console.log(this.listaModeloVariacao)
      } catch (error) {
        console.error('Erro ao buscar os projetistas sistemistas:', error);
      }
    },
  }

}
</script>

<style scoped>
.conteudo-modal-editar {
  border-radius: 8px;
  padding: 20px;
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.imagePreviewWrapper {
  width: 200px;
  height: 200px;
  background-color: #f5f5f5;
  /* Cor de fundo padrão */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ccc;
  border-radius: 8px;
}

.input-campos {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}
</style>