<template>
  <button class="ml-3 text-ativo" @click.prevent="modalPessoa">
    <i class="fas fa-plus-circle"></i>
  </button>

  <div class="container-modal-cadastro" v-if="modalTipoPessoa">
    <div class="conteudo-modal">
      <form class="formulario-modal" @submit.prevent="cadastarTipoPessoa">
        <div class="flex justify-end">
          <button @click.prevent="fecharModal">
            <i class="fas fa-close text-2xl"></i>
          </button>
        </div>
        <h2 class="titulo-formulario">Cadastro Tipo Pessoa Geral</h2>
        <label class="label-formulario-modal">Sigla:</label>
        <input type="text"
               required
               class="input-campos"
               maxlength="3"
               minlength="3"
               v-model="sigla"
        >

        <label class="label-formulario-modal">Descrição:</label>
        <input type="text"
               required
               class="input-campos"
               v-model="descricao"
        >

        <div class="btn-formulario-modal">
          <button class="btn-formulario" type="submit">
            Cadastrar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TipoPessoaGeralModal",
  data() {
    return {
      modalTipoPessoa: false,
      sigla: '',
      descricao: '',
    }
  },

  methods: {
    modalPessoa() {
      this.modalTipoPessoa = !this.modalTipoPessoa;
    },

    fecharModal() {
      this.modalTipoPessoa = !this.modalTipoPessoa;
    },

    async cadastarTipoPessoa() {
      try {
        const tipo_pessoa_geral = { sigla: this.sigla, descricao: this.descricao };

        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');
        const response = await axios.post('pessoas/tipo-pessoas', tipo_pessoa_geral, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        this.fecharModal();
        this.sigla = '';
        this.descricao = '';
      } catch (error) {
        this.erro = error.response.data.erro;
        console.error(this.erro);
      }
    }
  }
}
</script>

<style scoped>
/* Estilo do modal pequeno */
.container-modal-cadastro {
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.conteudo-modal {
  @apply p-4 rounded-2xl;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  max-width: 300px;
  background-color: transparent;
}

.formulario-modal {
  @apply flex flex-col justify-center text-corBase bg-white p-4 rounded-lg;
}

.titulo-formulario {
  @apply text-lg text-corBase font-bold mb-2;
}

.label-formulario-modal {
  @apply text-base font-bold mb-2 mt-2 text-corBase;
}

.input-campos {
  @apply w-full rounded-lg text-corBase/70 p-2
}

.btn-formulario-modal {
  @apply flex justify-end text-screenCor mt-4;
}

.btn-formulario {
  @apply bg-ativo py-1 px-3;
}
</style>
