<template>
  <div class="container-formulario pb-10">
    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Entrada/Saida Estoque</h1>
      </div>

      <router-link to="/estoque/entrada-saida" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <div class="w-full border p-4 flex flex-col md-flex-row gap-6">
      <div class="flex flex-col gap-2 w-full md:w-[20%]">
        <label class="font-bold text-corBase">Tipo da Movimentação</label>
        <select class="input-form-chapa" v-model="tipo_movimentacao">
          <option value="entrada manual">Entrada Manual</option>
          <option value="saida">Saida</option>
        </select>
      </div>
    </div>

    <!-- se for entrada manual -->
    <div v-if="tipo_movimentacao === 'entrada manual'" class="mt-4 w-full border p-4 flex flex-col gap-6">
      <div class="border border-gray-200 p-2 mt-3" v-for="(produto, index) in produtos" :key="index">
        <div class="flex justify-end">
          <div @click.prevent="removerProduto(index)" class="flex justify-center items-center cursor-pointer p-2 w-min">
            <i class="fa-solid fa-x"></i>
          </div>
        </div>

        <div class="flex flex-col md:flex-row w-full">
          <div class="campo-chapa">
            <label class="text-xl text-corBase flex items-center">
              Produto:
              <i class="fa-solid fa-question ml-2 text-white bg-gray-500 p-1 rounded-full text-xs cursor-pointer"
                title="Digite as 3 primeiras letras do produto para buscar"></i>
            </label>
            <v-select v-model="produto.produto" :options="lista_produto_fornecedor" label="codigo_fornecedor"
              class="w-full" :clearable="false" @input="onSelect(produto, index)" @search="buscarProdutosPorFornecedor">
              <template v-slot:option="option">
                <div>
                  <strong>{{ option.codigo_fornecedor }}</strong> -
                  <span>{{ option.nome_especifico }}</span>
                </div>
              </template>
            </v-select>
          </div>

          <div class="campo-chapa">
            <label class="text-xl text-corBase">Quantidade:</label>
            <input class="input-form-chapa" type="number" min="0" max="999999999" v-model="produto.qtd_produtos" />
          </div>
          <div class="campo-chapa">
            <label class="text-xl text-corBase">Preço de Custo:</label>
            <input class="input-form-chapa" type="number" v-model="produto.preco_custo" />
          </div>
        </div>
      </div>
      <button @click.prevent="adicionarProduto"
        class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo text-white hover:text-ativo py-2 px-4 mt-2 mr-2">
        Adicionar Produto
      </button>
    </div>

    <!-- se for saída manual -->
    <div v-if="tipo_movimentacao === 'saida'" class="mt-4 w-full border p-4 flex flex-col gap-6">
      <div class="border border-gray-200 p-2 mt-3" v-for="(produto, index) in produtos" :key="index">
        <div class="flex justify-end">
          <div @click.prevent="removerProduto(index)" class="flex justify-center items-center cursor-pointer p-2 w-min">
            <i class="fa-solid fa-x"></i>
          </div>
        </div>
        <div class="flex flex-col md:flex-row w-full">
          <div class="campo-chapa">
            <label class="text-xl text-corBase flex items-center">
              Produto:
              <i class="fa-solid fa-question ml-2 text-white bg-gray-500 p-1 rounded-full text-xs cursor-pointer"
                title="Digite as 3 primeiras letras do produto para buscar"></i>
            </label>
            <v-select v-model="produto.produto" :options="lista_produto_fornecedor" label="codigo_fornecedor"
              class="w-full" :clearable="false" @input="onSelect(produto, index)" @search="buscarProdutosPorFornecedor">
              <template v-slot:option="option">
                <div>
                  <strong>{{ option.codigo_fornecedor }}</strong> -
                  <span>{{ option.nome_especifico }}</span>
                </div>
              </template>
            </v-select>
          </div>

          <div class="campo-chapa">
            <label class="text-xl text-corBase">Quantidade:</label>
            <input class="input-form-chapa" type="number" min="0" max="999999999" v-model="produto.qtd_produtos" />
          </div>

          <div class="campo-chapa">
            <label class="text-xl text-corBase">Motivo Saída:</label>
            <input class="input-form-chapa" type="text" v-model="motivo_saida" required min="10" max="255" />
          </div>
        </div>
      </div>
      <button @click.prevent="adicionarProduto"
        class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo text-white hover:text-ativo py-2 px-4 mt-2 mr-2">
        Adicionar Produto
      </button>
    </div>

    <button v-if="tipo_movimentacao === 'entrada manual'" @click.prevent="entradaManual"
      class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo text-white hover:text-ativo py-2 px-4 mt-2 mr-2">
      Dar Entrada
    </button>
    <button v-if="tipo_movimentacao === 'saida'" @click.prevent="saidaManual"
      class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo text-white hover:text-ativo py-2 px-4 mt-2 mr-2">
      Dar Saida
    </button>
    <mensagem-sucesso v-if="sucesso === true && tipo_movimentacao === 'entrada manual'"
      mensagem="Entrada Realizada com Sucesso!" rotas="/estoque/entrada-saida" />
    <mensagem-sucesso v-if="sucesso === true && tipo_movimentacao === 'saida'" mensagem="Saida Realizada com Sucesso!"
      rotas="/estoque/entrada-saida" />
    <erro-rapido v-if="erro === true && tipo_movimentacao === 'saida'" :mensagem="erroMessage" />
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import ErroRapido from "../toasts/toast_erro/ErroRapido.vue";
import debounce from "lodash/debounce";

export default {
  name: "EntradaSaidaEstoque",
  components: {
    MensagemSucesso,
    ErroRapido,
    vSelect,
  },

  data() {
    return {
      tipo_movimentacao: "",
      numero_nota: "",
      lista_fornecedor: [],
      lista_produto_fornecedor: [],
      fornecedor: "",
      data_emissao_nf: "",
      motivo_saida: '',
      produtos: [],
      sucesso: false,
      erro: false,
      erroMessage: '',
    };
  },

  methods: {
    adicionarProduto() {
      this.produtos.push({
        produto: "",
        qtd_produtos: 0,
        preco_custo: 0,
      });
    },

    removerProduto(index) {
      this.produtos.splice(index, 1)
    },

    async buscarProdutosPorFornecedor(searchQuery) {
      if (searchQuery.length > 2) {
        const token = await this.$store.dispatch("getAuthTokenCookie");
        await this.$store.dispatch("fetchURLrequest");

        try {
          const response = await axios.get(`produtos/produto-por-fornecedor/?codigo_fornecedor=${searchQuery}&page_size=9999`, {
            headers: {
              Authorization: `Token ${token}`,
            },
          });
          this.lista_produto_fornecedor = response.data.results.map((item) => ({
            id: item.id,
            nome_especifico: item.nome_especifico,
            codigo_fornecedor: item.codigo_fornecedor,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    },

    onSelect(produto, index) {
      this.produtos[index].produto = produto.id;
    },

    async entradaManual() {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");
      const data = {
        tipo_movimentacao: "ENTRADA",
        motivo_movimentacao: "ENTRADA MANUAL",
        produtos: this.produtos.map((item) => ({
          produto: item.produto.id,
          qtd_produtos: item.qtd_produtos,
          preco_custo: item.preco_custo,
        })),
      };
      this.$store.dispatch("setLoading", true);
      this.sucesso = false;

      try {
        const response = await axios.post("produtos/movimentacao/", data, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        console.log(response.data);
        this.$store.dispatch("setLoading", false);
        this.produtos = [];
        this.sucesso = true;
        setTimeout(() => {
          this.sucesso = false;
        }, 3000);
      } catch (err) {
        console.error(err);
        this.$store.dispatch("setLoading", false);
      }
    },

    async saidaManual() {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      const data = {
        tipo_movimentacao: "SAIDA",
        motivo_movimentacao: "SAIDA MANUAL",
        motivo_saida: this.motivo_saida,
        produtos: this.produtos.map((item) => ({
          produto: item.produto.id,
          qtd_produtos: item.qtd_produtos,
          preco_custo: item.preco_custo,
        })),
      };
      this.$store.dispatch("setLoading", true);

      try {
        const response = await axios.post("produtos/movimentacao/", data, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        console.log(response.data);
        this.$store.dispatch("setLoading", false);
        this.produtos = [];
        this.sucesso = true;
        setTimeout(() => {
          this.sucesso = false;
        }, 3000);
      } catch (err) {
        this.$store.dispatch("setLoading", false);
        console.log(err.message);
        if (err.response) {
          this.erro = true;
          this.erroMessage = `${err.response.data.error}`
          setTimeout(() => {
            this.erro = false;
            this.erroMessage = ''
          }, 3000);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* Estilos adicionais, se necessários */
</style>
