<template>

<div class="">

</div>

  <preview-image 
    v-if="preview_imagem" 
    @closed="closed_preview_image" 
    :imagem="preview_imagem"
  />

  <div class="container-modelo-tabela">
    <table class="container-tabela overflow-auto">
      <thead class="cabecalho-tabela">
      <tr>
        <th scope="col" class="px-3 py-3">
          #
        </th>
        <th scope="col" class="p-4">
          <div class="flex items-center">
            <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

            <label class="sr-only">checkbox</label>
          </div>
        </th>
        <th class="px-3 py-3">
          Componente
        </th>
        <th class="px-3 py-3">
          Imagem
        </th>
        <th scope="col" class="px-3 py-3">
          Descrição
        </th>
        <th scope="col" class=" py-3">
          QTDE
        </th>
        <th scope="col" class=" py-3">
          Condição
        </th>
        <th scope="col" class=" py-3">
          Observação
        </th>
        <th scope="col" class="py-3">
          Fase Montagem
        </th>
        <th scope="col" class=" py-3">
          Aplicação
        </th>
      </tr>
      </thead>
      <draggable 
        :list="componentes"
        tag="tbody" 
        handle=".handle"
      >
      <template v-slot:item="{ element, index }">
        <tr :key="index" class="escopo-tabela">
          <th scope="row" class="px-3 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
            <div class="handle cursor-pointer">
              <span>☰</span> <!-- Handle para arrastar -->
              {{ element.index }}
            </div>
          </th>
          <td class="w-4 p-4">
            <div class="selec">
              <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                      v-model="element.selected">
              <label class="sr-only">checkbox</label>
            </div>
          </td>
          <td class="px-3 py-2 text-xs">
            <v-select
              v-model="element.componente_id"
              :options="componentesPesquisados"
              label="codigo_base"
              class="bg-white w-32"
              :clearable="false"
              @input="(s) => buscarComponentes(s.target.value)"
              @keydown.enter="atualizarUnicoComponente(element.componente_id, element.index)"
            >
              <template v-slot:option="option">
                <div @click.prevent="atualizarUnicoComponente(option, element.index)">
                  <span class="font-bold">
                    {{ option.codigo_base }}
                  </span> - 
                  {{ option.nome_base }}
                </div>
              </template>
            </v-select>
          </td>
          <td class="px-3 py-2 text-xs w-[80px]">
            <img 
              v-if="element.componente_id.imagem"
              :src="element.componente_id.imagem.url_documento"
              class="cursor-pointer"
              @click="openPreviewImage(element.componente_id.imagem.url_documento)"
            >

            <img 
              v-else
              src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
            >
          </td>
          <td class="px-3 w-60 py-2 text-xs">
            {{ element.componente_id.nome_base }}
          </td>
          <td class=" py-2 text-xs w-[13vw]">
            <input class="input-campos" v-model="element.qtd_formula">
          </td>
          <td class=" py-2 text-xs w-[13vw]">
            <input class="input-campos" v-model="element.condicao">
          </td>
          <td class=" py-2 text-xs w-[13vw]">
            <input class="input-campos" v-model="element.observacao">
          </td>
          <td class="py-2 text-xs w-[9vw]">
            <select v-model="element.fase_esquadria" class="input-campos">
              <option 
                v-for="fase in fasesEsquadria" 
                :value="fase"
                :key="fase.id"
              >
                {{ fase.descricao }}
              </option>
            </select>
          </td>
          <td class=" py-2 text-xs w-[9vw]">
            <select v-model="element.aplicacao" class="input-campos">
              <option 
                v-for="aplicacao in [ 'A Definir', 'Obra', 'Fabrica', 'Embalagem', 'Expedicao' ]"
                :key="aplicacao"
                :value="aplicacao"
              >
                {{ aplicacao }}
              </option>
            </select>
          </td>

        </tr>
      </template>
      </draggable>
    </table>
    <table class="container-tabela overflow-auto" v-if="novosComponentes.length">
      <thead class="cabecalho-tabela">
        <th class="w-[31vw] px-6">
          Busque o Componente:
        </th>
        <th scope="col" class="py-3">
          QTDE
        </th>
        <th scope="col" class="py-3">
          Condição
        </th>
        <th scope="col" class="py-3">
          Observação
        </th>
        <th scope="col" class="py-3">
          Fase Montagem
        </th>
        <th scope="col" class="py-3">
          Aplicação
        </th>
      </thead>
      <tbody>
        <tr v-for="(novoComponente, index) in novosComponentes" :key="index" class="escopo-tabela">
          <td class="px-6 py-2 text-xs">
            <Search
              v-model="componenteSelecionado"
              :options="componentesPesquisados"
              :labelKey="getLabelKey"
              valueKey="id"
              :nameKey="getNameKey"
              @select="onSelect"
              @shouldSearch="buscarComponentes"
            />
          </td>
          <td class="py-2 text-xs w-[13vw]">
            <input class="input-campos" v-model="novoComponente.qtd_formula">
          </td>
          <td class="py-2 text-xs w-[13vw]">
            <input class="input-campos" v-model="novoComponente.condicao">
          </td>
          <td class="py-2 text-xs w-[13vw]">
            <input class="input-campos" v-model="novoComponente.observacao">
          </td>
          <td class="py-2 text-xs w-[9vw]">
            <select v-model="novoComponente.fase_esquadria" class="input-campos">
              <option 
                v-for="fase in fasesEsquadria" 
                :value="fase"
                :key="fase.id"
              >
                {{ fase.descricao }}
              </option>
            </select>
          </td>
          <td class="py-2 text-xs w-[9vw]">
            <select v-model="novoComponente.aplicacao" class="input-campos">
              <option 
                v-for="aplicacao in [ 'A Definir', 'Obra', 'Fabrica', 'Embalagem', 'Expedicao' ]"
                :key="aplicacao"
                :value="aplicacao"
              >
                {{ aplicacao }}
              </option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="flex w-full justify-end itens-center mt-6 px-4 mb-5" v-if="novosComponentes.length">
      <button 
        class="btn-cancelar" 
        @click.prevent="() => novosComponentes = []"
      >
        Cancelar
      </button>
      <button 
        class="btn-salvar" 
        @click.prevent="postNovoComponente">
          Adicionar
      </button>
    </div>

    <div class="flex justify-center" v-if="novosComponentes.length === 0">
      <button class="text-ativo" @click.prevent="adicionarComponente">
        <i class="fas fa-plus-circle"></i>
      </button>
    </div>
  </div>
  <div class="flex w-full justify-end itens-center mt-6">
    <button
      class="btn-cancelar" 
      @click.prevent="modal_confirmacao = true"
      v-if="checkSome || checkAll"
    >
      Excluir Selecionados
    </button>
    <button class="btn-cancelar" @click.prevent="() => $emit('getListaComponentesProjeto')">Cancelar</button>
    <button class="btn-salvar" @click.prevent="atualizarComponentes(componentes)">Salvar</button>
  </div>

  <MensagemConfirmacaoExclusao
    mensagem="Deseja mesmo excluir os itens selecionados?"
    :abrir="modal_confirmacao"
    @feharModal="feharModalConfirmacao"
    @confirmarExclusao="excluirSelecionados"
  />
</template>

<script>
import Search from '@/utils/Search.vue';
import axios from 'axios'
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue'
import draggable from 'vuedraggable'
import Search2 from '@/utils/Search2.vue';
import vSelect from "vue-select";
import PreviewImage from '@/components/midais/ged/PreviewImage.vue';

export default {
  name: "ComponenteProjeto",

  props: ['componentes', 'projeto_id'],

  components: {
    Search,
    MensagemConfirmacaoExclusao,
    draggable,
    Search2,
    vSelect,
    PreviewImage
  },

  created() {
    this.buscaFases()
  },

  computed: {
    checkSome() {
      return this.componentes.some(componente => componente.selected)
    }
  },

  data() {
    return {
      novosComponentes: [],
      componenteSelecionado: {},
      componentesPesquisados: [],
      fasesEsquadria: [],
      preview_imagem: '',

      checkAll: false,
      modal_confirmacao: false,
    }
  },

  methods: {
    closed_preview_image() {
      this.preview_imagem = ''
    },

    openPreviewImage(url) {
      this.preview_imagem = url
    },

    atualizarUnicoComponente(componente, index){
      const componenteProjetoAAtualizarId =  this.componentes[index - 1].id

      this.componentesPesquisados = []
      this.$emit('atualizarUnicoComponente', componente.id, componenteProjetoAAtualizarId)

    },

    atualizarComponentes(componentesAAtualizar) {
      componentesAAtualizar.forEach((componente, index) => {
        componente.index = index + 1
        componente.fase_esquadria = componente.fase_esquadria ? componente.fase_esquadria.id : ''
      })

      this.$emit('atualizarComponentes', componentesAAtualizar)
    },

    getLabelKey(option) {
      return option.codigo_base ? option.codigo_base : ''
    },

    getNameKey(option) {
      return option.nome_base ? option.nome_base : ''
    },


    onSelect(componente) {
      this.componenteSelecionado = componente
    },

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.getElementsByClassName('checkbox');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    excluirSelecionados() {
      if(this.checkAll) {
        this.deletarComponentes(this.componentes)
        this.modal_confirmacao = false
        this.checkAll = false
        return
      }
      const componentesADeletar = this.componentes.filter((componente) => {
        if(componente.selected){
          return componente
        }
      })

      const componentesAAtualizar = this.componentes.filter(componente => 
        !componentesADeletar.some(deletar => deletar.id === componente.id)
      )

      this.deletarComponentes(componentesADeletar)
      this.modal_confirmacao = false
      this.atualizarComponentes(componentesAAtualizar)
    },

    async deletarComponentes(componentesADeletar) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      const componentesADeletarPromisses = []

      for(const componente of componentesADeletar){

        componentesADeletarPromisses.push(
          axios.delete(`projeto/projeto-componente/${componente.id}/`, {
            headers: {
              Authorization: `Token ${token}`
            }
          })
        )
      }

      await Promise.all(componentesADeletarPromisses)

      this.$emit('getListaComponentesProjeto')
    },


    async buscarComponentes(searchQuery) {
      if(searchQuery.length > 2) {
        try {
          const token = await this.$store.dispatch('getAuthTokenCookie');
          await this.$store.dispatch('fetchURLrequest')

          const response = await axios.get(`produtos/produto/?tipo_produto=Componente&filtro_base=${searchQuery}&page_size=9999`, {
            headers: {
              Authorization: `Token ${token}`
            }
          })

          this.componentesPesquisados = response.data.results   
        } catch (error) {
          console.error(error);
        }
      } else {
        this.componentesPesquisados = []
      }
    },

    adicionarComponente() {
      this.novosComponentes.push({
        projeto_id: this.projeto_id,
        componente_id: this.componenteSelecionado,
        qtd_formula: '',
        condicao: '',
        observacao: '',
        aplicacao: '',
        index: this.componentes.length + 1
      })
    },

    async buscaFases() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      try {
        const response = await axios.get(`projeto/fase-esquadria/?page_size=9999`, {
          headers: {
            Authorization: `Token ${token}`
          }
        });
        this.fasesEsquadria = response.data.results;
      } catch (error) {
        console.error(error)
      }
    },

    async postNovoComponente() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      const novosComponentesPromisses = []

      for(const novoComponente of this.novosComponentes){
        novoComponente.componente_id = this.componenteSelecionado.id
        novoComponente.fase_esquadria = novoComponente.fase_esquadria.id

        novosComponentesPromisses.push(
          axios.post('projeto/projeto-componente/', novoComponente, {
            headers: {
              Authorization: `Token ${token}`
            }
          })
        )
      }

      await Promise.all(novosComponentesPromisses)

      this.novosComponentes = []
      this.$emit('getListaComponentesProjeto')

    }
  }
}
</script>

<style scoped>

</style>