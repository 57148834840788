<template>


  <preview-image v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />
  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Imagens</h1>
      </div>

      <router-link to="/ged" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="flex flex-col md:flex-row gap-4 items-end mb-6 z-10">
      <div class="w-full md:w-1/3">
        <label class="text-xl text-corBase">Categoria</label>
        <v-select v-model="categoria" class="w-full z-11" :options="lista_categoria" label="text"
          :reduce="categoria => categoria.id" />
      </div>
      <div class="w-full md:w-1/3">
        <label class="text-xl text-corBase">Subcategoria</label>
        <v-select v-model="subcategoria" class="w-full z-10" :options="lista_subcategoria" label="text"
          :reduce="subcategoria => subcategoria.id" />
      </div>
      <div class="w-full md:w-1/3">
        <label class="text-xl text-corBase">Descrição</label>
        <input v-model="descricao" class="w-full z-10" type="text">
      </div>
      <div class="w-full md:w-auto mt-4 md:mt-0">
        <button class="btn-add-fisica w-full md:w-auto" @click="getListaImagem(1)">Pesquisar</button>
      </div>
    </div>

    <div class="mb-8">
      <button @click="$router.push(`/imagem/cadastro`)" class="btn-salvar mr-2">Adicionar</button>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3 text-start">
              #
            </th>
            <th scope="col" class="px-6 py-3">
              Nome
            </th>
            <th scope="col" class="px-6 py-3">
              Imagem
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Status
            </th>
            <th scope="col" class="px-6 py-3 text-center" v-if="isadmin === true">
              Ações
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(imagem, index) in lista_imagens" :key="index" class="escopo-tabela">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox" v-model="imagem.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row"
              class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white text-start">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-xs">
              {{ imagem.descricao }}
            </td>
            <td class="px-6 py-2 text-xs flex justify-start">
              <img :src="imagem.url_documento" class="w-14 cursor-pointer" alt="imagem sistema"
                @click="openPreviewImage(imagem.url_documento)">
            </td>
            <td class="px-6 py-2 text-center">
              <i v-if="imagem.ativo" class="fa-solid fa-circle-check text-ativo"></i>
              <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
            </td>
            <td class="px-6 py-2 text-xs text-center" v-if="isadmin === true">
              <a @click="definirImagem(imagem)" href="#" class="font-medium text-corBase pl-2 hover:underline">
                <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <Pagination v-if="lista_imagens.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
    </div>
    <EditarImagem v-if="modalEditar" @toggleModal="toggleModalEditar" @atualizarLista="getListaImagem(1)" :imagemId="imagemSelecionada"
      :lista_categoria="lista_categoria" :lista_subcategoria="lista_subcategoria" />
  </div>

</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";
import vSelect from "vue-select";
import PreviewImage from "@/components/midais/ged/PreviewImage";
import EditarImagem from "../midais/Editar/ged/EditarImagem.vue";
import Pagination from "@/utils/Pagination.vue";

export default {
  name: "ListaImagem",

  components: {
    Select2,
    vSelect,
    PreviewImage,
    Pagination,
    EditarImagem
  },

  data() {
    return {
      lista_imagens: [],
      imagem: '',

      checkAll: false,

      lista_categoria: [],
      categoria: '',
      lista_subcategoria: [],
      subcategoria: '',
      descricao: '',

      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      countItens: 0,

      modalEditar: false,
      imagemSelecionada: null,

      preview_imagem: '',
      error: [],
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  created() {
    this.getListaCategoria()
    this.getListaSubcategoria()
  },

  methods: {
    mudarPagina(pagina) {
      this.paginaAtual = pagina;
      this.getListaImagem(pagina);
    },

    closed_preview_image() {
      this.preview_imagem = ''
    },
    openPreviewImage(url) {
      this.preview_imagem = url
    },

    definirImagem(imagem){
      this.imagemSelecionada = imagem.id
      this.toggleModalEditar()
    },

    toggleModalEditar() {
      this.modalEditar = !this.modalEditar
    },

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },


    async getListaImagem(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      this.$store.dispatch("setLoading", true);

      try {
        const response = await axios.get(`ged/documentos/?descricao=${this.descricao}&categoria_id=${this.categoria || ''}&subcategoria_id=${this.subcategoria || ''}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.lista_imagens = response.data.results;
        this.countItens = response.data.count;

      } catch (error) {
        console.error(error);

      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    async getListaCategoria() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      try {
        const response = await axios.get(`ged/categoria/?page_size=9999`, {
          headers: {
            Authorization: `Token ${token}`
          }
        })
        this.lista_categoria = response.data.results.map(item => ({
          id: item.id,
          text: item.nome
        }))

      } catch (error) {
        console.error(error)
      }
    },

    async getListaSubcategoria() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      try {
        const response = await axios.get(`ged/subcategoria/?page_size=9999`, {
          headers: {
            Authorization: `Token ${token}`
          }
        })
        this.lista_subcategoria = response.data.results.map(item => ({
          id: item.id,
          text: item.nome
        }))

      } catch (error) {
        console.error(error)
      }
    },

  }
}
</script>

<style scoped>
input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
</style>