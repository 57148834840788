<template>

  <div class="container-formulario">
    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Criar Nova Tipologia:</h1>

      <router-link to="/lista-tipologia" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarTipologia">
      <!--        PARTE DE DADOS DO PRODUTO-->
      <div class="flex flex-col gap-3 my-3 w-full md:flex-row md:gap-10 ">

        <div class="flex flex-col w-full md:w-[50%] mt-6">
          <label class="text-corBase text-xl font-bold">Imagem:</label>
          <div class="imagePreviewWrapper" :style="{ 'background-image': `url(${previewImage.url_documento})` }"
            @click.prevent="abrir_galeria">
          </div>
          <div class="flex flex-col gap-3 my-3 w-full md:flex-row md:gap-10">
            <button @click.prevent="abrir_galeria" v-if="!previewImage.id">
              <i class="fa-solid fa-image fa-xl"></i>
              <p>Escolha uma Imagem</p>
            </button>

            <!-- Exibe a pré-visualização da imagem quando uma imagem é selecionada -->
            <div v-else>
              <button @click.prevent="abrir_galeria">
                <i class="fa-solid fa-image fa-xl"></i>
                <p>Trocar Imagem</p>
              </button>
              <!-- <img :src="obra.imagem.url_documento" alt="Imagem da Fachada" class="w-[250px] h-[250px]"> -->
            </div>

          </div>

        </div>

        <div class=" flex flex-col w-full gap-4 mr-2 pt-6">

          <div class="campo-chapa">
            <label class="text-xl text-corBase font-bold">Modelo:</label>
            <v-select v-model="modelo" :options="lista_modelo" class="w-full z-10" :reduce="option => option.id"
              label="name" />
          </div>

          <div class="campo-chapa">
            <label class="text-xl text-corBase font-bold">Código:</label>
            <input type="text" v-model="codigo" class="input-form-chapa" required>
          </div>

          <div class="campo-chapa">
            <label class="text-xl text-corBase font-bold">Descrição:</label>
            <input type="text" v-model="nome" class="input-form-chapa" required>
          </div>

        </div>

      </div>


      <div class="flex w-full justify-end">
        <button class="btn-enviar" type="submit">Salvar</button>
      </div>

    </form>
  </div>

  <mensagem-sucesso v-if="sucesso === true" rotas="/lista-tipologia" mensagem="Tipologia cadastrada com sucesso!" />
  <ErroRapido v-if="erro === true" :mensagem="erroMessage" />
  <Galeria v-if="showGaleria === true" @fechar_galeria="abrir_galeria" @objetoClicado="atualizarFileInput" />
</template>

<script>
import Galeria from "@/components/midais/ged/Galeria";
import ModalAddImagem from "@/components/ged/ModalAddImagem";
import axios from "axios";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import ErroRapido from "@/components/toasts/toast_erro/ErroRapido.vue";
import Select2 from "vue3-select2-component";
import vSelect from "vue-select";

export default {
  name: "CadastroTipologia",

  components: {
    MensagemSucesso,
    ErroRapido,
    Select2,
    vSelect,
    Galeria,
    ModalAddImagem
  },


  data() {
    return {
      showGaleria: false,
      ged: false,
      sucesso: false,
      erro: false,
      erroMessage: '',
      previewImage: {},
      nome: '',
      modelo: '',
      codigo: '',
      linhas_sistemas: [],
      lista_modelo: [],

    }
  },

  created() {
    this.getListaModelo()

  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  methods: {
    atualizarFileInput(objeto) {
      console.log('objeto que chegou:' + objeto)
      console.log(objeto)
      this.previewImage = objeto;
      console.log(this.previewImage)
    },

    abrir_galeria() {
      this.showGaleria = !this.showGaleria
    },


    async cadastrarTipologia() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      const data = {
        ativo: true,
        nome: this.nome,
        modelo: this.modelo,
        codigo: this.codigo,
        imagem: this.previewImage.id
      };

      this.$store.dispatch("setLoading", true);
      this.sucesso = false;

      try {
        const response = await axios.post('projeto/tipologia/', data, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        console.log(response.data.results);
        this.nome = '';
        this.modelo = '';
        this.codigo = '';
        this.previewImage = {};
        this.sucesso = true;
      } catch (error) {
        if (error.response.data.codigo[0]) {
          this.erro = true
          this.erroMessage = error.response.data.codigo[0]
          setTimeout(() => {
            this.erro = false;
            this.erroMessage = ''
          }, 3000);
        } else {
          console.log(error.response.data.codigo[0]);
          this.erro = true
          this.erroMessage = 'Ocorreu um erro ao salvar a Tipologia.'
          setTimeout(() => {
            this.erro = false;
            this.erroMessage = ''
          }, 3000);
        }
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },


    // get para modelo e linhas
    async getListaModelo() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/modelo-variacao/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_modelo = response.data.results.map(item => ({
            id: item.id,
            name: item.descricao
          }));
          console.log(response.data.results)
        })
        .catch(error => {
          console.log(error)
        })
    },
  }

}
</script>

<style lang="scss"></style>