<template>
    <div class="container-orcamento">
        <div class="orcamento-info">
            <p>ID Orçamento: {{ id }}</p>
            <br>
            <div v-for="(variavel, index) in variaveis" :key="variavel.id" class="opcao-variavel">
                <h1>{{ variavel.descricao }} ({{ id_variaveis[index] }})</h1>
                <div>
                    <!-- Verifica se há opções disponíveis -->
                    <div v-if="getOpcoesByVariavelId(variavel.id).length > 0">
                        <select v-model="selected[variavel.id]">
                            <option disabled value="">
                                Selecione uma opção
                            </option>
                            <option v-for="opcao in getOpcoesByVariavelId(variavel.id)" :key="opcao.id"
                                :value="opcao.valor">
                                {{ opcao.descricao }}
                            </option>
                        </select>
                    </div>
                    <!-- Se não houver opções, exibe um input numérico com o valor já vindo na requisição -->
                    <div v-else>
                        <input v-model.number="selected[variavel.id]" type="number" min="0"
                            placeholder="Insira um valor numérico" />
                    </div>
                </div>
            </div>
            <!-- Botões de Cancelar e Salvar -->
            <div class="flex w-full justify-end itens-center mt-6">
                <button class="btn-salvar" @click.prevent="salvarVariaveis()">Salvar variáveis</button>
            </div>
        </div>
    </div>
    <SucessoRapido v-if="sucesso" mensagem="Variáveis salvas com sucesso." />
    <ErroRapido v-if="erro" mensagem="Ocorreu um erro ao salvar as variáveis." />
</template>

<script>
import axios from 'axios'
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';

export default {
    name: 'CriarVariaveisOrcamento',
    data() {
        return {
            variaveis: [],
            id_variaveis: [],
            opcoesVariaveis: [],
            selected: {},
            sucesso: false,
            erro: false
        };
    },
    props: {
        id: String,
    },
    components: {
        SucessoRapido,
        ErroRapido
    },
    methods: {
        // Salvar as variáveis alteradas do orçamento
        async salvarVariaveis() {
            const data = {
                variaveis: this.variaveis.map(variavel => {
                    const opcaoSelecionada = this.getOpcoesByVariavelId(variavel.id).find(opcao => opcao.id === this.selected[variavel.id]);
                    return {
                        id: variavel.id,
                        opcao_selecionada: opcaoSelecionada ? opcaoSelecionada.id : null,
                        medida_valor: opcaoSelecionada ? null : this.selected[variavel.id]
                    };
                })
            };

            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')
            try {
                const response = await axios.put(`orcamentos/variavel-local-orcamento-update/`, data, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });
                console.log(response.data);
                this.sucesso = true
                setTimeout(() => {
                    this.sucesso = false;
                    // this.$router.push('/orcamentos')
                }, 3000);
            } catch (error) {
                console.error(error);
                this.erro = true
                setTimeout(() => {
                    this.erro = false;
                }, 3000);
            }
        },

        // Função para buscar as variáveis do orçamento
        async getVariaveisOrcamento() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')
            try {
                const response = await axios.get(`orcamentos/variavel-local-orcamento/?id_orcamento=${this.id}&page_size=999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });
                this.variaveis = response.data.results;
                this.id_variaveis = this.variaveis.map(variavel => variavel.id);
                this.getOpcoesVariaveisOrcamento();
            } catch (error) {
                console.error(error);
            }
        },

        // Função para buscar as opções das variáveis do orçamento
        async getOpcoesVariaveisOrcamento() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')
            try {
                const response = await axios.get(`orcamentos/opcao-variavel-local-orcamento/?id_orcamento=${this.id}&page_size=999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });
                this.opcoesVariaveis = response.data.results;
                this.setInitialSelections();
            } catch (error) {
                console.error(error);
            }
        },

        // Função para definir os valores iniciais caso exista
        setInitialSelections() {
            this.variaveis.forEach(variavel => {
                const opcoes = this.getOpcoesByVariavelId(variavel.id);
                const selectedOpcao = opcoes.find(opcao =>
                    String(opcao.valor) === String(variavel.medida_valor)
                );
                if (selectedOpcao) {
                    this.selected[variavel.id] = selectedOpcao.valor;
                } else {
                    this.selected[variavel.id] = variavel.medida_valor;
                }
            });
        },

        // Função para buscar as opções da variável do orçamento
        getOpcoesByVariavelId(id_variavel) {
            return this.opcoesVariaveis.filter(opcao => opcao.variavel.id === id_variavel);
        },
    },
    created() {
        this.getVariaveisOrcamento();
    },
    watch: {
        id(newId, oldId) {
            if (newId !== oldId) {
                this.getVariaveisOrcamento();
            }
        }
    },
};
</script>

<style scoped>
.container-orcamento {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.opcao-variavel {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
}

h1 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

select, input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f1f1f1;
    margin-top: 5px;
}

button{
    padding: 10px 15px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

</style>
