<template>

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Categorias GED</h1>
      </div>

      <router-link to="/ged" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="mb-8">
      <button @click="$router.push(`/ged/cadastro-categoria`)" class="btn-add-fisica mr-2">Adicionar</button>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              #
            </th>
            <th class="px-6 py-3">
              Nome
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(categoria, index) in lista_categoria" :key="index" class="escopo-tabela">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                  v-model="categoria.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-xs">
              {{ categoria.nome }}
            </td>
            <td class="px-6 py-2 text-center">
              <i v-if="categoria.ativo" class="fa-solid fa-circle-check text-ativo"></i>
              <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
            </td>

          </tr>
        </tbody>
      </table>

      <Pagination v-if="lista_categoria.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
    </div>
  </div>

</template>

<script>
import axios from "axios"
import Pagination from "@/utils/Pagination.vue";

export default {
  name: "ListaCategorias",

  components: {
    Pagination
  },

  data() {
    return {

      checkAll: false,
      // lista usada para salvar o retorno da requisição do get
      lista_categoria: [],
      // varival que inicializa o numero de paginas
      paginaAtual: 1,

      error: [],
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  created() {
    this.getListaCategoria(1)
  },

  methods: {

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    async getListaCategoria(pagina) {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');
        this.$store.dispatch("setLoading", true);

        const response = await axios.get(`ged/categoria/?page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.lista_categoria = response.data.results
        this.countItens = response.data.count
      } catch (error) {
        console.log(error)
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    mudarPagina(pagina) {
      this.getListaCategoria(pagina);
    },

  }
}
</script>

<style lang="scss"></style>