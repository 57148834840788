<template>

  <details>
    <summary class="mt-4">
      <h2 class="bg-screenCor hover:bg-screenCor border border-corBase hover:border-corBase
  text-corBase hover:text-corBase py-2 px-4 ">Endereço
      </h2>
    </summary>

    <button @click.prevent="adicionarEndereco" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 mt-2 mr-2">Adicionar
    </button>

    <div class="border border-gray-200 p-2 mt-3" v-for="(endereco, index) in dadosEndereco" :key="index">
      <div class="container-campos ">
        <div class="campos campos-mobile campos-dasktop-select">
          <label class="label-campos">CEP:</label>
          <input type="text" required placeholder="" class="input-campos" v-mask="'#####-###'" v-model="endereco.cep"
                 @input="buscarCepAutomaticamente(endereco)">
        </div>

        <div class="campos campos-mobile">
          <label class="label-campos">Logradouro:</label>
          <input type="text" required class="input-campos" placeholder="" v-model="endereco.logradouro">
        </div>

        <div class="campos campos-mobile campos-dasktop-select">
          <label class="label-campos">Número:</label>
          <input type="number" required class="input-campos" min="0" placeholder="" v-model="endereco.numero">
        </div>
      </div>

      <div class="container-campos  ">


        <div class="campos-mobile">
          <label class="text-base text-corBase font-bold mt-2">Complemento:</label>
          <input type="text" class="input-campos" placeholder="" v-model="endereco.complemento">
        </div>

        <div class="campos-mobile">
          <label class="text-base text-corBase  mt-2">Referência:</label>
          <input type="text" class="input-campos" placeholder="" v-model="endereco.referencia">
        </div>
        <div class="campos-mobile">
          <label class="label-campos">Bairro:</label>
          <input type="text" required class="input-campos" placeholder="" v-model="endereco.bairro">
        </div>
      </div>

      <div class=" container-campos ">
        <div class="campos-mobile campos-dasktop-select">
          <label class="label-campos">UF:</label>
          <div class="">
            <select class="input-campos" v-model="endereco.uf">
              <option>------</option>
              <option value="AC">Acre</option>
              <option value="AL">Alagoas</option>
              <option value="AP">Amapá</option>
              <option value="AM">Amazonas</option>
              <option value="BA">Bahia</option>
              <option value="CE">Ceará</option>
              <option value="DF">Distrito Federal</option>
              <option value="ES">Espírito Santo</option>
              <option value="GO">Goiana</option>
              <option value="MA">Maranhão</option>
              <option value="MT">Mato Grosso</option>
              <option value="MS">Mato Grosso do Sul</option>
              <option value="MG">Minas Gerais</option>
              <option value="PA">Pará</option>
              <option value="PB">Paraíba</option>
              <option value="PR">Paraná</option>
              <option value="PE">Pernanbuco</option>
              <option value="PI">Piauí</option>
              <option value="RJ">Rio de Janeiro</option>
              <option value="RN">Rio Grande do Norte</option>
              <option value="RS">Rio Grande do Sul</option>
              <option value="RO">Rondônia</option>
              <option value="RR">Roraima</option>
              <option value="SC">Santa Catarina</option>
              <option value="SP">São Paulo</option>
              <option value="SE">Sergipe</option>
              <option value="TD">Tocantins</option>

            </select>
          </div>
        </div>

        <div class="campos-mobile">
          <label class="label-campos">Cidade:</label>
          <input type="text" required class="input-campos" placeholder="" v-model="endereco.cidade">
        </div>

        <div class="campos-mobile campos-dasktop-select">
          <label class="label-campos">Tipo de Endereço:</label>
          <div class="flex">
            <select class="input-campos" v-model="endereco.tipo_endereco">
              <option>------</option>
              <option v-for="item in lista_tipo_endereco"
                      :value="item.id"
                      :key="item.id"
              >{{ item.descricao }}
              </option>
            </select>

            <tipo-endereco-modal/>
          </div>
        </div>
      </div>
    </div>


  </details>


</template>

<script>
import TipoEnderecoModal from "@/components/midais/modaisCadastro/TipoEnderecoModal";

import axios from "axios";

export default {
  name: 'EnderecosCampos',
  components: {TipoEnderecoModal},
  props: ['dadosEndereco'],

  data() {
    return {
      lista_tipo_endereco: []
    }
  },
  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  created() {
    this.getListaTipoEndereco()
  },

  methods: {
    adicionarEndereco() {
      this.dadosEndereco.push({
        logradouro: '',
        numero: '',
        complemento: '',
        cep: '',
        bairro: '',
        cidade: '',
        uf: '',
        referencia: '',
        tipo_endereco: '',
      });
    },
    removerEndereco(index) {
      this.dadosEndereco.splice(index, 1);
    },
    buscarCepAutomaticamente(endereco) {
      if (endereco && endereco.cep && endereco.cep.length === 9) {
        console.log('Iniciando busca de CEP...');
        this.buscarCep(endereco);
      }
    },

    async getListaTipoEndereco() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('pessoas/enderecos/tipo-enderecos', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
      .then(response => {
        this.lista_tipo_endereco = response.data.results
      })
      .catch(error => {
        console.log(error)
      })
    },

    async buscarCep(endereco) {
      // Fazer a chamada à API de CEP aqui
      // Por exemplo:
      const cepSemMascara = endereco.cep.replace(/[^0-9]/g, '');
      const token = await this.$store.state.token;

      this.$store.dispatch('fetchURLrequest');
      await axios
          .get(`https://viacep.com.br/ws/${cepSemMascara}/json`)
          .then(response => {
            console.log('Resposta da busca de CEP:', response.data.results);
            // Processar a resposta da API e atualizar os campos de endereço
            endereco.logradouro = response.data.logradouro;
            endereco.bairro = response.data.bairro;
            endereco.cidade = response.data.localidade;
            endereco.uf = response.data.uf;
          })
          .catch(error => {
            console.error('Erro ao buscar CEP:', error);
          });
    },

  }
};
</script>

<style>
details > summary {
  list-style: none;
}

details summary::-webkit-details-marker {
  display: none;
}

</style>