<template>
    <div>
        <div class="form-group">
            <label for="comissao">Comissão de Vendas (%)</label>
            <input v-model="obra.comissao" type="number" id="comissao" min="0" placeholder="Digite aqui..." />
        </div>
        <div class="form-group">
            <label for="reserva_tecnica">Reserva Técnica (%)</label>
            <input v-model="obra.valor_rt" type="number" id="valor_rt" min="0" placeholder="Digite aqui..." />
        </div>
        <div class="flex w-full justify-end itens-center mt-6">
            <button type="button" @click="$emit('voltarEtapa')" class="btn-cancelar">Voltar</button>
            <button type="submit" @click="$emit('salvarObra')" class="btn-salvar">Salvar</button>
        </div>
    </div>
</template>

<script>

export default {
    name: 'CriarNovaObra',
    props: {
        obra: Object
    },
    data() {
        return {
        };
    },
    methods: {
    },
};
</script>

<style scoped>
.etapas-navegacao {
    display: flex;
    justify-content: center;
    /* Espaço entre os botões */
    overflow-x: auto;
    /* Permite rolagem horizontal se necessário */
}

.etapas-navegacao button {
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    margin: 20px;
}

.etapas-navegacao .ativo {
    background-color: #1f2937;
    color: white;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input,
select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
</style>