<template>


  <div class="container-formulario">

    <div class="cabecalho-dashboard">
      <h1>Cadastro de Cores</h1>

      <button @click="$router.push(`/cadastros`)" class="text-2xl">
        <i class="fas fa-chevron-left mr-1 "></i>
      </button>
    </div>

    <div class="flex flex-wrap">

      <button v-if="isadmin === true" @click="$router.push('/lista-cores-base')" class="escopo-dashboard-desktop">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Cor Produto Base</h2>
            <i class="fas fa-user"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as Cores de Produto Base presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/lista-cores-chapas')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Chapas</h2>
            <i class="fa-solid fa-layer-group"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as Cores de Chapas presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/lista-cores-componentes')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Componentes</h2>
            <i class="fa-solid fa-gears"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as Cores de Componentes presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/lista-cores-perfil')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Perfis</h2>
            <i class="fa-solid fa-ruler-combined"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as Cores de Perfis presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/lista-cores-uso-consumo')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Uso de Consumo</h2>
            <i class="fa-solid fa-box-open"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as Cores de Uso de Consumo presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/lista-cores-vidro')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Vidros</h2>
            <i class="fas fa-user-friends"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as Cores de Vidros presentes em seu sistema!</p>
          </div>
        </div>
      </button>

    </div>


  </div>


</template>

<script>
export default {
  name: "DashboardCadastroCores",

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },
  }
}
</script>

<style lang="scss"></style>