<template>
  <div class="container-formulario">

  <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Orçamentos</h1>

      <router-link to="/orcamentos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>


    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="px-3 py-3">
              #
            </th>
            <th scope="col" class="px-3 py-3">
              Editar
            </th>
            <th scope="col" class="px-3 py-3">
              Cliente
            </th>
            <th scope="col" class="px-3 py-3">
              Vendedor
            </th>
            <th scope="col" class="px-3 py-3">
              Data Cadastro
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(orcamento, index) in orcamentos" :key="index">
            <th scope="row" class="px-3 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-3 py-2 text-xs">
              <router-link :to="'/orcamento/' + orcamento.id">
                <i class="fa-solid fa-paperclip"></i>
              </router-link>
            </td>
            <td class="px-3 py-2 text-xs">
              {{ orcamento.cliente && orcamento.cliente.nome_razao ? orcamento.cliente.nome_razao : 'Sem Cliente' }}
            </td>
            <td class="px-3 py-2 text-xs">
              {{ orcamento.vendedor && orcamento.vendedor.pessoa && orcamento.vendedor.pessoa.nome_razao ? orcamento.vendedor.pessoa.nome_razao : 'Sem Vendedor' }}
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination v-if="orcamentos.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Pagination from '@/utils/Pagination.vue';

export default {
  name: 'ListaOrcamento',

  components: {
    Pagination
  },

  data() {
    return {
      orcamentos: [],
      paginaAtual: 1,
      countItens: ''
    }
  },

  created() {
    this.getOrcamentos(1)
  },

  methods: {
    async getOrcamentos(pagina) {
      try{
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');

        const response = await axios.get(`orcamentos/?page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.orcamentos = response.data.results
        this.countItens = response.data.count
        console.log(this.orcamentos)
      }catch(err){
        console.error(err.message)
      }
    },

    mudarPagina(pagina) {
            this.paginaAtual = pagina;
            this.getOrcamentos(pagina);
        },
  }
}
</script>

<style scoped lang="scss">

</style>