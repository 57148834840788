<template>
  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Entradas NF-E</h1>
      </div>

      <router-link to="/estoque" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <div class="flex items-end mb-3 gap-8">

      <div class="w-full relative">
        <label class="text-corBase">Data:</label>
        <input v-model="dataFilter" class="w-full z-10" type="date">
        <i class="fas fa-times absolute top-[59%] right-10 cursor-pointer text-gray-500" @click="clearDateFilter"></i>
      </div>

      <div class="flex items-end gap-3">

        <button
          class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase text-white hover:text-corBase py-2 px-4"
          @click="getListaEntradas(1)">
          Pesquisar
        </button>

        <button @click="$router.push(`/estoque/entrada-nota-fiscal`)"
          class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo text-white hover:text-ativo py-2 px-4">
          Adicionar
        </button>

      </div>

    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">

          <tr>
            <th scope="col" class="px-6 py-2">
              #
            </th>

            <th scope="col" class="px-6 py-2 text-center">
              Tipo
            </th>

            <th scope="col" class="px-6 py-2 text-center">
              Data de Movimentação
            </th>

            <th scope="col" class="px-6 py-2"></th>

          </tr>
        </thead>

        <tbody>
          <template v-for="(movimentacao, index) in lista_entrada" :key="movimentacao.id">
            <tr>

              <td scope="row" class="px-6 py-2 text-xs">{{ index + 1 }}</td>

              <td scope="row" class="px-6 py-2 text-xs text-center">{{ movimentacao.motivo_movimentacao }}</td>

              <td scope="row" class="px-6 py-2 text-xs text-center">{{ formatarData(movimentacao.data_movimentacao) }}</td>

              <td class="px-6 py-2 text-xs">
                <button @click="selecionarMovimentacao(movimentacao)" class="text-gray-500 p-3" title="Ver Detalhes">
                  <i
                    :class="movimentacoesSelecionadas.some(m => m.id === movimentacao.id) ? 'fa-solid fa-caret-up' : 'fa-solid fa-caret-down'"></i>
                </button>
              </td>

            </tr>

            <!-- Detalhes da Movimentação (Projetos e outros dados) -->
            <tr v-if="movimentacoesSelecionadas.some(m => m.id === movimentacao.id)" class="bg-gray-200">
              <td colspan="4" class="px-4 py-2">
                <div>

                  <ul v-if="movimentacao.produtos && movimentacao.produtos.length > 0" class="w-full">

                    <li v-for="(produto, index) in movimentacao.produtos" :key="index"
                      class="border-b border-gray-300 py-2">

                      <div class="block items-center">
                        <div>
                          <p class="block text-base">Produto: {{ produto.produto.nome_especifico || 'Sem nome' }}</p>
                        </div>

                        <div>
                          <p class="block text-base">Quantidade: {{ parseFloat(produto.qtd_produtos).toFixed(2) }}</p>
                        </div>

                      </div>

                    </li>

                  </ul>

                  <p v-else>Sem produtos relacionados a esta movimentação.</p>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import Pagination from "@/utils/Pagination.vue";

export default {
  name: "EntradaNotaFiscalComponente",

  components: {
    Pagination
  },

  data() {
    return {
      lista_entrada: [],
      movimentacoesSelecionadas: [],
      lista_movimentacoes: [],

      dataFilter: '',

      paginaAtual: 1,
      countItens: ''

    }
  },

  computed: {
    // isadmin() {
    //   return this.$store.state.isAdminUser
    // },
  },

  created() {
    this.getListaEntradas(1)
  },

  methods: {
    async getListaEntradas(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        const response = await axios.get(`/produtos/movimentacao/?motivo_movimentacao=ENTRADA%20NF-E&data=${this.dataFilter}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.lista_entrada = response.data.results;
        this.countItens = response.data.count;

      } catch (error) {
        console.error(error);
      }
    },

    async selecionarMovimentacao(movimentacao) {
      const movimentacaoIndex = this.movimentacoesSelecionadas.findIndex(m => m.id === movimentacao.id);

      if (movimentacaoIndex === -1) {
        this.movimentacoesSelecionadas.push(movimentacao);
        await this.getProdutoMovimentacao(movimentacao.id)
      } else {
        this.movimentacoesSelecionadas.splice(movimentacaoIndex, 1);
      }
    },

    async getProdutoMovimentacao(movimentacao_id) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');
      try {
        const response = await axios.get(`produtos/produto-movimentacao/?movimentacao=${movimentacao_id}&page_size=9999`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        const movimentacaoIndex = this.lista_entrada.findIndex(m => m.id === movimentacao_id);

        if (movimentacaoIndex !== -1) {
          this.lista_entrada[movimentacaoIndex].produtos = response.data.results;
          console.log(this.lista_entrada[movimentacaoIndex].produtos);
        } else {
          console.warn(`Movimentação não encontrada.`);
        }
      } catch (error) {
        console.error(error);
      }
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina;
      this.getListaEntradas(pagina);
    },

    formatarData(dataISO) {
      const data = new Date(dataISO.includes('Z') ? dataISO : `${dataISO}Z`);
      const dia = String(data.getUTCDate()).padStart(2, '0');
      const mes = String(data.getUTCMonth() + 1).padStart(2, '0');
      const ano = data.getUTCFullYear();
      return `${dia}/${mes}/${ano}`;
    },

    clearDateFilter() {
      this.dataFilter = ''
    },
  }
}
</script>

<style scoped>
input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
</style>