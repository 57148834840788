<template>

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Cores Uso Consumos</h1>
      </div>

      <router-link to="/cores" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <div class="flex items-center gap-3 mb-10">

      <div class="flex gap-8">
        <div>
          <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="getListaCorUsoConsumo(1)">Pesquisar
          </button>
        </div>
      </div>
      <div v-if="isadmin === true">
        <button @click="$router.push(`/cadastro-cores-uso-consumo`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 ">Adicionar
        </button>
      </div>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              #
            </th>
            <th class="px-6 py-3 w-full">
              Nome
            </th>
            <th scope="col" class="px-6 py-3">
              Status
            </th>
            <th scope="col" class="px-10 py-3">
              Ação
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(cor, index) in lista_cores_uso_consumo" :key="index" class="escopo-tabela">

            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox" v-model="cor.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-xs">
              {{ cor.nome }}
            </td>

            <template v-if="cor.ativo === true">
              <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
                <i class="fa-solid fa-circle-check  text-ativo"></i>
              </td>
            </template>
            <template v-if="cor.ativo === false">
              <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
                <i class="fa-solid fa-circle-xmark text-inativo"></i>
              </td>
            </template>

            <td class="px-6 py-2 text-xs">
              <editar-cor-uso-consumo :cor="cor" :paginaAtual="paginaAtual" />
            </td>

          </tr>
        </tbody>
      </table>

      <Pagination v-if="lista_cores_uso_consumo.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
    </div>
  </div>

</template>

<script>
import axios from "axios";
import EditarCorUsoConsumo from "@/components/midais/Editar/cores/EditarCorUsoConsumo";
import Pagination from "@/utils/Pagination.vue";

export default {
  name: "ListaCadastroUsoConsumo",

  components: {
    EditarCorUsoConsumo,
    Pagination
  },

  data() {
    return {
      lista_cores_uso_consumo: [],

      checkAll: false,
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      countItens: '',

      error: [],
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  methods: {

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    async getListaCorUsoConsumo(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get(`/produtos/cor-uso-consumo/?page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.lista_cores_uso_consumo = response.data.results;
        this.countItens = response.data.count;
        console.log(this.lista_cores_uso_consumo);

      } catch (error) {
        console.error("Erro ao buscar lista de cores de uso e consumo:", error);
      }
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina;
      this.getListaCorUsoConsumo(pagina);
    },

  }
}
</script>

<style lang="scss"></style>