<template>
  <div class="fixed inset-0 flex items-center justify-center z-[100000]">
    <div class="w-full h-full bg-black/40 flex items-center justify-center">
      <div
        class="bg-screenCor p-6 w-full sm:w-[90%] lg:w-[75%] max-h-[80vh] rounded-lg shadow-xl overflow-y-auto relative">

        <!-- Cabeçalho com botão de fechar -->
        <div class="flex justify-end mb-4">
          <button class="text-corBase text-2xl" @click.prevent="fechar_galeria">
            <i class="fas fa-xmark"></i>
          </button>
        </div>

        <!-- Título e Filtros -->
        <h1 class="font-bold text-corBase text-xl mb-4">Filtros para Imagens</h1>
        <div class="flex flex-col sm:flex-row gap-4 items-end mb-4">

          <!-- Filtro Categoria -->
          <div class="w-full">
            <label>Categoria</label>
            <select class="input-campos w-full" v-model="categoria">
              <option value="">Escolha uma Categoria</option>
              <option v-for="cat in lista_categoria" :key="cat.id" :value="cat.id">
                {{ cat.text }}
              </option>
            </select>
          </div>

          <!-- Filtro Subcategoria -->
          <div class="w-full">
            <label>SubCategoria</label>
            <select class="input-campos w-full" v-model="subcategoria">
              <option value="">Escolha uma Subcategoria</option>
              <option v-for="sub in lista_subcategoria" :key="sub.id" :value="sub.id">
                {{ sub.text }}
              </option>
            </select>
          </div>

          <!-- Filtro Descrição -->
          <div class="w-full">
            <label>Descrição Imagem</label>
            <input class="input-campos w-full" v-model="descricao_image" />
          </div>

          <!-- Botões de Ação -->
          <div class="flex gap-2">
            <button
              class="py-2 px-4 bg-corBase text-screenCor rounded-lg border border-corBase hover:bg-screenCor hover:text-corBase transition duration-300"
              @click.prevent="filtrarFotos(1)">
              Pesquisar
            </button>
            <button class="btn-salvar py-2 px-4 text-white rounded-lg  transition duration-300"
              @click.prevent="abrir_novaImagem">
              Adicionar
            </button>
          </div>
        </div>

        <!-- Galeria de Imagens -->
        <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
          <div v-for="(imagem, index) in lista_imagens" :key="index" class="cursor-pointer flex flex-col items-center">

            <!-- Botão de Imagem -->
            <button class="relative w-full h-28 bg-gray-200 rounded-lg overflow-hidden flex items-center justify-center"
              @click.prevent="enviarObjeto(imagem)">
              <img class="w-[60%] h-auto object-cover" :src="imagem.url_documento" alt="imagem" />
            </button>

            <!-- Descrição da Imagem -->
            <span class="text-center text-sm text-black mt-2">
              {{ imagem.descricao }}
            </span>

          </div>
        </div>

        <!-- Paginação -->
        <Pagination v-if="lista_imagens.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
          @change-page="mudarPagina" />

          <NovaImagem v-if="novaImagem === true" @fechar_novaImagem="abrir_novaImagem" />
      </div>
    </div>
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import axios from "axios";
import Pagination from "@/utils/Pagination.vue";
import NovaImagem from "./NovaImagem.vue";

export default {
  name: 'Galeria',
  components: {
    Select2,
    Pagination,
    NovaImagem
  },
  data() {
    return {
      descricao_image: '',
      imagem: '',
      lista_imagens: [],
      lista_categoria: [],
      categoria: '',
      lista_subcategoria: [],
      subcategoria: '',
      countItens: 0,
      paginaAtual: 1,
      novaImagem: false
    }
  },
  created() {
    this.getListaGategoria();
    this.getListaSubcategoria();
    this.filtrarFotos(1)
  },
  methods: {
    abrir_novaImagem() {
      console.log("clicado")
      this.novaImagem = !this.novaImagem;
    },
    mudarPagina(pagina) {
      this.paginaAtual = pagina;
      this.filtrarFotos(pagina);
    },
    fechar_galeria() {
      this.$emit('fechar_galeria');
    },
    async filtrarFotos(pagina) {
      if (pagina === 1) {
        this.paginaAtual = 1;
      }
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');
        this.$store.dispatch("setLoading", true);
        const response = await axios.get(`ged/documentos/?categoria_id=${this.categoria}&subcategoria_id=${this.subcategoria}&descricao=${this.descricao_image}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        this.lista_imagens = response.data.results;
        console.log(this.lista_imagens)
        this.countItens = response.data.count;
      } catch (err) {
        console.error(err);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },
    async getListaGategoria() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');
        const response = await axios.get(`ged/categoria`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        this.lista_categoria = response.data.results.map(item => ({
          id: item.id,
          text: item.nome
        }));
      } catch (err) {
        console.error(err);
      }
    },
    async getListaSubcategoria() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');
        const response = await axios.get(`ged/subcategoria`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        this.lista_subcategoria = response.data.results.map(item => ({
          id: item.id,
          text: item.nome
        }));
      } catch (err) {
        console.error(err);
      }
    },
    enviarObjeto(objeto) {
      this.$emit('objetoClicado', objeto);
      this.fechar_galeria();
    }
  }
};
</script>

<style scoped>
.input-campos {
  @apply w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-corBase;
}
</style>
