<template>
  <div v-if="abrir !== false" class="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-100">
    <div>
      <div class="conteudo-modal-editar">
        <div class="flex justify-end">
          <button @click.prevent="fechar()">
            <i class="fas fa-close text-2xl text-corBase"></i>
          </button>
        </div>
        <header class="page__header">
          <div class="stepper">
            <span
              v-for="(etapa, index) in etapas"
              :key="etapa.titulo"
              :class="{'stepper__item--active': etapaAtual == index}"
            >
              {{ index + 1 }} - {{ etapa.titulo }}
            </span>
          </div>
        </header>    
        <section class="page__content">
          <div>
            <VincularEstoqueProduto
              :produtosXml="produtosXml"
              :produtosVinculados="produtosVinculados"
              :listaProdutoFornecedor="listaProdutoFornecedor"
              @fecharRelacao="fechar"      
              @proximaEtapa="proximaEtapa"
              @addProdutosVinculados="addProdutosVinculados"
              v-if="etapaAtual == 0"
            />
            <ConferenciaPerfis
              :produtosXml="produtosXml"
              v-if="etapaAtual == 1"
            />
          </div>
          <div class="page__footer">
            <button
              type="button"
              class="btn-primary"
              :disabled="primeiraEtapa"
              @click="voltarEtapa"
            >
              Voltar
            </button>

            <button
              v-if="ultimaEtapa"
              type="button"
              class="btn-primary"
              @click="submit"
            >
              Salvar
            </button>

            <button
              v-else
              type="button"
              class="btn-primary"
              @click="proximaEtapa"
            >
              Prosseguir
            </button>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import VincularEstoqueProduto from './VincularEstoqueProduto.vue'
import ConferenciaPerfis from './ConferenciaPerfis.vue'

export default {
  name: 'FormularioVinculoEmEtapas',

  data(){ 
    return{
      etapaAtual: 0,
      etapas: [],
    }
  },

  components: {
    VincularEstoqueProduto,
    ConferenciaPerfis
  },

  props: ["fornecedor", "produtosXml", "abrir", "listaProdutoFornecedor", 'produtosVinculados'],

  computed: {
    primeiraEtapa() {
      return this.etapaAtual == 0
    },

    ultimaEtapa() {
      return this.etapaAtual == this.etapas.length - 1
    }
  },

  created(){
    this.etapas = [
      {titulo: 'Vincular Produtos'},
      {titulo: 'Conferência Perfis'},
      // {titulo: 'Conferência Componentes/Acessórios'}            
    ]
  },

  methods: {
    proximaEtapa() {
      this.etapaAtual += 1
    },

    voltarEtapa(){
      this.etapaAtual -= 1
    },

    submit() {
      this.fechar()
    },

    fechar() {
      this.$emit("fecharRelacao");
    },

    addProdutosVinculados(novosVinculos) { 
      this.$emit('addProdutosVinculados', novosVinculos)
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  @apply w-[85vw] h-[100vh] flex flex-col bg-[#fafafa] items-center
}
.page__header {
  @apply w-[100%] h-[60px] flex items-center justify-center bg-[#fff] border-b-fuchsia-50 shadow-gray-300
}
.stepper {
  @apply w-[100%] flex justify-center items-center gap-[38px]
}
.stepper__item--active {
  color: #0ea5e9; 
}
.page__footer {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  padding-left: 18px;
  padding-right: 18px;
}
.page__footer button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 1px solid #cecece;
  font-size: 1rem;
  color: #9e9e9e;
  padding: 12px;
  max-height: 32px;
  border-radius: 4px;
  cursor: pointer;
}
.page__footer button.btn-primary {
  border-color: #0ea5e9;
  color: #0ea5e9;
}

</style>