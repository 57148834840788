<template>
  <div class="container-formulario">
    <!-- Cabeçalho -->
    <div class="container-cabecalho flex justify-between mb-4">
      <h1 class="text-xl text-corBase font-bold">Tipologias</h1>
      <router-link to="/projetos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Filtros e Botões -->
    <div class="flex gap-8 mb-6 items-end w-[70%]">
      <div class="w-full">
        <label class="text-base text-corBase font-bold mb-1">Descrição:</label>
        <input type="text" class="input-form-chapa" v-model="desc" placeholder="Digite aqui...">
      </div>
      <div class="w-full">
        <!-- <input type="text" class="input-form-chapa" v-model="modelo" placeholder="Modelo"> -->
        <label class="text-base text-corBase font-bold mb-1">Modelo:</label>
        <v-select v-model="modelo" :options="lista_modelo" class="w-full z-10" :reduce="option => option.id" label="name"/>
      </div>
      <div>
        <button class="btn-add-fisica" @click="getListaTipologias(1)">Pesquisar</button>
      </div>
      <div v-if="isadmin">
        <button @click="$router.push(`/tipologia/cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
                text-white hover:text-ativo py-1 rounded-sm px-4">
          Adicionar
        </button>
      </div>
    </div>

    <!-- Container da exibição da lista de tipologias -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <!-- Cabeçalho das Colunas -->
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">#</th>
            <th scope="col" class="px-6 py-3 text-center">Código</th>
            <th scope="col" class="px-6 py-3">Descrição</th>
            <th scope="col" class="px-8 py-3">Imagem</th>
            <th scope="col" class="px-6 py-3">Modelo</th>
            <th scope="col" class="px-6 py-3 text-center">Status</th>
            <th scope="col" class="px-6 py-3" v-if="isadmin">Ação</th>
          </tr>
        </thead>
        <tbody>
          <!-- Iteração para listar as tipologias -->
          <tr v-for="(tipologia, index) in lista_tipologia" :key="index" class="escopo-tabela">
            <!-- Checkbox de seleção -->
            <td scope="col" class="px-6 py-3">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                  v-model="tipologia.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <!-- Colunas com dados das tipologias -->
            <th scope="col" class="px-6 py-3 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td scope="col" class="px-6 py-3 text-center">
              {{ tipologia.codigo }}
            </td>
            <td scope="col" class="px-6 py-3">{{ tipologia.nome }}</td>
            <td scope="col" class="px-6 py-3 items-center">
              <!-- <img :src="tipologia.imagem.url_documento" alt="imagem-tipologia" class="w-10"> -->
              <img v-if="tipologia.imagem" :src="tipologia.imagem.url_documento" class="cursor-pointer w-[100px] h-[100px] border border-gray-100 rounded-lg"
                @click="openPreviewImage(tipologia.imagem.url_documento)">

              <img v-else
                src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                alt="Sem foto">
            </td>
            <td scope="col" class="px-6 py-3">{{ tipologia.modelo.descricao }}</td>
            <td class="px-6 py-3 text-base text-center">
              <i
                :class="tipologia.ativo ? 'fa-solid fa-circle-check text-ativo' : 'fa-solid fa-circle-xmark text-inativo'"></i>
            </td>
            <td v-if="isadmin">
              <button @click="abrirModalEditar(tipologia)" class="font-medium text-corBase pl-2 hover:underline">
                <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Paginação -->
      <Pagination v-if="lista_tipologia.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
    </div>

    <!-- Modal de Edição -->
    <EditarTipologia v-if="statusModal" :id_tipologia="tipologiaSelecionada" @fecharModal="fecharModalEditar"
      @atualizarLista="getListaTipologias(1)" />

    <PreviewImage v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />
  </div>
</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";
import vSelect from "vue-select";
import EditarTipologia from "@/components/midais/Editar/projeto/EditarTipologia";
import Pagination from "@/utils/Pagination.vue";
import PreviewImage from "@/components/midais/ged/PreviewImage.vue";

export default {
  name: "ListaTipologia",

  components: {
    Select2,
    vSelect,
    EditarTipologia,
    Pagination,
    PreviewImage
  },

  data() {
    return {
      lista_tipologia: [],
      lista_modelo: [],
      tipologiaSelecionada: null,
      statusModal: false,
      checkAll: false,
      paginaAtual: 1,
      countItens: '',
      desc: '',
      nome_projetista: '',
      modelo: '',
      preview_imagem: '',
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  created() {
    this.getListaModelo()
  },


  methods: {

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    abrirModalEditar(tipologia) {
      console.log(tipologia.id)
      this.tipologiaSelecionada = tipologia.id;
      this.statusModal = true;
    },

    fecharModalEditar() {
      this.statusModal = false;
    },

    openPreviewImage(url) {
      this.preview_imagem = url
    },

    closed_preview_image() {
      this.preview_imagem = ''
    },

    async getListaTipologias(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`/projeto/tipologia/?linhasistema=${this.nome_projetista}&modelo=${this.modelo || ''}&nome=${this.desc}&page=${pagina}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_tipologia = response.data.results
          this.countItens = response.data.count
        })
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina
      this.getListaTipologias(pagina)
    },

    async getListaModelo() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/modelo-variacao/?page_size=99999', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_modelo = response.data.results.map(item => ({
            id: item.id,
            name: item.descricao
          }));
          console.log(response.data.results)
        })
        .catch(error => {
          console.log(error)
        })
    },
  }
}
</script>

<style scoped>
</style>