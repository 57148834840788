<template>

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Serviço</h1>
      </div>

      <router-link to="/produtos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <div class="flex items-end gap-8 mb-6 w-[70%]">
      <div class="w-full">
        <label class="text-xl text-corBase">Tipo Pessoa:</label>
        <!-- <Select2 v-model="tipo_pessoa" class="w-full z-10" :options="lista_tipo_pessoa" :settings="{ width: '100%' }" /> -->
        <vSelect v-model="tipo_pessoa" class="w-full z-10" :options="lista_tipo_pessoa" :settings="{ width: '100%' }"
          label="text" :reduce="tipo_pessoa => tipo_pessoa.id" />
      </div>
      <div class="w-full">
        <label class="text-xl text-corBase">Tipo Serviço:</label>
        <!-- <Select2 v-model="tipo" class="w-full z-10" :options="lista_tipo_pessoa" :settings="{ width: '100%' }" /> -->
        <vSelect v-model="tipo" class="w-full z-10" :options="lista_tipo_servico" :settings="{ width: '100%' }"
          label="text" :reduce="tipo => tipo.id" />
      </div>
      <div class="w-full">
        <label class="text-xl text-corBase">Grupo Aplicação:</label>
        <!-- <Select2 v-model="grupo" class="w-full z-10" :options="lista_grupo_aplicacao_servico" :settings="{ width: '100%' }" /> -->
        <vSelect v-model="grupo" class="w-full z-10" :options="lista_grupo_aplicacao_servico"
          :settings="{ width: '100%' }" label="text" :reduce="grupo => grupo.id" />
      </div>
      <div>
        <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="getListaServico(1)">Pesquisar
        </button>
      </div>
    </div>

    <div class="mb-8">
      <div>
        <button @click="$router.push(`/servico/cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 ">Adicionar
        </button>
      </div>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              #
            </th>
            <th class="px-6 py-3">
              Nome
            </th>
            <th class="px-6 py-3">
              Grupo
            </th>
            <th scope="col" class="px-6 py-3">
              Fornecedor
            </th>
            <th scope="col" class="px-6 py-3">
              Tempo Medio
            </th>
            <th scope="col" class="px-6 py-3">
              Produto Base
            </th>
            <th scope="col" class="px-6 py-3">
              Tipo Pessoa
            </th>
            <th scope="col" class="px-6 py-3">
              Status
            </th>
            <th scope="col" class="px-6 py-3">
              Editar
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(servico, index) in lista_servicos" :key="index" class="escopo-tabela">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                  v-model="servico.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-xs">
              {{ servico.nome_especifico }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ servico.grupo_servico.nome }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ servico.fornecedor.pessoa.nome_razao }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ servico.tempo_medio_servico }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ servico.produto_base.nome_base }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ servico.tipo_pessoa.descricao }}
            </td>
            <template v-if="servico.ativo === true">
              <td class="px-6 py-2 text-xs">
                <i class="fa-solid fa-circle-check  text-ativo ml-3"></i>
              </td>
            </template>
            <template v-if="servico.ativo === false">
              <td class="px-6 py-2">
                <i class="fa-solid fa-circle-xmark text-inativo ml-3"></i>
              </td>
            </template>

            <td class="px-6 py-2 text-xs">
              <a @click="selecionarProdutoEditar(servico)" href="#" class="font-medium text-corBase hover:underline">
                <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
              </a>
            </td>

          </tr>
        </tbody>
      </table>
      <Pagination v-if="lista_servicos.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
        <editar-servico v-if="exibirModal" :dadosServico="servicoSelecionado" @fecharModal="toggleModal"></editar-servico>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import EditarServico from "@/components/midais/Editar/produtos/EditarServico";
import Select2 from "vue3-select2-component";
import vSelect from "vue-select";
import Pagination from "@/utils/Pagination.vue";

export default {
  name: "ListaServico",

  components: {
    EditarServico,
    Select2,
    vSelect,
    Pagination
  },

  data() {
    return {
      checkAll: false,
      // lista usada para salvar o retorno da requisição do get
      listaServico: [],
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      countItens: '',

      servicoSelecionado: null,
      exibirModal: false,

      // filtros
      lista_tipo_pessoa: [],
      tipo_pessoa: '',
      lista_tipo_servico: [],
      tipo: '',
      lista_grupo_aplicacao_servico: [],
      grupo: '',


      lista_servicos: [],

      error: []
    }
  },

  created() {
    this.getListaTipoPessoa()
    this.getListaTipoServico()
    this.getListaGrupoAplicacao()
  },

  computed: {

    isadmin() {
      return this.$store.state.isAdminUser
    },

    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  methods: {

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    async getListaServico(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      // Ativar o estado de loading
      this.$store.dispatch("setLoading", true);

      try {
        // Fazer a requisição GET
        const response = await axios.get(`produtos/servico/?tipo_servico_id=${this.tipo}&grupo_servico_id=${this.grupo}&tipo_pessoa_id=${this.tipo_pessoa}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.lista_servicos = response.data.results;
        this.countItens = response.data.count
        console.log(this.lista_servicos);

      } catch (error) {
        console.error("Erro ao buscar serviços:", error);

      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina;
      this.getListaServico(pagina);
    },

    selecionarProdutoEditar(servico){
      this.servicoSelecionado = servico.id
      this.toggleModal()
    },

    toggleModal(){
      this.exibirModal = !this.exibirModal
    },

    // lista dos filtros
    async getListaTipoPessoa() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get('pessoas/tipo-pessoas/?page_size=9999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.lista_tipo_pessoa = response.data.results.map(item => ({
          id: item.id,
          text: item.descricao
        }))
      } catch (error) {
        console.error(error)
      }
    },

    async getListaTipoServico() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get('produtos/tipo-servico/?page_size=9999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.lista_tipo_servico = response.data.results.map(item => ({
          id: item.id,
          text: item.nome
        }))
      } catch (error) {
        console.error(error)
      }
    },

    async getListaGrupoAplicacao() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get('produtos/grupo-aplicacao-servico/?page_size=9999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.lista_grupo_aplicacao_servico = response.data.results.map(item => ({
          id: item.id,
          text: item.nome
        }))
      } catch (error) {
        console.error(error)
      }
    },

  }
}
</script>

<style lang="scss"></style>