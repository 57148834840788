<template>

  <div class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full z-[100000]">
    <div class="w-full h-screen bg-black/40 z-[100000]">
      <div class="bg-screenCor p-6 w-[75%] z-[100000] shadow-xl shadow-screenCor/30 fixed top-[55%] left-1/2
      transform -translate-x-1/2 -translate-y-1/2 h-[80vh] overflow-y-scroll scrollbar-thin
      scrollbar-thumb-screenCor/40">

        <div>
          <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
          <div class="cabecalho-formulario">
            <h1 class="text-2xl text-corBase font-bold">Cadastro da Imagem:</h1>

            <button class="text-corBase text-2xl" @click="fechar_novaImagem">
              <i class="fas fa-xmark"></i>
            </button>
          </div>

          <!-- Escopo do formulario de cadastro mobile-->
          <form @submit.prevent="cadastrarImagem">
            <!--        PARTE DE DADOS DO PRODUTO-->
            <div class="div-produtos flex flex-col md:flex md:flex-row">
              <div class="flex flex-col w-full mt-2">

                <div class="imagePreviewWrapper" :style="{ 'background-image': `url(${previewImage})` }"
                  @click="selectImage">
                </div>
                <div class="flex gap-3 mt-4">
                  <label for="file-input" class="selecionar-arquivo">
                    Selecionar
                  </label>
                  <input id="file-input" type="file" class="hidden" ref="fileInput" @change="handleImagemUpload"
                    @input="pickFile">

                </div>

              </div>
              <div class="flex flex-col w-full">
                <div class="campo-chapa mt-8">
                  <label class="text-xl font-bold text-corBase">Descrição:</label>
                  <input type="text" v-model="descricao" class="input-form-chapa" required>
                </div>
                <div class="campo-chapa mt-8">
                  <label class="text-xl font-bold text-corBase">Categoria:</label>
                  <v-select v-model="categoria" class="w-full z-20 bg-white" :options="lista_categorias" label="text"
                    :reduce="categoria => categoria.id" />
                </div>
                <div class="campo-chapa mt-8">
                  <label class="text-xl font-bold text-corBase">Subcategoria:</label>
                  <v-select v-model="sub_categoria" class="w-full z-10 bg-white" :options="lista_sub_categorias"
                    label="text" :reduce="sub_categoria => sub_categoria.id" />
                </div>
              </div>
            </div>

            <div class="flex w-full justify-end">
              <button class="btn-enviar" type="submit">Salvar</button>
            </div>

          </form>
        </div>
        <!-- <mensagem-sucesso v-if="sucesso === true" mensagem="Imagem Cadastrada com Sucesso!"
          rotas="/ged/lista-imagens" /> -->
        <SucessoRapido v-if="sucesso === true" mensagem="Imagem cadastrada com sucesso!" />
        <ErroRapido v-if="erro === true" mensagem="Ocorreu um erro ao cadastrar a imagem." />
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import vSelect from 'vue-select';
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import ErroRapido from "@/components/toasts/toast_erro/ErroRapido.vue";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";

export default {
  name: "NovaImagem",

  components: {
    vSelect,
    MensagemSucesso,
    ErroRapido,
    SucessoRapido
  },

  data() {
    return {
      sucesso: false,
      erro: false,
      previewImage: null,
      fileInput: null,
      lista_categorias: [],
      categoria: '',
      lista_sub_categorias: [],
      sub_categoria: '',
      descricao: '',
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  created() {
    this.getCategorias()
    this.getSubCategorias()
  },

  methods: {
    fechar_novaImagem() {
      this.$emit('fechar_novaImagem')
    },

    async cadastrarImagem() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');

        const data = new FormData();
        data.append('ativo', 'True');
        data.append('descricao', this.descricao);
        data.append('categoria', this.categoria);
        data.append('subcategoria', this.sub_categoria);
        data.append('url_documento', this.$refs.fileInput.files[0]);

        this.$store.dispatch("setLoading", true);
        this.sucesso = false;

        const response = await axios.post('ged/documentos/', data, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        console.log(response.data);
        this.descricao = '';
        this.categoria = '';
        this.sub_categoria = '';
        this.fileInput = null;
        this.previewImage = null;

        this.sucesso = true;
        setTimeout(() => {
          this.sucesso = false;
          this.fechar_novaImagem()
        }, 3000);

      } catch (error) {
        console.error(error);
        this.erro = true;
        setTimeout(() => {
          this.erro = false;
        }, 3000);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    async getCategorias() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('ged/categoria/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_categorias = response.data.results.map(item => ({
            id: item.id,
            text: item.nome
          }))
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getSubCategorias() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('ged/subcategoria/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_sub_categorias = response.data.results.map(item => ({
            id: item.id,
            text: item.nome
          }))
        })
        .catch(error => {
          console.log(error)
        })
    },

    handleImagemUpload(event) {
      this.fileInput = event.target.files[0];
    },
    selectImage() {
      this.$refs.fileInput.click()
    },

    pickFile() {
      let input = this.$refs.fileInput
      let file = input.files
      if (file && file[0]) {
        let reader = new FileReader
        reader.onload = e => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    }

  }
}
</script>


<style scoped></style>