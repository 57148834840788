<template>
    <div class="modal-backdrop">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title">Editar Imagem</h2>
                <button @click.prevent="$emit('toggleModal')">
                    <i class="fa-solid fa-x"></i>
                </button>
            </div>
            <form @submit.prevent="putDadosEditado">
                <div class="div-produtos flex flex-col md:flex md:flex-row">
                    <div class="flex flex-col w-full mt-2">
                        <!-- Imagem pré-visualizada -->
                        <div class="imagePreviewWrapper"
                            :style="{ 'background-image': `url(${this.imagem.url_documento})` }">
                        </div>
                        <div class="flex gap-3 mt-4">
                            <button @click.prevent="abrir_galeria" class="selecionar-arquivo py-2 px-4">
                                <i class="fa-solid fa-image fa-lg mr-2"></i>
                                <span>Escolha a Imagem</span>
                            </button>
                            <input id="file-input" type="file" class="hidden" ref="fileInput"
                                @change="handleImagemUpload">
                        </div>
                    </div>
                    <div class="flex flex-col w-full">
                        <div class="campo-chapa mt-8">
                            <label class="text-xl font-bold text-corBase">Descrição:</label>
                            <input type="text" v-model="imagem.descricao" class="input-form-chapa" required>
                        </div>
                        <div class="campo-chapa mt-8">
                            <label class="text-xl font-bold text-corBase">Categoria:</label>
                            <v-select v-model="imagem.categoria" class="w-full z-20 bg-white"
                                :options="this.lista_categoria" label="text" :reduce="categoria => categoria.id" />
                        </div>
                        <div class="campo-chapa mt-8">
                            <label class="text-xl font-bold text-corBase">Subcategoria:</label>
                            <v-select v-model="imagem.subcategoria" class="w-full z-10 bg-white"
                                :options="this.lista_subcategoria" label="text"
                                :reduce="subcategoria => subcategoria.id" />
                        </div>
                        <div>
                            <label class="text-base text-corBase font-bold mt-2 mr-1">Ativo:</label>

                            <span @click.prevent="toggleAtivo" class="text-start text-xl">
                                <i v-if="imagem.ativo === true" class="fa-solid fa-circle-check text-ativo"></i>
                                <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="flex w-full justify-end">
                    <button class="btn-enviar" type="submit">Salvar</button>
                </div>
            </form>
        </div>
    </div>

    <Galeria v-if="showGaleria" @fechar_galeria="abrir_galeria" @objetoClicado="atualizarFileInput" />
    <SucessoRapido v-if="sucesso" mensagem="Imagem atualizada com sucesso!" />
    <ErroRapido v-if="erro" mensagem="Ocorreu um erro ao atualizar imagem." />
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import Galeria from "../../ged/Galeria.vue";
import ErroRapido from "@/components/toasts/toast_erro/ErroRapido.vue";

export default {
    name: "EditarImagem",
    components: {
        vSelect,
        SucessoRapido,
        ErroRapido,
        Galeria
    },
    data() {
        return {
            sucesso: false,
            erro: false,
            erroMessage: '',
            imagem: [],
            // documento: [],

            showGaleria: false
        }
    },

    props: ['imagemId', 'lista_categoria', 'lista_subcategoria'],

    emits: ['toggleModal', 'atualizarLista'],

    created() {
        this.getDadosImagem()
        // console.log(this.imagem)
    },

    methods: {
        atualizarFileInput(file) {
            this.imagem.url_documento = file
            // console.log(this.imagem)
        },

        abrir_galeria() {
            this.showGaleria = !this.showGaleria
        },

        async getDadosImagem() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            try {
                const response = await axios.get(`/ged/documentos/${this.imagemId}`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })
                console.log(response.data)
                this.imagem = response.data
            } catch (error) {
                console.error(error)
            }
        },

        async putDadosEditado() {
            const dadosAtualizados = new FormData();
            dadosAtualizados.append('ativo', this.imagem.ativo);
            dadosAtualizados.append('descricao', this.imagem.descricao);
            dadosAtualizados.append('categoria', this.imagem.categoria);
            dadosAtualizados.append('subcategoria', this.imagem.subcategoria);
            // dadosAtualizados.append('url_documento', this.imagem.imagem);

            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');
                const response = await axios.patch(`/ged/documentos/${this.imagem.id}/`, dadosAtualizados, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                });
                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit('toggleModal');
                    this.$emit('atualizarLista');
                }, 3000);

            } catch (error) {
                console.error(error)
                this.erro = true
                setTimeout(() => {
                    this.erro = false;
                }, 3000);
            }
        },
    }
}
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modal-content {
    @apply bg-screenCor w-[75%];
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.form-group {
    margin-bottom: 15px;
}
</style>
