<template>

  <!-- Lista de pessoas até 768 pixels-->

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Componentes</h1>
      </div>

      <router-link to="/produtos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="flex items-end gap-8 mb-6">
      <div class="w-full">
        <label class="text-xl text-corBase">Cor:</label>
        <v-select v-model="cor_componente" :options="lista_cor_componente" :reduce="option => option.id" label="text"
          class="w-full z-10" />
      </div>
      <div class="w-full">
        <label class="text-xl text-corBase">Tipo:</label>
        <v-select v-model="tipo_componente" :options="lista_tipo_componente" :reduce="option => option.id" label="text"
          class="w-full z-10" />
      </div>
      <div class="w-full">
        <label class="text-xl text-corBase">Grupo Aplicacao:</label>
        <v-select v-model="grupo_aplicacao_componente" :options="lista_grupo_aplicacao_componente"
          :reduce="option => option.id" label="text" class="w-full z-10" />
      </div>
      <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="getListaComponente(1)">Pesquisar
      </button>
    </div>
    <div class="mb-8">
      <div>
        <button @click="$router.push(`/componente-acessorio/cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 ">Adicionar
        </button>
      </div>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              #
            </th>
            <th class="px-6 py-3">
              Nome
            </th>
            <th class="px-6 py-3">
              Tipo
            </th>
            <th scope="col" class="px-6 py-3">
              Fornecedor
            </th>
            <th scope="col" class="px-6 py-3">
              Cor
            </th>
            <th scope="col" class="px-6 py-3">
              Produto Base
            </th>
            <th scope="col" class="px-6 py-3">
              Grupo de Aplicacao
            </th>
            <th scope="col" class="px-6 py-3">
              Status
            </th>
            <th scope="col" class="px-6 py-3">
              Editar
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(componente, index) in lista_componentes" :key="index" class="escopo-tabela">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                  v-model="componente.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-xs">
              {{ componente.nome_especifico || 'Sem nome' }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ componente.tipo_componentes.nome }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ componente.fornecedor?.nome_razao || 'Sem fornecedor definido' }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ componente.cor_componentes.nome }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ componente.componente_base.nome_especifico }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ componente.grupo_aplicacao.nome }}
            </td>
            <template v-if="componente.ativo === true">
              <td class="px-6 py-2 text-xs">
                <i class="fa-solid fa-circle-check  text-ativo ml-3"></i>
              </td>
            </template>
            <template v-if="componente.ativo === false">
              <td class="px-6 py-2">
                <i class="fa-solid fa-circle-xmark text-inativo ml-3"></i>
              </td>
            </template>

            <td class="px-6 py-2 text-xs">
              <a @click="selecionarComponenteEditar(componente.id)" href="#"
                class="font-medium text-corBase hover:underline">
                <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
              </a>
            </td>

          </tr>
        </tbody>
      </table>
      <Pagination v-if="lista_componentes.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />

      <editar-componente-acessorio v-if="exibirModal" :dadosComponente="componenteSelecionado"
        @fecharModal="toggleModal"></editar-componente-acessorio>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import EditarComponenteAcessorio from "@/components/midais/Editar/produtos/EditarComponenteAcessorio";
import vSelect from "vue-select";
import Pagination from "@/utils/Pagination.vue";

export default {
  name: "ListaComponentes",

  components: {
    EditarComponenteAcessorio,
    vSelect,
    Pagination
  },

  data() {
    return {
      checkAll: false,
      // lista usada para salvar o retorno da requisição do get
      listaPessoas: [],
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      countItens: '',

      componenteSelecionado: null,
      exibirModal: false,

      // filtros
      lista_cor_componente: [],
      cor_componente: '',
      lista_tipo_componente: [],
      tipo_componente: '',
      lista_grupo_aplicacao_componente: [],
      grupo_aplicacao_componente: '',
      produto_base: '',


      lista_componentes: [],

      error: []
    }
  },

  created() {
    console.log(this.isadmin)
    this.getTipoComponente()
    this.getCorComponente()
    this.getGrupoAplicacaoComponente()
  },

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },

    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  methods: {

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    async getListaComponente(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      this.$store.dispatch("setLoading", true); // Ativar o estado de loading

      try {
        const response = await axios.get(`produtos/componente-acessorio/?cor_componentes_id=${this.cor_componente}&tipo_componentes_id=${this.tipo_componente}&grupo_aplicacao_id=${this.grupo_aplicacao_componente}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.lista_componentes = response.data.results;
        this.countItens = response.data.count
        console.log(response.data.results);

      } catch (error) {
        console.error("Erro ao buscar componentes:", error);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina;
      this.getListaComponente(pagina);
    },

    selecionarComponenteEditar(componente) {
      this.componenteSelecionado = componente
      this.toggleModal()
    },

    toggleModal() {
      this.exibirModal = !this.exibirModal
    },

    async getCorComponente() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get('produtos/cor-componente', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        // console.log(response.data);
        this.lista_cor_componente = response.data.results.map(item => ({
          id: item.id,
          text: item.nome
        }));

      } catch (error) {
        console.error("Erro ao buscar cores dos componentes:", error);
      }
    },

    async getTipoComponente() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get('produtos/tipo-componente', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        // console.log(response.data);
        this.lista_tipo_componente = response.data.results.map(item => ({
          id: item.id,
          text: item.nome
        }));

      } catch (error) {
        console.error("Erro ao buscar tipos de componentes:", error);
      }
    },

    async getGrupoAplicacaoComponente() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get('produtos/grupo-aplicacao-componente', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        // console.log(response.data);
        this.lista_grupo_aplicacao_componente = response.data.results.map(item => ({
          id: item.id,
          text: item.nome
        }));

      } catch (error) {
        console.error("Erro ao buscar grupos de aplicação dos componentes:", error);
      }
    },
  }
}
</script>

<style lang="scss"></style>