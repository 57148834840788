<template>
    <div>
        <h2 class="text-lg text-corBase font-bold">Produtos da Tabela</h2>

        <!-- Adicionando uma div para rolagem lateral -->
        <div class="container-modelo-tabela">
            <table class="container-tabela overflow-auto">
                <thead class="cabecalho-tabela">
                    <tr>
                        <th class="py-2 px-4 bg-gray-200 border-b">Ações</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Linha</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Custo</th>
                        <th class="py-2 px-4 bg-gray-200 border-b whitespace-nowrap">ICMS (%)</th>
                        <th class="py-2 px-4 bg-gray-200 border-b whitespace-nowrap">IPI (%)</th>
                        <th class="py-2 px-4 bg-gray-200 border-b whitespace-nowrap">Frete (%)</th>
                        <th class="py-2 px-4 bg-gray-200 border-b  whitespace-nowrap">Valor com Impostos</th>
                        <th class="py-2 px-4 bg-gray-200 border-b  whitespace-nowrap">Margem (%)</th>
                        <th class="py-2 px-4 bg-gray-200 border-b whitespace-nowrap">Valor de Venda</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- Exibir os produtos existentes -->
                    <tr v-for="produto in produtosPorLinha" :key="produto.id">
                        <td class="py-2 px-4 border-b flex gap-2">
                            <button @click="deletePerfil(produto.id)" class="text-gray-500" title="Excluir Perfil">
                                <i class="fas fa-times"></i>
                            </button>
                            <button @click="abrirModalEditarProduto(produto)" class="text-gray-500"
                                title="Editar Perfil">
                                <i class="fas fa-paperclip"></i>
                            </button>
                        </td>
                        <td class="py-2 px-4 border-b whitespace-nowrap">{{ produto.linha_aplicacao.nome }}</td>
                        <td class="py-2 px-4 border-b whitespace-nowrap">R$ {{
                            parseFloat(produto.custo).toFixed(2) }}</td>
                        <td class="py-2 px-4 border-b">{{ parseFloat(produto.icms).toFixed(2) }}%
                        </td>
                        <td class="py-2 px-4 border-b">{{ parseFloat(produto.ipi).toFixed(2) }}%
                        </td>
                        <td class="py-2 px-4 border-b">{{
                            parseFloat(produto.frete).toFixed(2) }}%</td>
                        <td class="py-2 px-4 border-b">R$ {{ calcularValorComImpostosExistente(produto) }}</td>
                        <td class="py-2 px-4 border-b">{{
                            parseFloat(produto.margem_adicional).toFixed(2) }}%</td>
                        <td class="py-2 px-4 border-b">R$ {{ calcularValorDeVendaExistente(produto) }}</td>
                    </tr>

                    <!-- Linha de input para adicionar novo produto -->
                    <tr v-if="adicionarProdutoAtivo">
                        <td class="py-2 px-4 border-b"> </td>
                        <td class="py-2 px-4 w-full sm:w-96 border-b">
                            <Select2 v-model="novoProduto.linha_aplicacao" :options="listaLinhas" class="w-full"
                                :settings="{ width: '100%', placeholder: 'Selecione um produto' }" />
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="novoProduto.custo" type="number" min="0" class="input-form">
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="novoProduto.icms" type="number" min="0" max="100" class="input-form">
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="novoProduto.ipi" type="number" min="0" max="100" class="input-form">
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="novoProduto.frete" type="number" min="0" max="100" class="input-form">
                        </td>
                        <td class="py-2 px-4 border-b">{{ calcularValorComImpostosNovo(novoProduto) }}</td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="novoProduto.margem_adicional" type="number" min="0" max="100"
                                class="input-form">
                        </td>
                        <td class="py-2 px-4 border-b">{{ calcularValorDeVendaNovo(novoProduto) }}</td>
                    </tr>

                    <!-- Modal inline de edição de produto -->
                    <tr v-if="editarProdutoAtivo">
                        <td class="py-2 px-4 border-b"> </td>
                        <td class="py-2 px-4 w-full sm:w-96 border-b">
                            <input v-model="produtoSelecionado.linha_aplicacao.nome" type="text" class="input-form"
                                readonly />
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="produtoSelecionado.custo" type="number" min="0" class="input-form">
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="produtoSelecionado.icms" type="number" min="0" max="100" class="input-form">
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="produtoSelecionado.ipi" type="number" min="0" max="100" class="input-form">
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="produtoSelecionado.frete" type="number" min="0" max="100"
                                class="input-form">
                        </td>
                        <td class="py-2 px-4 border-b">{{ calcularValorComImpostosNovo(produtoSelecionado) }}</td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="produtoSelecionado.margem_adicional" type="number" min="0" max="100"
                                class="input-form">
                        </td>
                        <td class="py-2 px-4 border-b">{{ calcularValorDeVendaNovo(produtoSelecionado) }}</td>
                    </tr>

                </tbody>
            </table>
        </div>

        <!-- Botão de Adicionar Produto -->
        <div class="flex justify-end mt-4" v-if="adicionarProdutoAtivo">
            <button class="btn-salvar" @click.prevent="adicionarProduto" :disabled="!produtoPreenchido">
                Adicionar Produto
            </button>
        </div>

        <!-- Botão de salvar as edições no modal de edição -->
        <div class="flex justify-end mt-4" v-if="editarProdutoAtivo">
            <button class="btn-salvar" @click.prevent="salvarProdutoEditado">Salvar Alterações</button>
        </div>

        <!-- Botão para adicionar novo produto -->
        <div class="flex justify-center mt-4">
            <button class="text-ativo" @click.prevent="abrirLinhaAdicionarProduto">
                <i class="fas fa-plus-circle"></i>
            </button>
        </div>

        <!-- Botões da paginação -->
        <Pagination v-if="produtosPorLinha.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
            @change-page="mudarPagina" />
    </div>

    <SucessoRapido v-if="sucesso === true" mensagem="Tabela alterada com sucesso!" />
    <ErroRapido v-if="erro === true" mensagem="Este produto já existe na tabela." />
</template>

<script>
import axios from 'axios';
import formatarData from '@/utils/FormatarData';
import Select2 from 'vue3-select2-component';
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import Pagination from '@/utils/Pagination.vue';

export default {
    name: 'PrecoLinha',
    props: {
        dadosPerfil: Object
    },
    components: {
        Select2,
        Pagination,
        SucessoRapido,
        ErroRapido,
    },
    data() {
        return {
            produtosPorLinha: [],
            listaLinhas: [],
            sucesso: false,
            erro: false,
            adicionarProdutoAtivo: false,
            novoProduto: {
                linha_aplicacao: '',
                custo: 0,
                icms: 0,
                ipi: 0,
                frete: 0,
                margem_adicional: 0,
            },
            editarProdutoAtivo: false,
            produtoSelecionado: {},
            countItens: 0,
            paginaAtual: 1
        };
    },
    methods: {

        teste(id) {
            console.log(id)
        },
        // Função para buscar os Perfis por Linha
        async buscarPerfisLinha(pagina) {
            console.log(pagina)
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`precificacao/itens-perfil-linha/?id_tabela_preco=${this.dadosPerfil.id}&page=${pagina}`, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
                this.produtosPorLinha = response.data.results;
                console.log(this.produtosPorLinha)
                this.countItens = response.data.count;
                console.log(this.countItens)
            } catch (error) {
                console.error('Erro ao buscar perfis por linha:', error);
            }
        },

        // Função para buscar as Linhas para o Select2
        async buscarListaLinhasPerfil() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get('/produtos/linha-aplicacao/', {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });
                console.log(response.data.results)
                this.listaLinhas = response.data.results.map(produto => ({
                    id: produto.id,
                    text: produto.nome,
                }));
            } catch (error) {
                console.error("Erro ao buscar lista de linhas de perfil:", error);
            }
        },

        // Função para Adicionar a Linha na Tabela
        async adicionarProduto() {
            if (this.produtoPreenchido) {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');
                try {
                    const response = await axios.post('precificacao/itens-perfil-linha/', {
                        tabela_preco_perfil: this.dadosPerfil.id,
                        linha_aplicacao: this.novoProduto.linha_aplicacao,
                        custo: this.novoProduto.custo,
                        icms: this.novoProduto.icms,
                        ipi: this.novoProduto.ipi,
                        frete: this.novoProduto.frete,
                        margem_adicional: this.novoProduto.margem_adicional,
                        valor_final_impostos: this.calcularValorComImpostosNovo(this.novoProduto),
                        valor_final_venda: this.calcularValorDeVendaNovo(this.novoProduto)
                    }, {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    });

                    this.buscarPerfisLinha(1);

                    this.novoProduto = {
                        linha_aplicacao: '',
                        custo: 0,
                        icms: 0,
                        ipi: 0,
                        frete: 0,
                        margem_adicional: 0,
                    };

                    this.adicionarProdutoAtivo = false;

                    this.sucesso = true
                    setTimeout(() => {
                        this.sucesso = false;
                    }, 3000);

                } catch (error) {

                    if (error.response.data[0] === "Este item já existe para esta tabela de preço.") {
                        this.erro = true
                        setTimeout(() => {
                            this.erro = false;
                        }, 3000);
                    } else {
                        console.error("Erro ao adicionar produto:", errorMessage);
                    }
                }
            }
        },

        abrirModalEditarProduto(produto) {
            this.produtoSelecionado = {
                ...produto,
                linha_aplicacao: produto.linha_aplicacao,
                custo: produto.custo,
            };
            this.editarProdutoAtivo = true;
        },

        fecharModal() {
            this.editarProdutoAtivo = false;
        },

        async salvarProdutoEditado() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                // Requisição para salvar as alterações do produto editado
                await axios.put(
                    `precificacao/itens-perfil-linha/${this.produtoSelecionado.id}/`,
                    {
                        tabela_preco_perfil: this.dadosPerfil.id,
                        linha_aplicacao: this.produtoSelecionado.linha_aplicacao.id,
                        custo: this.produtoSelecionado.custo,
                        icms: this.produtoSelecionado.icms,
                        ipi: this.produtoSelecionado.ipi,
                        frete: this.produtoSelecionado.frete,
                        margem_adicional: this.produtoSelecionado.margem_adicional,
                        valor_final_impostos: this.calcularValorComImpostosNovo(this.produtoSelecionado),
                        valor_final_venda: this.calcularValorDeVendaNovo(this.produtoSelecionado)
                    },
                    {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    }
                );

                this.buscarPerfisLinha(this.paginaAtual)

                this.sucesso = true
                setTimeout(() => {
                    this.sucesso = false;
                }, 3000);

                this.fecharModal();
            } catch (error) {
                console.error('Erro ao salvar o produto editado:', error);
            }
        },

        async deletePerfil(id) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                // Realiza a exclusão do perfil
                await axios.patch(`precificacao/itens-perfil-linha/${id}/`, { ativo: 'False' }, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                this.buscarPerfisLinha(1);

                this.sucesso = true
                setTimeout(() => {
                    this.sucesso = false;
                }, 3000);
            } catch (error) {
                console.error("Erro ao excluir o Perfil:", error);
            }
        },

        // Função para calcular os valores de produtos já existentes
        calcularValorComImpostosExistente(produto) {
            const custo = parseFloat(produto.custo) || 0;
            const icms = (parseFloat(produto.icms) / 100) || 0;
            const ipi = (parseFloat(produto.ipi) / 100) || 0;
            const frete = (parseFloat(produto.frete) / 100) || 0;

            // Calcula valor com ICMS e IPI
            let valorComImpostos = custo + (custo * icms) + (custo * ipi);
            // Aplica o frete sobre o valor com ICMS e IPI
            valorComImpostos += (valorComImpostos * frete);

            return valorComImpostos.toFixed(2);
        },

        calcularValorDeVendaExistente(produto) {
            const valorComImpostos = parseFloat(this.calcularValorComImpostosExistente(produto)) || 0;
            const margem = (parseFloat(produto.margem_adicional) / 100) || 0;

            const valorVenda = valorComImpostos + (valorComImpostos * margem);
            return valorVenda.toFixed(2);
        },

        // Função para calcular os valores de novos produtos
        calcularValorComImpostosNovo(produto) {
            const custo = parseFloat(produto.custo) || 0;
            const icms = (parseFloat(produto.icms) / 100) || 0;
            const ipi = (parseFloat(produto.ipi) / 100) || 0;
            const frete = (parseFloat(produto.frete) / 100) || 0;

            // Calcula valor com ICMS e IPI
            let valorComImpostos = custo + (custo * icms) + (custo * ipi);
            // Aplica o frete sobre o valor com ICMS e IPI
            valorComImpostos += (valorComImpostos * frete);

            return valorComImpostos.toFixed(2);
        },

        calcularValorDeVendaNovo(produto) {
            const valorComImpostos = parseFloat(this.calcularValorComImpostosNovo(produto)) || 0;
            const margem = (parseFloat(produto.margem_adicional) / 100) || 0;

            const valorVenda = valorComImpostos + (valorComImpostos * margem);
            return valorVenda.toFixed(2);
        },

        formatarDataProdutos(dataISO) {
            return formatarData(dataISO);
        },

        abrirLinhaAdicionarProduto() {
            this.adicionarProdutoAtivo = true;
        },

        mudarPagina(pagina) {
            this.paginaAtual = pagina;
            this.buscarPerfisLinha(pagina);
        },

    },
    computed: {
        produtoPreenchido() {
            return (
                this.novoProduto.linha_aplicacao !== '' &&
                this.novoProduto.custo > 0 &&
                this.novoProduto.icms > 0 &&
                this.novoProduto.ipi > 0 &&
                this.novoProduto.frete > 0 &&
                this.novoProduto.margem_adicional > 0
            );
        },
    },
    created() {
        this.buscarPerfisLinha(1);
        this.buscarListaLinhasPerfil();
        console.log(this.dadosPerfil)
    },
};
</script>

<style scoped>
.input-form {
    border: 1px solid #ccc;
    padding: 8px;
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 8px;
    text-align: left;
}

thead th {
    background-color: #f8f9fa;
}

tr:hover {
    background-color: #f1f1f1;
}

.overflow-x-auto {
    overflow-x: auto;
}
</style>
