<template>
  <div>
    <div class="container-modal-editar">
      <div class="sombra-modal">
        <div class="conteudo-modal-editar">
          <form class="form-editar text-white">
            <div class="flex justify-end">
              <button @click.prevent="$emit('fecharModal')">
                <i class="fas fa-close text-2xl text-corBase"></i>
              </button>
            </div>
            <h2 class="text-lg text-corBase font-bold mb-2">Editar Dados Componente/Acessório</h2>
            <img :src="this.componente.imagem.url_documento" alt="" class="w-[100px]">
            <div>
              <div class="container-campos">
                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Produto Base:</label>
                  <input type="text" required class="input-campos" v-model="componente.componente_base.nome_especifico">
                </div>

                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Fornecedor:</label>
                  <input type="text" required class="input-campos"
                    v-model="componente.componente_base.fornecedor.pessoa.nome_razao" readonly>
                </div>

                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Unidade de Medida:</label>
                  <input type="text" required class="input-campos" v-model="componente.unidade_medida_embalagem.codigo">
                </div>

              </div>

              <div class="container-campos">
                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Tipo de Componente:</label>
                  <input type="text" required class="input-campos" v-model="componente.tipo_componentes.nome">
                </div>
                <div class="campos-mobile">
                  <label class="label-campos">Grupo de Aplicação Componente:</label>
                  <input type="text" required class="input-campos" placeholder=""
                    v-model="componente.grupo_aplicacao.nome">
                </div>
              </div>

              <div class="container-campos">
                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Cor do Componente:</label>
                  <input type="text" required class="input-campos" v-model="componente.cor_componentes.nome">
                </div>
              </div>
            </div>

            <!--Botão-->
            <div class="btn-formulario-modal">
              <button class="btn-formulario-salvar" @click.prevent="putDadosEditado">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
      <SucessoRapido v-if="sucesso" mensagem="Componente/Acessório atualizado com sucesso." />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";

export default {
  name: "EditarComponenteAcessorio",
  data() {
    return {
      sucesso: false,
      componente: [],
      produtoBase: {},
      fonecedorComponente: {},
      grupoaplicacao: {},
      corcomponente: {},
      tipocomponente: {}

    }
  },
  components: {
    SucessoRapido
  },
  props: ["dadosComponente"],
  emits: ["fecharModal"],
  created() {
    this.getDadosComponente()
  },
  methods: {
    alterarModalEditar() {
      this.statusModal = !this.statusModal;
    },

    async getDadosComponente() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')
      try {
        const response = await axios.get(`produtos/componente-acessorio/${this.dadosComponente}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
        this.componente = response.data
        console.log(this.componente)
      } catch (error) {
        console.error(error)
      }
    },

    async putDadosEditado() {
      const formData = new FormData();
      formData.append('componente_base', this.componente.componente_base.id);
      formData.append('fornecedor', this.componente.componente_base.fornecedor.id);
      formData.append('unidade_medida_embalagem', this.componente.unidade_medida_embalagem.id);
      formData.append('imagem', this.componente.imagem.id);
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.patch(`produtos/componente-acessorio/${this.dadosComponente}/`, formData, {
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          }
        });
        console.log(response);
        this.sucesso = true;
        setTimeout(() => {
          this.sucesso = false;
          this.$emit('fecharModal')
        }, 3000);

      } catch (error) {
        console.log(error)
      }
    },

    // async getProdutoBase() {
    //   const token = await this.$store.dispatch('getAuthTokenCookie')
    //   await this.$store.dispatch('fetchURLrequest')

    //   await axios.get(`produtos/produto/${this.componente.produto_base.id}/`, {
    //     headers: {
    //       'Authorization': `Token ${token}`
    //     }
    //   })
    //     .then(response => {
    //       this.produtoBase = response.data
    //       console.log(this.produtoBase)
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     })

    // },

    // async getFornecedor() {
    //   const token = await this.$store.dispatch('getAuthTokenCookie')
    //   await this.$store.dispatch('fetchURLrequest')

    //   await axios.get(`pessoas/fornecedor/${this.componente.fornecedor.id}/`, {
    //     headers: {
    //       'Authorization': `Token ${token}`
    //     }
    //   })
    //     .then(response => {
    //       this.fornecedorChapa = response.data
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     })
    // },

    // async getGrupoAplicacao() {
    //   const token = await this.$store.dispatch('getAuthTokenCookie')
    //   await this.$store.dispatch('fetchURLrequest')

    //   await axios.get(`produtos/grupo-aplicacao-chapa/`, {
    //     headers: {
    //       'Authorization': `Token ${token}`
    //     }
    //   })
    //     .then(response => {
    //       this.grupoaplicacao = response.data
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     })
    // },

    // async getCorChapa() {
    //   const token = await this.$store.dispatch('getAuthTokenCookie')
    //   await this.$store.dispatch('fetchURLrequest')

    //   await axios.get(`produtos/cor-chapa/`, {
    //     headers: {
    //       'Authorization': `Token ${token}`
    //     }
    //   })
    //     .then(response => {
    //       this.corchapas = response.data
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     })
    // },

    // async getTipoChapa() {
    //   const token = await this.$store.dispatch('getAuthTokenCookie')
    //   await this.$store.dispatch('fetchURLrequest')

    //   await axios.get(`produtos/tipo-chapa/`, {
    //     headers: {
    //       'Authorization': `Token ${token}`
    //     }
    //   })
    //     .then(response => {
    //       this.tipochapa = response.data
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     })
    // }

  }
}
</script>

<style></style>