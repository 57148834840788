<template>


  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Fornecedores</h1>
      </div>

      <router-link to="/pessoas" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="mb-8 flex items-end gap-8">
      <div class="campo-chapa">
        <label>Tipo Fornecedor</label>
        <v-select v-model="tipo_fornecedor" :options="lista_tipo_fornecedor" class="w-full" label="label"
          :reduce="tipo => tipo.value" />
      </div>

      <div class="campo-chapa">
        <label>Status</label>
        <select v-model="status" class="input-campos">
          <option value="True">Ativo</option>
          <option value="False">Inativo</option>
        </select>
      </div>
      <div>
        <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="getListaFornecedor(1)">Pesquisar
        </button>
      </div>
    </div>
    <div class="mb-8">
      <button @click="$router.push(`/cadastro-fornecedor`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 mt-2 mr-2">Adicionar
      </button>

    </div>


    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              #
            </th>
            <th class="px-6 py-3">
              Nome
            </th>
            <th class="px-6 py-3">
              Tipo Fornecedor
            </th>
            <th scope="col" class="px-6 py-3">
              Status
            </th>
            <th scope="col" class="px-6 py-3">
              Ações
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(fornecedor, index) in listaFornecedor" :key="index" class="escopo-tabela">
            <!--          <pre>{{ fornecedor }}</pre>-->
            <template v-if="fornecedor.pessoa_juridica !== null">
              <td class="w-4 p-4">
                <div class="flex items-center">
                  <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                    v-model="fornecedor.selected">
                  <label class="sr-only">checkbox</label>
                </div>
              </td>
              <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {{ index + 1 }}
              </th>
              <td class="px-6 py-4">
                {{ fornecedor.pessoa.nome_razao }}
              </td>
              <td class="px-6 py-4">
                {{ fornecedor.id_tipo_fornecedor.nome }}
              </td>
              <template v-if="fornecedor.pessoa.pessoa_juridica !== null">
                <template v-if="fornecedor.pessoa.pessoa_juridica.ativo === true">
                  <td class="px-6 py-4">
                    <i class="fa-solid fa-circle-check  text-ativo  ml-3"></i>
                  </td>
                </template>
                <template v-if="fornecedor.pessoa.pessoa_juridica.ativo === false">
                  <td class="px-6 py-4">
                    <i class="fa-solid fa-circle-xmark text-inativo  ml-3"></i>
                  </td>
                </template>
              </template>

              <td class="px-6 py-4">
                <a @click="selecionarPessoaEditar(fornecedor)" href="#" class="font-medium text-corBase hover:underline">
                  <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
                </a>
              </td>
            </template>

          </tr>
        </tbody>
      </table>
      <Pagination v-if="listaFornecedor.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
      <editar-dados-pessoa-juridica v-if="mostrarModal" :dados_pessoa_juridica="fornecedorSelecionado" @fecharModal="toggleModal" />
    </div>
  </div>

</template>

<script>
import EditarDadosPessoaJuridica from "@/components/midais/Editar/EditarDadosPessoaJuridica.vue";
import vSelect from "vue-select";
import Pagination from "@/utils/Pagination.vue";
import axios from "axios";

export default {
  name: "Listafornecedor",

  components: {
    EditarDadosPessoaJuridica,
    vSelect,
    Pagination
  },
  // id: "4757600f-3d09-47b0-9086-4b76de04fa36"
  // id: "4757600f-3d09-47b0-9086-4b76de04fa36",
  data() {
    return {
      checkAll: false,
      listaFornecedor: [],
      lista_tipo_fornecedor: [],
      tipo_fornecedor: '',
      paginaAtual: 1,
      countItens: '',
      filtros: false,
      status: '',
      fornecedorSelecionado: null,
      mostrarModal: false,
    }
  },

  created() {
    this.getListaTipoFornecedor()
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  methods: {

    selecionarPessoaEditar(pessoa) {
      this.fornecedorSelecionado = pessoa
      this.toggleModal()
    },

    toggleModal(){
      this.mostrarModal = !this.mostrarModal
    },

    async getListaTipoFornecedor() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get('pessoas/tipo-fornecedor/?page_size=9999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.lista_tipo_fornecedor = response.data.results.map(tipo => ({
          value: tipo.id,
          label: tipo.nome
        }));

      } catch (error) {
        console.error("Erro ao buscar lista de tipos de fornecedor:", error);
      }
    },
    selectAll() {
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    async getListaFornecedor(pagina = 1) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      this.$store.dispatch("setLoading", true);

      try {
        const response = await axios.get(`pessoas/fornecedor/?tipo_fornecedor=${this.tipo_fornecedor || ''}&ativo=${this.status}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.listaFornecedor = response.data.results;
        this.countItens = response.data.count;
        console.log(this.listaFornecedor);

      } catch (error) {
        console.error("Erro ao buscar lista de fornecedores:", error);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina;
      this.getListaFornecedor(pagina);
    },

  }
}
</script>

<style scoped></style>