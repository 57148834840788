<template>
  <div>
    <div class="container-modal-editar">
      <div class="sombra-modal">
        <div class="conteudo-modal-editar">
          <form class="form-editar text-white">
            <div class="flex justify-end">
              <button @click.prevent="$emit('fecharModal')">
                <i class="fas fa-close text-2xl text-corBase"></i>
              </button>
            </div>
            <h2 class="text-lg text-corBase font-bold mb-2">Editar Dados Perfil</h2>
            <!--Campos da Pessoa-->
            <div>
              <div class="container-campos">
                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Produto Base:</label>
                  <input type="text" required class="input-campos"
                    v-model="perfil.perfil_base_fornecedor.nome_especifico">
                </div>

                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Fornecedor:</label>
                  <input type="text" required class="input-campos"
                    v-model="perfil.perfil_base_fornecedor.fornecedor.pessoa.nome_razao" readonly>
                </div>

                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Linha:</label>
                  <input type="text" required class="input-campos" v-model="perfil.linha_aplicacao.nome">
                </div>

                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Tipo de Perfil:</label>
                  <input type="text" required class="input-campos" v-model="perfil.tipo_perfil.nome">
                </div>

              </div>

              <div class="container-campos">
                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Nome Especifico:</label>
                  <input type="text" required class="input-campos" v-model="perfil.nome_perfil_especifico">
                </div>
                <div class="campos-mobile">
                  <label class="label-campos">Cor do Perfil:</label>
                  <input type="text" required class="input-campos" placeholder="" v-model="perfil.cor_perfil.nome">
                </div>
                <div class="campos-mobile">
                  <label class="label-campos">Preço Base:</label>
                  <input type="text" required class="input-campos" placeholder=""
                    v-model="perfil.perfil_base_fornecedor.preco_custo">
                </div>
              </div>
              <!--Botão-->
              <div class="btn-formulario-modal">
                <button class="btn-formulario-salvar" @click.prevent="putDadosEditado">
                  Salvar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <SucessoRapido v-if="sucesso" mensagem="Perfil atualizado com sucesso." />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";

export default {
  name: "EditarPerfil",
  components: {
    SucessoRapido
  },
  data() {
    return {
      statusModal: false,
      sucesso: false,
      perfil: [],
      produtoBase: {},
      fonecedorChapa: {},
      grupoaplicacao: {},
      corchapas: {},
      tipochapa: {}

    }
  },
  props: ["dadosPerfil"],
  emits: ["fecharModal"],
  created() {
    console.log(this.perfil)
    this.getDadosPerfil()
  },
  methods: {

    async getDadosPerfil() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')
      try {
        const response = await axios.get(`produtos/perfil/${this.dadosPerfil}/`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
        console.log(response.data)
        this.perfil = response.data
      } catch (error) {
        console.error(error)
      }
    },

    async putDadosEditado() {
      const dadosAtualizados = new FormData();
      dadosAtualizados.append('ativo', this.perfil.ativo)
      dadosAtualizados.append('perfil_base_fornecedor', this.perfil.perfil_base_fornecedor.id)
      // dadosAtualizados.append('perfil_base_fornecedor.fornecedor', this.perfil.perfil_base_fornecedor.fornecedor.id)
      // dadosAtualizados.append('perfil_base_fornecedor.preco_custo', this.perfil.perfil_base_fornecedor.preco_custo)
      dadosAtualizados.append('linha_aplicacao', this.perfil.linha_aplicacao.id)
      dadosAtualizados.append('tipo_perfil', this.perfil.tipo_perfil.id)
      dadosAtualizados.append('nome_perfil_especifico', this.perfil.nome_perfil_especifico)
      dadosAtualizados.append('cor_perfil', this.perfil.cor_perfil.id)



      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.patch(`produtos/perfil/${this.perfil.id}/`, dadosAtualizados, {
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          }
        });
        console.log(response);
        this.sucesso = true;
        setTimeout(() => {
          this.sucesso = false;
          this.$emit('fecharModal')
        }, 3000);

      } catch (error) {
        console.log(error)
      }
    },

    async getProdutoBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/produto/${this.perfil.produto_base.id}/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.produtoBase = response.data
          console.log(this.produtoBase)
        })
        .catch(error => {
          console.log(error)
        })

    },

    async getFornecedor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`pessoas/fornecedor/${this.perfil.fornecedor.id}/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.fornecedorChapa = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getGrupoAplicacao() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/grupo-aplicacao-chapa/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.grupoaplicacao = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getCorChapa() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/cor-chapa/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.corchapas = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getTipoChapa() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/tipo-chapa/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.tipochapa = response.data
        })
        .catch(error => {
          console.log(error)
        })
    }

  }
}
</script>

<style></style>