<template>
    <div class="space-y-4">
        <div class="flex justify-center">
            <label for="arquivo"
                class="cursor-pointer bg-gray-400 text-white py-2 px-4 rounded-lg font-semibold shadow-md hover:bg-gray-500 hover:shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1">
                Clique aqui para adicionar um arquivo
            </label>
        </div>
        <input type="file" class="hidden" @change="adicionarArquivo" accept=".pdf" multiple id="arquivo">

        <div class="flex flex-wrap gap-4 mt-4 items-center justify-center">
            <div v-for="(arquivo, index) in obra.arquivos" :key="index"
                class="flex items-center p-4 bg-gray-100 rounded-lg shadow-lg cursor-pointer hover:bg-gray-200 transition duration-300 w-full max-w-xs sm:w-[48%] lg:w-[30%]"
                @click="abrirArquivo(arquivo)">
                <div class="relative bottom-5 left-72 justify-end text-corBase cursor-pointer" @click.stop.prevent="excluirArquivo(arquivo)">
                    <i class="fa-solid fa-x"></i>
                </div>

                <div class="text-3xl mr-4 text-corBase flex-shrink-0">
                    <i class="fa-solid fa-file-pdf"></i>
                </div>

                <div class="flex-1 overflow-hidden">
                    <p class="text-gray-700 font-medium truncate">{{ arquivo.name || arquivo.descricao }}</p>
                </div>

            </div>
        </div>
        <ErroRapido v-if="erro === true" mensagem="Alguns arquivos não foram adicionados por não serem PDF." />
    </div>
</template>

<script>
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import axios from "axios"

export default {
    name: 'Documentos',
    props: ["obra"],
    emits: ["atualizarLista"],
    data() {
        return {
            erro: false,
        }
    },
    components: {
        ErroRapido
    },
    methods: {
        adicionarArquivo(event) {
            const novosArquivos = Array.from(event.target.files);
            const arquivosValidos = novosArquivos.filter(arquivo =>
                ["application/pdf"].includes(arquivo.type)
            );
            const arquivosInvalidos = novosArquivos.filter(arquivo => arquivo.type !== "application/pdf");

            if (arquivosInvalidos.length > 0) {
                this.erro = true
                setTimeout(() => {
                    this.erro = false;
                }, 3000);
            }

            if (this.obra.arquivos) {
                this.obra.arquivos = [...this.obra.arquivos, ...arquivosValidos];
            } else {
                this.obra.arquivos = arquivosValidos;
            }

            console.log(this.obra.arquivos);
        },

        abrirArquivo(arquivo) {
            if (arquivo.url_documento) {
                window.open(arquivo.url_documento, "_blank");
            } else {
                const url = URL.createObjectURL(arquivo);
                window.open(url, '_blank');
            }
        },

        async excluirArquivo(arquivo) {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            try {
                if (arquivo.id) {
                    const response = await axios.patch(`obra/documentos-obra/${arquivo.id}/`, { 'ativo': 'False' }, {
                        headers: {
                            'Authorization': `Token ${token}`
                        }
                    });
                    console.log("Arquivo desativado:", response.data);
                    this.$emit('atualizarLista')
                } else {
                    const index = this.obra.arquivos.indexOf(arquivo);
                    if (index !== -1) {
                        this.obra.arquivos.splice(index, 1);
                    }
                    console.log("Arquivo removido da lista local.");
                }
            } catch (error) {
                console.error("Erro ao excluir o arquivo:", error);
            }
        }

    }
};
</script>

<style scoped></style>