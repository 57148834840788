<template>
    <div class="container-formulario">
        <!-- Cabeçalho -->
        <div class="container-cabecalho flex justify-between mb-4">
            <div>
                <h1 class="text-xl text-corBase font-bold">Editar Vão - {{ vao.descricao }} </h1>
            </div>

            <router-link :to="`/obra/${$route.params.id_obra}`" class="text-xl text-corBase font-bold">
                <i class="fas fa-chevron-left"></i>
            </router-link>
        </div>

        <!-- Formulário de edição de vão -->
        <div>
            <div class="form-group flex items-center">
                <label for="descricao" class="mr-2 text-corBase">Descrição</label>
                <input v-model="vao.descricao" type="text" id="descricao" placeholder="Digite a descrição..."
                    required />
            </div>

            <div class="form-group flex items-center">
                <label for="tipologia" class="mr-2 text-corBase">Tipologia</label>
                <select class="cursor-pointer" v-model="tipologiaSelecionada" @change="onTipologiaChange">
                    <option value="" disabled>Selecione uma tipologia</option>
                    <option v-for="tipologia in tipologias" :key="tipologia.id" :value="tipologia.id">
                        {{ tipologia.nome }}
                    </option>
                </select>
            </div>

            <!-- Lista de linhas baseada na tipologia selecionada -->
            <div class="form-group" v-if="linhas.length > 0">
                <p>Linhas disponíveis:</p>
                <div v-for="linha in linhas" :key="linha.id" class="flex">
                    <label class="mr-2">{{ linha.nome }}</label>
                    <input type="checkbox" :value="linha" v-model="linhasSelecionadas" />
                </div>
                <button type="button" class="btn-salvar" @click="adicionarTipologia">Adicionar Tipologia</button>
            </div>

            <!-- Seção de abas -->
            <div class="bg-gray-300 p-5 pt-0 rounded-lg">
                <div class="abas-container">
                    <template v-for="(aba, index) in abas" :key="index">
                        <div :class="['aba', { 'aba-selecionada': abaSelecionada === index }]">
                            {{ aba.nome }}
                        </div>
                    </template>
                </div>

                <div v-if="abas.length > 0">
                    <div v-for="(aba, index) in abas" :key="index" v-show="abaSelecionada === index">

                        <!-- Aba Geral -->
                        <GeralComponent v-if="aba.nome === 'Geral'" :vao="vao" :orcamento="orcamento"
                            @update-file="onFileChange" />

                        <!-- Aba Projetos -->
                        <div v-else-if="aba.nome === 'Projetos'">
                            <h2 class="text-lg font-bold text-corBase pb-2">Projetos:</h2>
                            <div v-if="projetos.length > 0">
                                <ul class="flex flex-wrap gap-6 justify-center">
                                    <li v-for="projeto in projetos" :key="projeto.projeto.id"
                                        class="flex flex-col items-center w-60 p-4 bg-white border border-corBase/40 shadow-md rounded-md">
                                        <img src="@/assets/imagens/fotos/janela2folhas.png" alt="Imagem do Projeto"
                                            class="w-40 h-40 object-cover mb-4">
                                        <strong class="block text-base font-semibold">Projeto: </strong>
                                        <span class="text-gray-700 whitespace-nowrap text-sm pb-3">
                                            <p>{{ projeto.projeto[0].descricao_fixa }}</p>
                                        </span>
                                        <hr>

                                    </li>
                                </ul>
                            </div>
                            <div v-else>
                                <p>Nenhum projeto encontrado para este vão.</p>
                            </div>
                        </div>


                        <!-- Aba correspondente a tipologia -->
                        <div v-else class="flex items-start">
                            <!-- Coluna das imagens -->
                            <div class="w-1/2 flex flex-col items-center text-center">
                                <h4 class="text-corBase">Imagem da tipologia:</h4>
                                <img :src="aba.imagem.url_documento" class="foto-tipologia w-32 h-auto cursor-pointer"
                                    alt="Imagem tipologia" @click="abrirModal(aba.imagem.url_documento)">

                                <div v-if="vao.imagem">
                                    <h4 class="text-corBase mt-10">Imagem do vão:</h4>
                                    <img :src="vao.imagem.url_documento"
                                        class="foto-tipologia w-32 h-auto cursor-pointer object-cover"
                                        alt="Pré-visualização" @click="abrirModal(vao.imagem.url_documento)" />
                                </div>
                            </div>

                            <!-- Linha de separação vertical -->
                            <div class="w-px h-full bg-gray-600 mx-4"></div>

                            <!-- Coluna das informações da linha -->
                            <div class="w-1/2 flex flex-col space-y-4">
                                <!-- Abas internas para linhas -->
                                <div class="linhas-abas-container">
                                    <template v-for="(linha, linhaIndex) in aba.linhas" :key="linhaIndex">
                                        <div :class="['linha-aba', { 'linha-aba-selecionada': linhaSelecionada === linhaIndex }]"
                                            @click="selecionarLinha(linhaIndex, aba)">
                                            {{ linha.nome }}
                                        </div>
                                    </template>
                                </div>

                                <!-- Exibe o componente CriarVariaveisOrcamento quando o projeto for selecionado -->
                                <div v-if="mostrarVariaveisPorLinha[`${aba.id}_${aba.linhas[linhaSelecionada]?.nome}`]">
                                    <CriarVariaveisOrcamento
                                        :id="projetoSelecionadoPorLinha[`${aba.id}_${aba.linhas[linhaSelecionada]?.nome}`]" />
                                </div>

                                <!-- Conteúdo da linha selecionada -->
                                <div v-else-if="aba.linhas.length > 0">
                                    <div v-for="(linha, linhaIndex) in aba.linhas" :key="linhaIndex"
                                        v-show="linhaSelecionada === linhaIndex">

                                        <!-- Verifica se um projeto foi selecionado -->
                                        <div v-if="projetosSelecionados[`${aba.nome}_${linha.nome}`]">
                                            <p class="text-corBase">Projeto Selecionado:</p>
                                            <p><strong>Descrição:</strong> {{
                                                projetosSelecionados[`${aba.nome}_${linha.nome}`].descricao_fixa }}</p>
                                            <p>Ocorreu um erro. Reinicie a página</p>
                                        </div>

                                        <!-- Exibição dos projetos  -->
                                        <div v-else>
                                            <p class="text-corBase font-semibold text-center pb-5">Lista de Projetos</p>
                                            <!-- Projetos do tenant atual -->
                                            <ListaProjetos v-if="listaProjetos[linha.nome]?.length > 0"
                                                :projetos="listaProjetos[linha.nome]" :abaId="aba.id"
                                                :nomeDaLinha="linha" @projeto-selecionado="incluirItemOrcamento" />

                                            <!-- Projetos do tenant público -->
                                            <ListaProjetos v-if="listaProjetosPublic[linha.nome]?.length > 0"
                                                :projetos="listaProjetosPublic[linha.nome]" :abaId="aba.id"
                                                :nomeDaLinha="linha" @projeto-selecionado="incluirItemOrcamento" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Botões para navegação entre abas -->
                <div class="flex w-full justify-end itens-center mt-6">
                    <button v-if="abaSelecionada > 0" @click="voltarAba" class="btn-cancelar">Voltar</button>
                    <button v-if="abaSelecionada < abas.length - 1" @click="onAvancarAba"
                        class="btn-salvar">Avançar</button>
                    <button v-else @click="onAvancarAba" class="btn-salvar">Salvar</button>
                </div>
            </div>
        </div>
    </div>

    <SucessoRapido v-if="sucesso" mensagem="Vão atualizado com sucesso." />
    <ErroRapido v-if="erro" :mensagem="erroMessage" />
    <ModalVariaveisOrcamento v-if="mostrarVariavel" :id="projeto_id" @fecharModalVariaveis="toggleVariaveis" />
</template>


<script>
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import GeralComponent from './ComponentesCriacaoVao/GeralComponent.vue';
import CriarVariaveisOrcamento from './ComponentesCriacaoVao/CriarVariaveisOrcamento.vue';
import ModalVariaveisOrcamento from '@/components/cadastros_orcamentos_componentes/ModalVariaveisOrcamento.vue';
import ListaProjetos from './ComponentesCriacaoVao/ListaProjetos.vue';
import axios from 'axios';

export default {
    name: 'EditarVao',

    components: {
        SucessoRapido,
        ErroRapido,
        GeralComponent,
        CriarVariaveisOrcamento,
        ListaProjetos,
        ModalVariaveisOrcamento
    },

    data() {
        return {
            vao: {
                descricao: '',
                largura: null,
                altura: null,
                local_vao: '',
                cor_componente: '',
                cor_perfil: '',
                cor_vidro: '',
                imagem: null,
                imagePreview: null
            },

            orcamento: {
                tipoMedidaContramarco: '',
                contramarco: false,
                arremate: false,
                arrematePiso: false
            },

            orcamentoCriado: false,

            tipologiaSelecionada: null,

            tipologias: [],
            linhas: [],
            linhasSelecionadas: [],

            abas: [
                { nome: 'Geral', ativo: true },
                { nome: 'Projetos', ativo: true }
            ],

            abaSelecionada: 0,
            linhaSelecionada: 0,

            sucesso: false,
            erro: false,
            erroMessage: '',

            fotoAmpliada: null,

            projetosPorLinha: {},
            projetosSelecionados: {},

            listaProjetosSelecionados: [],
            listaProjetos: {},
            listaProjetosPublic: {},

            projetoSelecionadoPorLinha: {},

            mostrarVariavel: false,
            mostrarVariaveisPorLinha: {},
            projetos: [],

            vaoId: '',
            projeto_id: '',

        };
    },

    created() {
        this.vaoId = this.$route.params.vao;
        this.buscarDadosVao();
        this.getTipologias();
    },

    methods: {

        // Modal de Variáveis do Projeto
        toggleVariaveis(projetoId) {
            this.mostrarVariavel = !this.mostrarVariavel
            this.projeto_id = projetoId
        },

        // Buscar os dados do vão
        async buscarDadosVao() {

            const token = await this.$store.dispatch("getAuthTokenCookie");
            await this.$store.dispatch("fetchURLrequest");

            try {
                const response = await axios.get(`/obra/vao-obra/${this.vaoId}`, {
                    headers: { Authorization: `Token ${token}` }
                });

                this.vao = response.data;

                // console.log(this.vao)

                if (this.vao.orcamento) {
                    this.orcamentoCriado = true
                    this.orcamento.tipoMedidaContramarco = this.vao.orcamento.tipo_medida_contramarco === 'E' ? 'Externa' : 'Interna';
                    this.orcamento.contramarco = this.vao.orcamento.contramarco;
                    this.orcamento.arremate = this.vao.orcamento.arremate;
                    this.orcamento.arrematePiso = this.vao.orcamento.arremate_piso;
                }

                await this.buscarProjetosVao();
            } catch (error) {
                console.error('Erro ao buscar dados do vão:', error);
            }
        },

        // Buscar projetos do vão
        async buscarProjetosVao() {
            const token = await this.$store.dispatch("getAuthTokenCookie");
            await this.$store.dispatch("fetchURLrequest");

            try {
                const response = await axios.get(`obra/linhas-projeto-vao-obra/?vao=${this.vaoId}`, {
                    headers: { Authorization: `Token ${token}` }
                });

                this.projetos = response.data.results;
                console.log(this.projetos)
            } catch (error) {
                console.error('Erro ao buscar dados do orçamento:', error);
            }
        },
        // Teste Projeto Perfil, Teste Janela 2 folhas, teste jaela, teste janela 2 folhas
        async salvarVao() {

            // console.log(this.vao.imagem)

            const formDataVao = new FormData();
            formDataVao.append('obra', this.vao.obra);
            formDataVao.append('ativo', 'true');
            formDataVao.append('descricao', this.vao.descricao);
            formDataVao.append('largura', this.vao.largura);
            formDataVao.append('altura', this.vao.altura);
            formDataVao.append('orcamento', this.vao.orcamento.id);
            formDataVao.append('local_vao', this.vao.local_vao);
            if (this.vao.imagem) {
                formDataVao.append('imagem', this.vao.imagem.id);
            }
            // formDataVao.append('imagePreview', this.vao.imagePreview);
            formDataVao.append('cor_componente', this.vao.cor_componente);
            formDataVao.append('cor_perfil', this.vao.cor_perfil);
            formDataVao.append('cor_vidro', this.vao.cor_vidro);

            const token = await this.$store.dispatch("getAuthTokenCookie");
            await this.$store.dispatch("fetchURLrequest");

            try {
                const response = await axios.patch(`/obra/vao-obra/${this.vaoId}/`, formDataVao, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                })

                await this.salvarProjetos(this.vao.id, this.vao.orcamento.id)

                this.sucesso = true
                setTimeout(() => {
                    this.sucesso = false;
                }, 3000);
                // console.log(response.data)
            } catch (error) {
                console.error
            }
        },

        async incluirItemOrcamento(projeto) {
            // Está vindo tudo como um único objeto (projeto) do componente ListaProjetos

            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                const data = {
                    orcamento: this.vao.orcamento.id,
                    projeto: projeto.projeto.id,
                    descricao: this.vao.descricao,
                    quantidade: this.quantidade,
                    largura: this.vao.largura,
                    altura: this.vao.altura,
                    contramarco: this.orcamento.contramarco,
                    arremate: this.orcamento.arremate,
                    arremate_piso: this.orcamento.arrematePiso,
                    cor_componentes: this.vao.cor_componente,
                    cor_perfis: this.vao.cor_perfil,
                    cor_vidros: this.vao.cor_vidro,
                    status_medidas: "2"
                };

                const response = await axios.post('orcamentos/item-orcamento/', data, {
                    headers: { Authorization: `Token ${token}` }
                });

                this.listaProjetosSelecionados.push({
                    projetoId: projeto.projeto.id,
                    linhaId: projeto.nomeDaLinha.id
                });

                this.idItemOrcamento = response.data.id;
                this.projetoSelecionadoPorLinha[`${projeto.abaId}_${projeto.nomeDaLinha.nome}`] = response.data.id;
                this.mostrarVariaveisPorLinha[`${projeto.abaId}_${projeto.nomeDaLinha.nome}`] = true;
            } catch (error) {
                console.error(error);
            }
        },

        onFileChange(file) {
            const imageUrl = URL.createObjectURL(file);
            this.vao.imagem = file;
            this.vao.imagePreview = imageUrl;
        },

        async getTipologias() {
            const token = await this.$store.dispatch("getAuthTokenCookie");
            await this.$store.dispatch("fetchURLrequest");

            try {
                const response = await axios.get(`/projeto/tipologia/`, {
                    headers: { Authorization: `Token ${token}` }
                });
                this.tipologias = response.data.results;

                // console.log(this.tipologias)
            } catch (error) {
                console.error(error);
            }
        },

        selecionarLinha(linhaIndex, aba) {
            this.linhaSelecionada = linhaIndex;
            const chave = `${aba.id}_${aba.linhas[linhaIndex].nome}`;
            this.mostrarVariaveis = !!this.mostrarVariaveisPorLinha[chave];
        },

        // Carrega as linhas com base na tipologia selecionada
        async onTipologiaChange() {
            await this.getLinhas();
            this.linhasSelecionadas = [];
        },

        async getLinhas() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');

            try {
                const response = await axios.get(`/produtos/linha-aplicacao/`, {
                    headers: { Authorization: `Token ${token}` }
                });
                this.linhas = response.data.results;
            } catch (error) {
                console.error('Erro ao carregar linhas:', error);
            }
        },

        // Função para salvar os projetos associados ao vão
        async salvarProjetos(id_vao) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            // Itera sobre os projetos selecionados, usando o índice para acessar o projeto correto
            for (let index = 0; index < this.listaProjetosSelecionados.length; index++) {
                const { projetoId, linhaId } = this.listaProjetosSelecionados[index];
                const formDataProjeto = new FormData();

                formDataProjeto.append('vao_obra', id_vao);
                formDataProjeto.append('ativo', 'true');

                // Buscar o ID do projeto selecionado baseado na ordem das linhas escolhidas
                const chave = Object.keys(this.projetoSelecionadoPorLinha)[index]; // Acessando pela posição (index)

                const projetoSelecionadoId = this.projetoSelecionadoPorLinha[chave];

                if (!projetoSelecionadoId) {
                    console.error(`ID do projeto não encontrado para a linha ${chave}`);
                    continue;  // Se não encontrar o ID, pula essa iteração e tenta a próxima
                }

                formDataProjeto.append('item_orcamento', projetoSelecionadoId);
                formDataProjeto.append('projeto', projetoId);
                formDataProjeto.append('linhas', linhaId);

                try {
                    await axios.post(`obra/linhas-projeto-vao-obra/`, formDataProjeto, {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    });
                } catch (error) {
                    console.error(`Erro ao enviar o projeto para a linha ${linhaId}:`, error);
                }
            }
        },

        // Adiciona a tipologia e as linhas selecionadas como aba
        async adicionarTipologia() {
            const tipologia = this.tipologias.find(t => t.id === this.tipologiaSelecionada);
            if (tipologia && this.linhasSelecionadas.length > 0) {
                const uniqueAbaId = `${tipologia.nome}_${this.abas.length}`;

                this.abas.push({
                    nome: `${tipologia.nome}`,
                    ativo: true,
                    id: uniqueAbaId,
                    id_tipologia: tipologia.id,
                    linhas: this.linhasSelecionadas.slice(),
                    imagem: tipologia.imagem
                });

                for (const linha of this.linhasSelecionadas) {
                    const projetos = await this.buscarProjetos(tipologia.id, linha.nome);
                    this.projetosPorLinha[`${uniqueAbaId}_${linha.nome}`] = projetos;
                }

                this.tipologiaSelecionada = null;
                this.linhasSelecionadas = [];
                this.linhas = [];
            }
        },

        async buscarCliente() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')
            try {
                const response = await axios.get(`/obra/?obra=${this.$route.params.id_obra}`,
                    {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    }
                )
                this.cliente = response.data.results[0].cliente.id
            } catch (error) {
                console.error(error)
            }
        },

        async criarOrcamento() {
            await this.buscarCliente();

            const data = {
                descricao: this.vao.descricao,
                contramarco: this.orcamento.contramarco,
                tipo_medida_contramarco: this.orcamento.tipoMedidaContramarco === 'Externa' ? 'E' : 'I',
                arremate: this.orcamento.arremate,
                arremate_piso: this.orcamento.arrematePiso,
                cliente: this.cliente
            };

            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.post('orcamentos/', data, {
                    headers: { Authorization: `Token ${token}` }
                });

                this.vao.orcamento = response.data;
                this.orcamentoCriado = true
            } catch (error) {
                console.error(error);
            }
        },

        async buscarProjetos(tipologiaId, linhaNome) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            try {
                const response = await axios.get(
                    `/projeto/projeto/?tipologia=${tipologiaId}&linha_nome=${linhaNome}`, {
                    headers: { Authorization: `Token ${token}` }
                });

                this.listaProjetos[linhaNome] = response.data.current_tenant;
                this.listaProjetosPublic[linhaNome] = response.data.public_tenant;
            } catch (error) {
                console.error('Erro ao buscar projetos:', error);
                return [];
            }
        },

        voltarAba() {
            if (this.abaSelecionada > 0) {
                this.abaSelecionada--;
            }
        },

        proximaAba() {
            if (this.abaSelecionada < this.abas.length - 1) {
                this.abaSelecionada++;
            }
        },

        async onAvancarAba() {
            // Verificação de campos obrigatórios na aba "Geral"
            if (this.abaSelecionada === 0) {
                // Verifica se todos os campos obrigatórios estão preenchidos
                if (!this.vao.descricao || !this.vao.largura || !this.vao.altura || !this.vao.cor_componente || !this.vao.cor_perfil || !this.vao.cor_vidro) {
                    this.erro = true;
                    this.erroMessage = "Preencha todos os campos obrigatórios.";
                    setTimeout(() => {
                        this.erro = false;
                        this.erroMessage = '';
                    }, 3000);
                    return;
                }

                // Se o orçamento não foi criado, cria um novo orçamento
                if (!this.vao.orcamento && !this.orcamentoCriado) {
                    try {
                        await this.criarOrcamento();
                    } catch (error) {
                        console.error("Erro ao criar orçamento:", error);
                        this.erro = true;
                        this.erroMessage = "Erro ao criar o orçamento.";
                        setTimeout(() => {
                            this.erro = false;
                            this.erroMessage = '';
                        }, 3000);
                        return;
                    }
                }

                // Avançar para a próxima aba
                this.proximaAba();

            } else if (this.abaSelecionada === this.abas.length - 1) {
                await this.salvarVao();

            } else {
                this.proximaAba();
            }
        }

    }
};
</script>

<style scoped>
label {
    display: block;
    font-weight: bold;
}

input[type="file"],
input[type="number"],
input[type="text"],
select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.abas-container {
    display: flex;
    margin-top: 15px;
    @apply mt-4 p-2 border-b rounded-lg
}

.aba {
    padding: 10px;
    cursor: pointer;
    margin-right: 5px;
    font-weight: 700;
    transition: 0.3s ease-in-out;
    @apply rounded-lg bg-white text-corBase border border-white
}

.aba-selecionada {
    @apply bg-corBase text-white border border-white
}

.foto-tipologia {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    z-index: 10;
}

.linhas-abas-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    gap: 5px;
}

.linha-aba {
    padding: 10px;
    cursor: pointer;
    margin-top: 2px;
    margin-right: 5px;
    font-weight: 700;
    transition: 0.3s ease-in-out;
    white-space: nowrap;
    @apply rounded-lg bg-white text-corBase border border-white
}

.linha-aba-selecionada {
    @apply bg-corBase text-white border border-white
}

.modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-foto {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
}
</style>