<template>
    <div class="container-modelo-tabela">
        <table class="container-tabela overflow-auto">
            <thead class="cabecalho-tabela">
                <tr>
                    <th scope="col" class="px-3 py-3">#</th>
                    <th scope="col" class="px-3 py-3">Variável</th>
                    <th scope="col" class="px-3 py-3">Opção Selecionada</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(variavel, index) in variaveis" :key="variavel.id" class="escopo-tabela text-left">
                    <th scope="row"
                        class="px-3 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                        {{ index + 1 }}
                    </th>
                    <td class="px-3 w-60 py-2 text-xs">
                        {{ variavel.descricao }}
                    </td>
                    <td class="px-3 py-2 text-xs">
                        <!-- Exibe a descrição da opção selecionada ou o valor manual -->
                        <div v-if="getOpcoesByVariavelId(variavel.id).length > 0">
                            {{ getDescricaoOpcaoSelecionada(variavel.id) || 'Nenhuma opção selecionada' }}
                        </div>
                        <div v-else>
                            {{ selected[variavel.id] || 'Nenhum valor definido' }}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- Botões de Cancelar e Salvar -->
    <div class="flex w-full justify-end itens-center mt-6">
        <button class="btn-cancelar" @click.prevent="$router.push('/orcamentos')">Cancelar</button>
        <button class="btn-salvar" @click.prevent="salvarVariaveis()">Salvar</button>
    </div>
</template>

<script>
import axios from 'axios'
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';

export default {
    name: 'VisualizarVariaveisOrcamento',
    props: {
        id: String,
    },
    components: {
        SucessoRapido,
        ErroRapido,
    },
    data() {
        return {
            variaveis: [],
            id_variaveis: [],
            opcoesVariaveis: [],
            selected: {},
            sucesso: false,
            erro: false,
        };
    },
    methods: {
        async getVariaveisOrcamento() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`orcamentos/variavel-local-orcamento/?id_orcamento=${this.id}&page_size=999`, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
                this.variaveis = response.data.results;
                this.id_variaveis = this.variaveis.map((variavel) => variavel.id);
                this.getOpcoesVariaveisOrcamento();
            } catch (error) {
                console.error(error);
            }
        },
        async getOpcoesVariaveisOrcamento() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`orcamentos/opcao-variavel-local-orcamento/?id_orcamento=${this.id}&page_size=999`, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
                this.opcoesVariaveis = response.data.results;
                this.setInitialSelections();
            } catch (error) {
                console.error(error);
            }
        },
        setInitialSelections() {
            this.variaveis.forEach((variavel) => {
                const opcoes = this.getOpcoesByVariavelId(variavel.id);
                const selectedOpcao = opcoes.find(
                    (opcao) => String(opcao.valor) === String(variavel.medida_valor)
                );
                if (selectedOpcao) {
                    this.selected[variavel.id] = selectedOpcao.valor;
                } else {
                    this.selected[variavel.id] = variavel.medida_valor;
                }
            });
        },
        getOpcoesByVariavelId(id_variavel) {
            return this.opcoesVariaveis.filter(
                (opcao) => opcao.variavel.id === id_variavel
            );
        },
        getDescricaoOpcaoSelecionada(id_variavel) {
            const opcoes = this.getOpcoesByVariavelId(id_variavel);
            const selectedOpcao = opcoes.find(
                (opcao) => String(opcao.valor) === String(this.selected[id_variavel])
            );
            return selectedOpcao ? selectedOpcao.descricao : null;
        },
        async salvarVariaveis() {
            // Implementar lógica de salvamento se necessário
        },
    },
    created() {
        this.getVariaveisOrcamento();
    },
};
</script>

<style scoped></style>