<template>
    <div class="container-formulario">
        <!-- Navegação entre as seções -->
        <div class="mb-4">
            <div class="container-cabecalho justify-end flex mb-4">
                <router-link :to="`/vao/${$route.params.id_obra}/${$route.params.id}`" class="text-xl text-corBase font-bold">
                    <i class="fas fa-chevron-left"></i>
                </router-link>
            </div>
            <div class="etapas-navegacao">
                <button :class="{ ativo: etapaAtual === 1 }" @click="etapaAtual = 1">Tipologia</button>
                <button :class="{ ativo: etapaAtual === 2 }" @click="etapaAtual = 2">Cores</button>
            </div>
        </div>

        <!-- Lista de Tipologias -->
        <div class="container-modelo-tabela mt-8" v-if="etapaAtual === 1">
            <h2 class="text-lg text-corBase font-bold mb-4">Lista de Tipologias</h2>
            <table class="container-tabela">
                <thead class="cabecalho-tabela">
                    <tr>
                        <th class="py-2 px-4 bg-gray-200 border-b">Ações</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Imagem</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Nome</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Descrição</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Linha</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(tipologia, index) in lista_tipologia" :key="tipologia.id">
                        <td class="py-2 px-4 gap-2">
                            <button @click="removerTipologia(index)" class="text-gray-500" title="Remover Tipologia">
                                <i class="fas fa-times"></i>
                            </button>
                            <input type="checkbox" class="my-2 mx-2 text-gray-500">
                        </td>
                        <td class="py-2 px-4 border-b">
                            <img :src="tipologia.imagem.url_documento" alt="imagem-tipologia" class="foto-vao"
                                @click="abrirModal(tipologia.imagem.url_documento)" />
                        </td>
                        <td class="py-2 px-4 border-b">{{ tipologia.nome }}</td>
                        <td class="py-2 px-4 border-b">{{ tipologia.modelo.descricao }}</td>
                        <td class="py-2 px-4 border-b z-10">
                            <!-- <Select2 v-model="tipologia.linhaSelecionada" class="w-full text-xs z-10"
                                :options="listaLinhas" :settings="{ width: '100%', dropdownParent: 'body' }" /> -->
                            <select v-model="tipologia.linhaSelecionada" class="select_tipologia w-full text-xs z-10">
                                <option v-for="linha in listaLinhas" :key="linha.id" :value="linha.id">
                                    {{ linha.text }}
                                </option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Seção Cores -->
        <div v-if="etapaAtual === 2">
            <div class="container-modelo-tabela mt-8">
                <h2 class="text-lg text-corBase font-bold mb-4">Lista de Cores</h2>
                <div class="flex gap-6">
                    <div class="flex flex-col w-full">
                        <label class="font-bold text-corBase">Cor Componentes</label>
                        <Select2 v-model="corComponentes" class="w-full text-xs z-10" :options="listaCorComponentes"
                            :settings="{ width: '100%' }" />
                    </div>

                    <div class="flex flex-col w-full">
                        <label class="font-bold text-corBase">Cor Perfis</label>
                        <Select2 v-model="corPerfis" class="w-full text-xs z-10" :options="listaCorPerfis"
                            :settings="{ width: '100%' }" />
                    </div>

                    <div class="flex flex-col w-full">
                        <label class="font-bold text-corBase">Cor Vidros</label>
                        <Select2 v-model="corVidros" class="w-full text-xs z-10" :options="listaCorVidros"
                            :settings="{ width: '100%' }" />
                    </div>
                </div>

                <!-- Exibição das cores selecionadas -->
                <div class="mt-4">
                    <p v-if="corComponentes" class="text-corBase">Cor Componentes Selecionada: {{ corComponentes.text ||
                        corComponentes }}</p>
                    <p v-if="corPerfis" class="text-corBase">Cor Perfis Selecionada: {{ corPerfis.text || corPerfis }}
                    </p>
                    <p v-if="corVidros" class="text-corBase">Cor Vidros Selecionada: {{ corVidros.text || corVidros }}
                    </p>
                </div>
            </div>
        </div>

        <!-- Botão de Adicionar -->
        <div class="mt-8">
            <button @click="adicionarInformacoes" class="btn-salvar">Adicionar</button>
        </div>

        <!-- Modal de Foto Ampliada -->
        <div v-if="fotoAmpliada" class="modal-mask z-9999" @click="fecharModal">
            <div class="modal-wrapper z-9999">
                <img :src="fotoAmpliada" class="modal-foto z-9999" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Select2 from 'vue3-select2-component';

export default {
    name: 'AdicionarTipologia',
    components: {
        Select2
    },
    data() {
        return {
            etapaAtual: 1, // Controla a navegação entre Tipologia e Cores
            tipologia: {
                nome: '',
                descricao: '',
                categoria: '',
                linhaSelecionada: '', // Adiciona o campo de linha selecionada
            },
            cor: {
                nome: ''
            },
            lista_tipologia: [],
            coresAdicionadas: [],
            paginaAtual: 1,
            fotoAmpliada: null,

            listaLinhas: [],
            listaCorComponentes: [],
            listaCorPerfis: [],
            listaCorVidros: [],

            corComponentes: '',
            corPerfis: '',
            corVidros: '',
        };
    },
    created() {
        this.getListaTipologias(1)
        this.getListaLinhas()
        this.getlistaCorComponentes()
        this.getlistaCorPerfis()
        this.getlistaCorVidros()
    },
    methods: {
        abrirModal(fotoUrl) {
            this.fotoAmpliada = fotoUrl;
        },
        fecharModal() {
            this.fotoAmpliada = null;
        },
        async getListaTipologias(pagina) {
            const token = await this.$store.dispatch("getAuthTokenCookie");
            await this.$store.dispatch("fetchURLrequest");

            try {
                const response = await axios.get(
                    `/projeto/tipologia/?&page=${pagina}`,
                    { headers: { Authorization: `Token ${token}` } }
                );
                this.lista_tipologia = response.data.results;
                this.countItens = response.data.count;
            } catch (error) {
                console.error("Erro ao buscar tipologias:", error);
            }
        },
        async getListaLinhas() {
            // Função para obter a lista de linhas e popular o Select2
            try {
                const token = await this.$store.dispatch("getAuthTokenCookie");
                await this.$store.dispatch("fetchURLrequest");

                const response = await axios.get('/produtos/linha-aplicacao/', {
                    headers: { Authorization: `Token ${token}` }
                });
                this.listaLinhas = response.data.results.map(produto => ({
                    id: produto.id,
                    text: produto.nome,
                }));
            } catch (error) {
                console.error("Erro ao buscar linhas:", error);
            }
        },
        adicionarInformacoes() {
            // Captura as informações selecionadas e exibe no console
            console.log("Tipologias Selecionadas:", this.lista_tipologia);
            console.log("Cor dos Componentes Selecionada:", this.corComponentes);
            console.log("Cor dos Perfis Selecionada:", this.corPerfis);
            console.log("Cor dos Vidros Selecionada:", this.corVidros);
        },
        removerTipologia(index) {
            this.tipologiasAdicionadas.splice(index, 1);
        },

        // Funções relacionadas à sessão de cor
        async getlistaCorComponentes() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get('produtos/cor-componente/?page_size=999', {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.listaCorComponentes = response.data.results.map(response => {
                    return {
                        id: response.id,
                        text: response.nome
                    }
                })
            } catch (error) {
                console.error(error)
            }
        },

        async getlistaCorPerfis() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get('produtos/cor-perfil/?page_size=999', {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.listaCorPerfis = response.data.results.map(response => {
                    return {
                        id: response.id,
                        text: response.nome
                    }
                })
            } catch (error) {
                console.error(error)
            }
        },

        async getlistaCorVidros() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get('produtos/cor-vidro/?page_size=999', {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.listaCorVidros = response.data.results.map(response => {
                    return {
                        id: response.id,
                        text: response.nome
                    }
                })
            } catch (error) {
                console.error(error)
            }
        },
    }
};
</script>

<style scoped>
.etapas-navegacao {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.foto-vao {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    z-index: 9998;
}

.modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-foto {
    max-width: 50%;
    max-height: 50%;
    border-radius: 10px;
    border: 3px solid white;
    z-index: 1000;
}

.etapas-navegacao button {
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    margin: 0 10px;
}

.etapas-navegacao .ativo {
    background-color: #1f2937;
    color: white;
}

.input-form-chapa {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.select_tipologia {
    border: 1px solid black;
    @apply rounded-lg p-3
}
</style>
