<template>

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Vidro</h1>
      </div>

      <router-link to="/produtos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="flex items-end gap-8 mb-6">
      <div class="w-full">
        <label class="text-xl text-corBase">Cor:</label>
        <v-select v-model="cor_vidro" class="w-full z-10" :options="lista_cor_vidro" label="text" />
      </div>
      <div class="w-full">
        <label class="text-xl text-corBase">Tipo:</label>
        <v-select v-model="tipo_vidro" class="w-full z-10" :options="lista_tipo_vidro" label="text" />
      </div>
      <div class="w-full">
        <label class="text-xl text-corBase">Espessura:</label>
        <v-select v-model="espessura" class="w-full z-10" :options="lista_espessura" label="text" />
      </div>
      <div>
        <button
          class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase text-white hover:text-corBase py-2 px-4"
          @click="getListaVidro(1)">
          Pesquisar
        </button>
      </div>
    </div>

    <div class="mb-8">
      <div>
        <button @click="$router.push(`/vidro/cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 ">Adicionar
        </button>
      </div>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              #
            </th>
            <th class="px-6 py-3">
              Nome
            </th>
            <th class="px-6 py-3">
              Tipo
            </th>
            <th scope="col" class="px-6 py-3">
              Cor
            </th>
            <th scope="col" class="px-6 py-3">
              Fornecedor
            </th>
            <th scope="col" class="px-6 py-3">
              Produto Base
            </th>
            <th scope="col" class="px-6 py-3">
              Espessura
            </th>
            <th scope="col" class="px-6 py-3">
              Status
            </th>
            <th scope="col" class="px-6 py-3">
              Editar
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(vidro, index) in lista_vidro" :key="index" class="escopo-tabela">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox" v-model="vidro.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-xs">
              {{ vidro.nome_especifico || 'Sem nome' }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ vidro.tipo_vidro.nome }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ vidro.cor_vidro.nome }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ vidro.fornecedor?.nome_razao || 'Sem fornecedor definido' }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ vidro.vidro_base.nome_especifico }}
            </td>
            <td class="px-12 py-2 text-xs ">
              {{ vidro.espessura?.espessura || 'Não definido' }}
            </td>
            <template v-if="vidro.ativo === true">
              <td class="px-6 py-2 text-xs">
                <i class="fa-solid fa-circle-check  text-ativo ml-3"></i>
              </td>
            </template>
            <template v-if="vidro.ativo === false">
              <td class="px-6 py-2">
                <i class="fa-solid fa-circle-xmark text-inativo ml-3"></i>
              </td>
            </template>

            <td class="px-6 py-2 text-xs">
              <a @click="selecionarVidroEditar(vidro.id)" href="#" class="font-medium text-corBase hover:underline">
                <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
              </a>
            </td>

          </tr>
        </tbody>
      </table>
      <Pagination v-if="lista_vidro.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
      <editar-vidro v-if="mostrarModal" :dadosVidro="vidroSelecionado" @fecharModal="toggleModal"></editar-vidro>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import EditarVidro from "@/components/midais/Editar/produtos/EditarVidro";
import vSelect from "vue-select";
import Pagination from "@/utils/Pagination.vue";

export default {
  name: "ListaVidro",

  components: {
    EditarVidro,
    vSelect,
    Pagination
  },

  data() {
    return {
      checkAll: false,
      // lista usada para salvar o retorno da requisição do get
      listaVidro: [],
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      countItens: '',

      vidroSelecionado: null,
      mostrarModal: false,

      // filtros
      lista_cor_vidro: [],
      cor_vidro: '',
      lista_tipo_vidro: [],
      tipo_vidro: '',
      lista_espessura: [],
      espessura: '',


      lista_vidro: [],

      error: []
    }
  },

  created() {
    this.getListaCorVidro()
    this.getListaTipoVidro()
    this.getListaEspessura()
  },

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },

    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  methods: {
    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    async getListaVidro(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      this.$store.dispatch("setLoading", true);

      try {
        const response = await axios.get(`produtos/vidro/?cor_vidro_id=${this.cor_vidro}&tipo_vidro_id=${this.tipo_vidro}&espessura_id=${this.espessura}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        // Armazenar os dados recebidos
        this.lista_vidro = response.data.results
        this.countItens = response.data.count
        console.log(this.lista_vidro);

      } catch (error) {
        console.error("Erro ao buscar lista de vidros:", error);

      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina;
      this.getListaVidro(pagina);
    },

    selecionarVidroEditar(vidro) {
      this.vidroSelecionado = vidro
      this.toggleModal()
    },

    toggleModal() {
      this.mostrarModal = !this.mostrarModal
    },

    // Requisição para obter a cor do vidro
    async getListaCorVidro() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get('produtos/cor-vidro/?page_size=9999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        // console.log(response.data.results);
        this.lista_cor_vidro = response.data.results.map(item => ({
          id: item.id,
          text: item.nome
        }));

      } catch (error) {
        console.error("Erro ao buscar lista de cores de vidro:", error);
      }
    },

    // Requisição para obter o tipo de vidro
    async getListaTipoVidro() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get('produtos/tipo-vidro/?page_size=9999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        // console.log(response.data.results);
        this.lista_tipo_vidro = response.data.results.map(item => ({
          id: item.id,
          text: item.nome
        }));

      } catch (error) {
        console.error("Erro ao buscar lista de tipos de vidro:", error);
      }
    },

    // Requisição para obter as espessuras
    async getListaEspessura() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get('produtos/espessura/?page_size=9999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        // console.log(response.data.results);
        this.lista_espessura = response.data.results.map(item => ({
          id: item.id,
          text: item.espessura
        }));

      } catch (error) {
        console.error("Erro ao buscar lista de espessuras:", error);
      }
    },

  }
}
</script>

<style lang="scss"></style>