<template>
  <div>
    <div class="container-modal-editar">
      <div class="sombra-modal">
        <div class="conteudo-modal-editar">
          <form class="form-editar text-white">
            <div class="flex justify-end">
              <button @click.prevent="$emit('fecharModal')">
                <i class="fas fa-close text-2xl text-corBase"></i>
              </button>
            </div>
            <h2 class="text-lg text-corBase font-bold mb-2">Editar Dados Unidade de Medida</h2>
            <!--Campos da Pessoa-->

            <div class="flex flex-row gap-4">

              <div class="flex flex-col pt-5 w-full">
                <div>
                  <div class="container-campos">
                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Codigo:</label>
                      <input type="text" required class="input-campos" v-model="unidade.codigo">
                    </div>

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Descrição:</label>
                      <input type="text" required class="input-campos" v-model="unidade.nome">
                    </div>

                  </div>

                </div>
                <!--Campos de endereço-->
                <div>

                  <div class="container-campos w-full">
                    <div class="container-campos w-full">
                      <div class="campos campos-mobile label-campos-popup">
                        <label class="label-campos label-campos-popup">Produtos Permitidos:</label>
                        <Select2 v-model="produtos_permitidos"
                                 class="w-full z-10"
                                 :options="listaProdutosPermitidos"
                                 :settings="{ width: '100%', multiple:true, value: 'id'}"
                        />
                      </div>
                    </div>
                    <div class="flex flex-col mt-2 w-full justify-start ">
                      <label class="text-base text-corBase font-bold mt-2">Ativo:</label>

                      <span @click.prevent="toggleAtivo" class="text-start pt-1 text-xl">
                        <i v-if="unidade.ativo === true" class="fa-solid fa-circle-check text-ativo"></i>
                        <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="btn-formulario-modal">
              <button class="btn-formulario-salvar" @click.prevent="putDadosEditado">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
      <SucessoRapido v-if="sucesso === true" mensagem="Unidade de Medida alterada com sucesso!" />
    </div>
  </div>
</template>

<script>
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import axios from "axios";
import Select2 from "vue3-select2-component";

export default {
  name: "EditarUnidadeMedida",

  components: {
    Select2,
    SucessoRapido
  },
  data() {
    return {
      sucesso: false,
      statusModal: false,
      lista_produtos_permitidos: [],
      produtos_permitidos: []
    }
  },
  props: ["unidade", "listaProdutosPermitidos"],
  emits: ["fecharModal"],
  created() {
    console.log(this.unidade)
  },

  mounted() {
    window.addEventListener('keydown', this.onEscKeyPressed);
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.onEscKeyPressed);
  },

  watch: {
    unidade: {
      handler(newUnidade, oldUnidade) {
        // Lógica para processar a mudança na propriedade 'unidade'
        this.produtos_permitidos = newUnidade.produtos_do_sistema.map(item => item.id);
      },
      immediate: true // Para que o watcher seja chamado imediatamente na criação do componente
    },
    statusModal(newStatus) {
      if (newStatus) {
        window.addEventListener('keydown', this.onEnterKeyPressed);
      } else {
        window.removeEventListener('keydown', this.onEnterKeyPressed);
      }
    }
  },
  methods: {

    toggleAtivo(event) {
      event.preventDefault()
      this.unidade.ativo = !this.unidade.ativo
    },

    alterarModalEditar() {
      this.statusModal = !this.statusModal;
      this.$parent.getListaUnidadeMedida()
    },

    onEscKeyPressed(event) {
      if (event.key === 'Escape') {
        this.$parent.getListaUnidadeMedida()
        this.statusModal = false
      }
    },

    onEnterKeyPressed(event) {
      if (event.key === 'Enter') {
        this.putDadosEditado()
      }
    },

    async putDadosEditado() {
      const data = {
        ativo: this.unidade.ativo,
        nome: this.unidade.nome,
        codigo: this.unidade.codigo,
        produtos_do_sistema: this.produtos_permitidos
      }
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.patch(`produtos/unidade-medida/${this.unidade.id}/`, data, {
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          }
        });
        console.log(response);
        this.sucesso = true
        setTimeout(() => {
          this.sucesso = false;
          this.$emit('fecharModal')
        }, 3000);
      } catch (error) {
        console.log(error)
      }
    },

  }
}
</script>

<style>
.container-modal-editar {
  @apply fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full z-[10000]
}

.conteudo-modal-editar {
  @apply bg-screenCor p-6 w-[75%]  shadow-xl shadow-screenCor/30 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[80vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-screenCor/40
}

.form-editar {
  @apply text-corBase
}
</style>