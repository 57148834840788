<template>
    <div>
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <h1>Variáveis do Projeto:</h1>
                        <br>
                        <div v-for="variavel in variaveis" :key="variavel.id" class="opcao-variavel">
                            <h2>{{ variavel.descricao }}:</h2>
                            <div>
                                <!-- Verifica se há opções disponíveis -->
                                <div v-if="getOpcoesByVariavelId(variavel.id).length > 0">
                                    <select v-model="selected[variavel.id]">
                                        <option disabled value="">
                                            Selecione uma opção
                                        </option>
                                        <option v-for="opcao in getOpcoesByVariavelId(variavel.id)" :key="opcao.id"
                                            :value="opcao.valor">
                                            {{ opcao.descricao }}
                                        </option>
                                    </select>
                                </div>
                                <!-- Se não houver opções, exibe um input numérico com o valor já vindo na requisição -->
                                <div v-else>
                                    <input v-model.number="selected[variavel.id]" type="number" min="0"
                                        placeholder="Insira um valor numérico" />
                                </div>
                            </div>
                        </div>
                        <!-- Botões de Cancelar e Salvar -->
                        <div class="flex w-full justify-end itens-center mt-6">
                            <button class="btn-salvar" @click.prevent="salvarVariaveis()">Salvar</button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <SucessoRapido v-if="sucesso" mensagem="Alteração feita com sucesso." />
    <ErroRapido v-if="erro" mensagem="Ocorreu um erro ao salvar as variáveis." />
</template>

<script>
import axios from 'axios'
import SucessoRapido from '../toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '../toasts/toast_erro/ErroRapido.vue';

export default {
    name: 'ModalVariaveisOrcamento',
    data() {
        return {
            variaveis: [],
            id_variaveis: [],
            opcoesVariaveis: [],
            selected: {},
            sucesso: false,
            erro: false
        };
    },
    props: {
        id: String,
        rotaVoltar: String
    },
    components: {
        SucessoRapido,
        ErroRapido
    },
    methods: {
        // Salvar as variáveis alteradas do orçamento
        async salvarVariaveis() {
            const data = {
                variaveis: this.variaveis.map(variavel => {
                    const opcaoSelecionada = this.getOpcoesByVariavelId(variavel.id).find(opcao => opcao.id === this.selected[variavel.id]);
                    return {
                        id: variavel.id,
                        opcao_selecionada: opcaoSelecionada ? opcaoSelecionada.id : null,
                        medida_valor: opcaoSelecionada ? null : this.selected[variavel.id]
                    };
                })
            };

            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')
            try {
                const response = await axios.put(`orcamentos/variavel-local-orcamento-update/`, data, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });
                console.log(response.data);
                this.sucesso = true
                setTimeout(() => {
                    this.sucesso = false;
                    if (this.rotaVoltar) {
                        this.$router.push(`${this.rotaVoltar}`)
                    } else {
                        this.$emit('fecharModalVariaveis')
                    }
                }, 3000);
            } catch (error) {
                console.error(error);
                this.erro = true
                setTimeout(() => {
                    this.erro = false;
                }, 3000);
            }
        },

        // Função para buscar as variáveis do orçamento
        async getVariaveisOrcamento() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')
            try {
                const response = await axios.get(`orcamentos/variavel-local-orcamento/?id_orcamento=${this.id}&page_size=999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });
                this.variaveis = response.data.results;
                this.id_variaveis = this.variaveis.map(variavel => variavel.id);
                this.getOpcoesVariaveisOrcamento();
            } catch (error) {
                console.error(error);
            }
        },

        // Função para buscar as opções das variáveis do orçamento
        async getOpcoesVariaveisOrcamento() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')
            try {
                const response = await axios.get(`orcamentos/opcao-variavel-local-orcamento/?id_orcamento=${this.id}&page_size=999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });
                this.opcoesVariaveis = response.data.results;
                this.setInitialSelections();
            } catch (error) {
                console.error(error);
            }
        },

        // Função para definir os valores iniciais caso exista
        setInitialSelections() {
            this.variaveis.forEach(variavel => {
                const opcoes = this.getOpcoesByVariavelId(variavel.id);
                const selectedOpcao = opcoes.find(opcao =>
                    String(opcao.valor) === String(variavel.medida_valor)
                );
                if (selectedOpcao) {
                    this.selected[variavel.id] = selectedOpcao.valor;
                } else {
                    this.selected[variavel.id] = variavel.medida_valor;
                }
            });
        },

        // Função para buscar as opções da variável do orçamento
        getOpcoesByVariavelId(id_variavel) {
            return this.opcoesVariaveis.filter(opcao => opcao.variavel.id === id_variavel);
        },
    },
    created() {
        this.getVariaveisOrcamento();
    }
};
</script>

<!-- Adiciona estilo para o modal -->
<style scoped>
.opcao-variavel {
    @apply mb-3 p-3
}

h1 {
    @apply text-xl font-bold
}

select {
    @apply w-full p-2 bg-gray-100 border-[1px] border-black rounded-lg cursor-pointer
}

input {
    @apply w-full p-2 bg-gray-100 border-[1px] border-black rounded-lg
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.modal-container {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
}

.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.5s;
}

.modal-enter,
.modal-leave-to {
    opacity: 0;
}

::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}


::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

::-webkit-scrollbar-horizontal {
    height: 8px;
}
</style>
