<template>
    <div v-if="isVisible" class="modal-backdrop">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title">Criar Função da Obra</h2>
                <button @click="fecharModal" class="modal-close">
                    <i class="fa-solid fa-x"></i>
                </button>
            </div>

            <div class="modal-body">
                <div class="form-group">
                    <input v-model="descricao" type="text" id="descricao"
                        placeholder="Digite aqui..." class="form-input"/>
                </div>
            </div>

            <div class="modal-footer">
                <button class="btn-cancelar" @click="fecharModal">Cancelar</button>
                <button class="btn-salvar" @click="salvarResponsabilidade"
                    :disabled="!descricaoPreenchida">Salvar</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModalCriacaoResponsabilidade",
    data() {
        return {
            isVisible: false,
            descricao: '',
            sucesso: false,
            erro: false,
        };
    },
    computed: {
        descricaoPreenchida() {
            return this.descricao !== '';
        }
    },
    methods: {
        abrirModal() {
            this.isVisible = true;
        },
        fecharModal() {
            this.isVisible = false;
            this.descricao = '';
        },
        salvarResponsabilidade() {
            if (this.descricaoPreenchida) {
                this.$emit('responsabilidadeCriada', this.descricao);
                this.fecharModal();
            }
        }
    }
};
</script>

<style scoped>
input.form-input {
    border: 1px solid #ccc;
    padding: 10px;
    width: 100%;
    margin-top: 8px;
    border-radius: 5px;
    font-size: 16px;
}


.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}


.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 450px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-title {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

.modal-close {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #999;
}

.modal-body {
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
</style>
