<template>
    <div>
        <h2 class="text-lg text-corBase font-bold">Produtos da Tabela</h2>

        <!-- Adicionando uma div para rolagem lateral -->
        <div class="container-modelo-tabela">
            <table class="container-tabela overflow-auto">
                <thead class="cabecalho-tabela">
                    <tr>
                        <th class="py-2 px-4 bg-gray-200 border-b">Ações</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Nome do Produto</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Valor de Custo</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">% Margem Adicional</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">% Margem Adicional Balcão</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Valor Final de Venda</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Valor Final de Venda Balcão</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- Exibir os produtos existentes -->
                    <tr v-for="produto in produtosTabela" :key="produto.id">
                        <td class="py-2 px-4 border-b flex gap-2">
                            <button @click="deleteVidro(produto.id)" class="text-gray-500" title="Excluir Vidro">
                                <i class="fas fa-times"></i>
                            </button>
                            <button @click="abrirEdicao(produto)" class="text-gray-500" title="Editar Vidro">
                                <i class="fas fa-paperclip"></i>
                            </button>
                        </td>
                        <td class="py-2 px-4 border-b">{{ produto.vidro.nome_especifico }}</td>
                        <td class="py-2 px-4 border-b">R$ {{ parseFloat(produto.vidro.preco_custo).toFixed(2) ||
                            '0.00' }}</td>
                        <td class="py-2 px-4 border-b">
                            <input v-if="produtoEditando && produtoEditando.id === produto.id"
                                v-model="produtoEditando.margem_lucro_ml" type="number" min="0" class="input-form" />
                            <span v-else>{{ parseFloat(produto.margem_lucro_ml).toFixed(2) }}%</span>
                        </td>

                        <td class="py-2 px-4 border-b">
                            <input v-if="produtoEditando && produtoEditando.id === produto.id"
                                v-model="produtoEditando.margem_lucro_balcao" type="number" min="0"
                                class="input-form" />
                            <span v-else>{{ parseFloat(produto.margem_lucro_balcao).toFixed(2) }}%</span>
                        </td>
                        <td class="py-2 px-4 border-b">
                            <span v-if="produtoEditando && produtoEditando.id === produto.id">
                                R$ {{ calcularValorFinal(produtoEditando) }}
                            </span>
                            <span v-else>
                                R$ {{ calcularValorFinalProdutoExistente(produto) }}
                            </span>
                        </td>
                        <td class="py-2 px-4 border-b">
                            <span v-if="produtoEditando && produtoEditando.id === produto.id">
                                R$ {{ calcularValorFinalBalcao(produtoEditando) }}
                            </span>
                            <span v-else>
                                R$ {{ calcularValorFinalBalcaoProdutoExistente(produto) }}
                            </span>
                        </td>
                    </tr>

                    <!-- Linha de input para adicionar novo produto -->
                    <tr v-if="adicionarProdutoAtivo">
                        <td class="py-2 px-4 border-b"></td>
                        <td class="py-2 px-4 w-full sm:w-96 border-b">
                            <v-select v-model="novoProduto.vidro" :options="listaProdutos" label="text"
                                class="w-full bg-white" :clearable="false" @search="onSearchProduto" />
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="novoProduto.custo" type="text" min="0" class="input-form" readonly />
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="novoProduto.margem_lucro_ml" type="number" min="0" class="input-form" />
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="novoProduto.margem_lucro_balcao" type="number" min="0" class="input-form" />
                        </td>
                        <td class="py-2 px-4 border-b">{{ calcularValorFinal(novoProduto) }}</td>
                        <td class="py-2 px-4 border-b">{{ calcularValorFinalBalcao(novoProduto) }}</td>
                    </tr>
                </tbody>
            </table>
            
        <!-- Botões da paginação -->
        <Pagination v-if="produtosTabela.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
            @change-page="mudarPagina" />
        </div>

        <!-- Botão de Adicionar Produto -->
        <div class="flex justify-end mt-4" v-if="adicionarProdutoAtivo">
            <button class="btn-salvar" @click.prevent="adicionarProduto" :disabled="!produtoPreenchido">
                Adicionar Produto
            </button>
        </div>

        <!-- Botão de salvar as edições no modal de edição -->
        <div class="flex justify-end mt-4" v-if="produtoEditando">
            <button class="btn-salvar" @click.prevent="salvarProdutoEditado">Salvar Alterações</button>
        </div>

        <!-- Botão para adicionar novo produto -->
        <div class="flex justify-center mt-4">
            <button class="text-ativo" @click.prevent="abrirLinhaAdicionarProduto">
                <i class="fas fa-plus-circle"></i>
            </button>
        </div>
    </div>

    <SucessoRapido v-if="sucesso === true" mensagem="Alteração feita com sucesso." />
    <ErroRapido v-if="erro === true" :mensagem="erroMessage" />
</template>

<script>
import Select2 from "vue3-select2-component";
import vSelect from "vue-select";
import Pagination from "@/utils/Pagination.vue";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ErroRapido from "@/components/toasts/toast_erro/ErroRapido.vue";
import axios from 'axios'

export default {
    name: "ProdutosTabela",
    components: {
        Select2,
        vSelect,
        Pagination,
        SucessoRapido,
        ErroRapido
    },
    props: {
        dadosVidro: Object
    },
    data() {
        return {
            listaProdutos: [],
            produtoSelecionado: {},
            produtoEditando: null,
            sucesso: false,
            erro: false,
            erroMessage: '',

            produtosTabela: [],

            listaProdutos: [],
            paginaAtual: 1,
            countItens: 0,

            adicionarProdutoAtivo: false,

            novoProduto: {
                custo: 0,
                vidro: null,
                margem_lucro_ml: 0,
                margem_lucro_balcao: 0
            },

            pesquisaProduto: '',
        };
    },
    computed: {
        produtoPreenchido() {
            return (
                this.novoProduto.vidro !== null &&
                this.novoProduto.custo > 0 &&
                this.novoProduto.margem_lucro_ml > 0 &&
                this.novoProduto.margem_lucro_balcao > 0
            );
        }
    },
    created() {
        // console.log(this.dadosVidro)
        this.buscarProdutosTabelaVidro(1)
    },
    watch: {
        'novoProduto.vidro': {
            handler(novoVidro) {
                if (novoVidro) {
                    // console.log(novoVidro)
                    const produtoSelecionado = this.listaProdutos.find(produto => produto.id === novoVidro.id);
                    // console.log(produtoSelecionado)
                    if (produtoSelecionado) {
                        this.novoProduto.custo = parseFloat(produtoSelecionado.preco).toFixed(2);
                    } else {
                        this.novoProduto.custo = "0.00";
                    }
                } else {
                    this.novoProduto.custo = "0.00";
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {

        async buscarProdutosTabelaVidro(pagina) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`precificacao/itens-tabelas-vidro/?id_tabela_preco=${this.$route.params.id}&page=${pagina}`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });
                this.produtosTabela = response.data.results;
                this.countItens = response.data.count
                console.log(this.produtosTabela)
            } catch (error) {
                console.error("Erro ao buscar produtos da tabela de vidro:", error);
            }
        },

        async onSearchProduto(query) {
            if (query.length >= 3) {
                this.pesquisaProduto = query;
                await this.buscarListaProdutos();
            }
        },

        async buscarListaProdutos() {
            if (this.pesquisaProduto.length < 3) return;
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`/produtos/produto-por-fornecedor/?tipo_produto=Vidro&nome_especifico=${this.pesquisaProduto || ''}&page_size=9999`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });
                this.listaProdutos = response.data.results.map(produto => ({
                    id: produto.id,
                    text: produto.nome_especifico,
                    preco: produto.preco_custo
                }));
            } catch (error) {
                console.error("Erro ao buscar lista de produtos:", error);
            }
        },

        buscarPrecoVidro(id) {
            const produtoSelecionado = this.listaProdutos.find(produto => produto.id === id);
            // console.log(produtoSelecionado)
            if (produtoSelecionado) {
                return parseFloat(produtoSelecionado.preco).toFixed(2);
            }
            return "0.00";
        },

        abrirModalEditarProduto(produto) {
            this.produtoSelecionado = {
                ...produto,
                vidro: produto.vidro.id,
                custo: produto.vidro.preco_custo,
            };
            this.buscarListaProdutos()
        },

        abrirEdicao(produto) {
            this.produtoEditando = { ...produto };
        },

        async salvarProdutoEditado() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                await axios.put(
                    `precificacao/itens-tabelas-vidro/${this.produtoEditando.id}/`,
                    {
                        tabela_preco_vidro: this.dadosVidro.id,
                        vidro: this.produtoEditando.vidro.id,
                        custo: this.produtoEditando.custo,
                        margem_lucro_ml: this.produtoEditando.margem_lucro_ml,
                        margem_lucro_balcao: this.produtoEditando.margem_lucro_balcao,
                        valor_final_venda_ml: this.calcularValorFinal(this.produtoEditando),
                        valor_final_venda_balcao: this.calcularValorFinalBalcao(this.produtoEditando)
                    },
                    {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    }
                );
                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                }, 3000);

                this.produtoEditando = null;
                this.buscarProdutosTabelaVidro(this.paginaAtual)
            } catch (error) {
                console.error('Erro ao salvar o produto editado:', error);
            }
        },

        async deleteVidro(id) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                // Realiza a exclusão do vidro
                await axios.patch(`precificacao/itens-tabelas-vidro/${id}/`, { ativo: 'False' }, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                this.sucesso = true
                setTimeout(() => {
                    this.sucesso = false;
                }, 3000);
                this.buscarProdutosTabelaVidro(this.paginaAtual)
            } catch (error) {
                console.error("Erro ao desativar o vidro:", error);
            }
        },

        calcularValorFinal(produto) {
            console.log(produto)
            const custo = parseFloat(produto.vidro?.preco_custo || produto.vidro?.preco) || 0;
            const margemLucroML = parseFloat(produto.margem_lucro_ml) || 0;
            const valorFinal = custo + (custo * (margemLucroML / 100));
            return valorFinal.toFixed(2);
        },

        calcularValorFinalBalcao(produto) {
            const custo = parseFloat(produto.vidro?.preco_custo || produto.vidro?.preco) || 0;
            const margemLucroBalcao = parseFloat(produto.margem_lucro_balcao) || 0;
            const valorFinalBalcao = custo + (custo * (margemLucroBalcao / 100));
            return valorFinalBalcao.toFixed(2);
        },

        calcularValorFinalProdutoExistente(produto) {
            const custo = parseFloat(produto.vidro.preco_custo) || 0;
            const margemLucroML = parseFloat(produto.margem_lucro_ml) || 0;
            const valor = custo + custo * (margemLucroML / 100);
            return valor.toFixed(2);
        },

        calcularValorFinalBalcaoProdutoExistente(produto) {
            const custo = parseFloat(produto.vidro.preco_custo) || 0;
            const margemLucroBalcao = parseFloat(produto.margem_lucro_balcao) || 0;
            const valor = custo + custo * (margemLucroBalcao / 100);
            return valor.toFixed(2);
        },

        abrirLinhaAdicionarProduto() {
            this.adicionarProdutoAtivo = true;
        },

        async adicionarProduto() {
            if (this.produtoPreenchido) {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');
                try {
                    const response = await axios.post(`precificacao/itens-tabelas-vidro/`, {
                        tabela_preco_vidro: this.dadosVidro.id,
                        vidro: this.novoProduto.vidro.id,
                        margem_lucro_ml: this.novoProduto.margem_lucro_ml,
                        margem_lucro_balcao: this.novoProduto.margem_lucro_balcao,
                        custo: this.novoProduto.custo,
                        valor_final_venda_ml: this.calcularValorFinal(this.novoProduto),
                        valor_final_venda_balcao: this.calcularValorFinalBalcao(this.novoProduto)
                    }, {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    });

                    // console.log(response.data)

                    this.sucesso = true
                    setTimeout(() => {
                        this.sucesso = false;
                    }, 3000);

                    this.novoProduto = {
                        vidro: '',
                        custo: 0,
                        margem_lucro_ml: 0,
                        margem_lucro_balcao: 0
                    }

                    this.buscarProdutosTabelaVidro(this.paginaAtual)

                    this.adicionarProdutoAtivo = false;
                } catch (error) {
                    if (error.response && error.response.data[0] === "Este item já existe para esta tabela de preço.") {
                        this.erro = true
                        this.erroMessage = 'Este item já existe para esta tabela de preço.'
                        setTimeout(() => {
                            this.erro = false;
                            this.erroMessage = ''
                        }, 3000);
                    } else {
                        console.error("Erro ao adicionar produto:", error);
                        this.erro = true
                        this.erroMessage = 'Ocorreu um erro ao salvar este Produto na tabela.'
                        setTimeout(() => {
                            this.erro = false;
                            this.erroMessage = ''
                        }, 3000);
                    }
                }
            }
        },

        mudarPagina(pagina) {
            this.paginaAtual = pagina
            this.buscarProdutosTabelaVidro(pagina)
        },


    },
};
</script>

<style scoped>
.input-form {
    border: 1px solid #ccc;
    padding: 8px;
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 8px;
    text-align: left;
}

thead th {
    background-color: #f8f9fa;
}

tr:hover {
    background-color: #f1f1f1;
}
</style>