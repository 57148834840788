<template>
  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Chapas</h1>
      </div>

      <router-link to="/produtos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="flex gap-8 items-end mb-6 z-10">
      <div class="w-full">
        <label class="text-corBase">Cor:</label>
        <v-select v-model="cor_chapa" class="w-full z-10" :options="lista_cor_chapa" label="text" />
      </div>
      <div class="w-full">
        <label class="text-corBase">Tipo:</label>
        <v-select v-model="tipo_chapa" class="w-full z-10" :options="lista_tipo_chapa" label="text" />
      </div>
      <div class="w-full">
        <label class="text-corBase">Grupo Aplicação:</label>
        <v-select v-model="grupo_aplicacao_chapa" class="w-full z-10" :options="lista_grupo_aplicacao_chapa"
          label="text" />
      </div>
      <div>
        <button
          class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase text-white hover:text-corBase py-2 px-4"
          @click="getListaChapas(1)">
          Pesquisar
        </button>
      </div>
      <div>
        <button @click="$router.push(`/chapa/cadastro`)"
          class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo text-white hover:text-ativo py-2 px-4">
          Adicionar
        </button>
      </div>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              #
            </th>
            <th class="px-6 py-3">
              Nome
            </th>
            <th class="px-6 py-3">
              Tipo
            </th>
            <th scope="col" class="px-6 py-3">
              Altura
            </th>
            <th scope="col" class="px-6 py-3">
              Largura
            </th>
            <th scope="col" class="px-6 py-3">
              Produto Base
            </th>
            <th scope="col" class="px-6 py-3">
              Grupo de Aplicacao
            </th>
            <th scope="col" class="px-6 py-3">
              Status
            </th>
            <th scope="col" class="px-6 py-3">
              Editar
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(chapa, index) in lista_chapas" :key="index" class="escopo-tabela">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox" v-model="chapa.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-xs">
              {{ chapa.nome_especifico || 'Sem nome específico' }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ chapa.tipo_chapas.nome }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ chapa.altura_chapa }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ chapa.largura_chapa }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ chapa.chapa_base.nome_especifico }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ chapa.grupo_aplicacao_chapa.nome }}
            </td>
            <template v-if="chapa.ativo === true">
              <td class="px-6 py-2 text-xs">
                <i class="fa-solid fa-circle-check  text-ativo ml-3"></i>
              </td>
            </template>
            <template v-if="chapa.ativo === false">
              <td class="px-6 py-2">
                <i class="fa-solid fa-circle-xmark text-inativo ml-3"></i>
              </td>
            </template>

            <td class="px-6 py-2 text-xs">
              <a @click="selecionarChapaEditar(chapa.id)" href="#" class="font-medium text-corBase hover:underline">
                <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
              </a>
            </td>

          </tr>
        </tbody>
      </table>
      <Pagination v-if="lista_chapas.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
      <editar-chapas v-if="mostrarModal" :dadosChapa="chapaSelecionada" @fecharModal="toggleModal()"></editar-chapas>
    </div>
  </div>
  <button @click.prevent="gerar_relatorio">gerar relatorio</button>


</template>

<script>
import axios from "axios";
import EditarChapas from "@/components/midais/Editar/produtos/EditarChapas";
import vSelect from "vue-select";
import Pagination from "@/utils/Pagination.vue";

export default {
  name: "ListaChapas",

  components: {
    EditarChapas,
    vSelect,
    Pagination
  },

  data() {
    return {
      checkAll: false,
      // lista usada para salvar o retorno da requisição do get
      listaPessoas: [],

      // filtros
      cor: '',
      tipo: '',
      produto_base: '',
      grupo_aplicacao: '',


      lista_chapas: [],
      lista_cor_chapa: [],
      cor_chapa: '',
      lista_tipo_chapa: [],
      tipo_chapa: '',
      lista_grupo_aplicacao_chapa: [],
      grupo_aplicacao_chapa: '',
      paginaAtual: 1,
      countItens: '',

      chapaSelecionada: null,
      mostrarModal: false,

      error: []
    }
  },

  created() {
    this.getTipoChapas()
    this.getGrupoAplicacaoChapa()
    this.getCorChapas()
  },

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },

    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },


  },

  methods: {
    async gerar_relatorio() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      const data = {
        model_name: "ModeloVariacao"
      }

      axios.post('relatorios/gerar-relatorio/', data, {
        headers: {
          'Authorization': `Token ${token}`,
        }
      }).then(response => {
        console.log(response.data)
      }).catch(error => {
        console.log(error)
      })
    },

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    async getListaChapas(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');


      try {
        const response = await axios.get(`produtos/chapa?cor_chapas_id=${this.cor}&tipo_chapas_id=${this.tipo}&chapa_base_id=${this.produto_base}&grupo_aplicacao_chapa_id=${this.grupo_aplicacao}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.lista_chapas = response.data.results;
        this.countItens = response.data.count
        console.log(this.lista_chapas);

      } catch (error) {
        console.error("Erro ao buscar chapas:", error);
      }
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina;
      this.getListaChapas(pagina);
    },

    selecionarChapaEditar(chapa){
      this.chapaSelecionada = chapa
      this.toggleModal()
    },

    toggleModal(){
      this.mostrarModal = !this.mostrarModal
    },

    async getCorChapas() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');
      try {
        const response = await axios.get('produtos/cor-chapa', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        // Se a lista estiver dentro de response.data.results
        this.lista_cor_chapa = response.data.results.map(item => ({
          id: item.id,
          text: item.nome
        }));
        console.log(this.lista_cor_chapa);
      } catch (error) {
        console.log(error);
      }
    },

    async getTipoChapas() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');
      try {
        const response = await axios.get('produtos/tipo-chapa', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        // Se a lista estiver dentro de response.data.results
        this.lista_tipo_chapa = response.data.results.map(item => ({
          id: item.id,
          text: item.nome
        }));
        console.log(this.lista_tipo_chapa);
      } catch (error) {
        console.log(error);
      }
    },

    async getGrupoAplicacaoChapa() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');
      try {
        const response = await axios.get('produtos/grupo-aplicacao-chapa', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        // Se a lista estiver dentro de response.data.results
        this.lista_grupo_aplicacao_chapa = response.data.results.map(item => ({
          id: item.id,
          text: item.nome
        }));
        console.log(this.lista_grupo_aplicacao_chapa);
      } catch (error) {
        console.log(error);
      }
    },

  }
}
</script>

<style lang="scss"></style>