<template>

  <!-- formulario para mais de 768 pixels -->
  <section>
      <!-- Escopo do formulario de cadastro mobile-->
      <form @submit.prevent="alterarDadosProjeto" enctype="multipart/form-data">
          <div class="w-full mx-auto mt-8">
            <div class="space-y-4">
              <!-- Seção 1 -->
              <div>
                <button @click.prevent="toggleSection(1)"
                        class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                  Dados do Produto
                </button>
                <div v-if="activeSection === 1"
                     class="p-4 flex flex-col gap-5 border border-corBase/60 tansition duration-500">
                  <!-- Campos de cadastro para a primeira seção -->
                  <div class="flex gap-10">
                    <div class="flex flex-col w-full">
                      <label class="font-bold text-corBase">Sistemista</label>
                      <v-select 
                        v-model="sistemista"
                        :options="lista_sistemistas"
                        label="text"
                        :reduce="(option) => option.id"
                      >
                      </v-select>
                    </div>

                    <div class="flex flex-col w-full">
                      <label class="font-bold text-corBase">Linha/Sistema</label>
                      <v-select 
                        v-model="linha"
                        :options="lista_linhas"
                        label="text"
                        :reduce="(option) => option.id"
                        class="input-disabled"
                        :disabled="!sistemista"
                      >
                      </v-select>
                    </div>
      
                    <div class="flex flex-col w-full">
                      <label class="font-bold text-corBase">Modelo</label>
                      <v-select 
                        v-model="tipologia"
                        :options="lista_tipologias"
                        label="text"
                        :reduce="(option) => option.id"
                      >
                      </v-select>
                    </div>
  
                  </div>

                  <div class="flex gap-12 w-full">
                    <div>
                      <label class="font-bold text-corBase">Cod. Produto</label>
                      <input type="text" class="input-campos" v-model="cod_projeto" required>
                    </div>
                    <div class="w-full">
                      <label class="font-bold text-corBase">Descrição</label>
                      <input type="text" class="input-campos" v-model="descricao_fixa" required>
                    </div>
                  </div>

                  <div class="flex w-full">
                    <div class="w-full">
                      <label class="font-bold text-corBase">Descrição Para Orçamentos e Relatórios</label>
                      <input type="text" class="input-campos" v-model="descricao_variavel">
                    </div>

                  </div>
                </div>
              </div>

              <!-- Seção 2 -->
              <div>
                <button @click.prevent="toggleSection(2)"
                        class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                  Configurações
                </button>
                <div v-if="activeSection === 2" class="p-4 border border-corBase/60 flex flex-col gap-8">
                  <!-- Campos de cadastro para a segunda seção -->
                  <div class="flex gap-12 w-full">
                    <div>
                      <label class="font-bold text-corBase">Unidade</label>
                      <input type="text" class="input-campos" v-model="unidade" required>
                    </div>
                    <div>
                      <label class="font-bold text-corBase">NCM</label>
                      <input type="text" class="input-campos" v-model="ncm" required>
                    </div>
                    <div class="w-80">
                      <label class="font-bold text-corBase">STATUS</label>
                      <Select2 v-model="status_projeto.id"
                              class="w-full z-10"
                              :options="lista_status"
                              :settings="{ width: '100%' }"
                      />
                    </div>
                  </div>

                <div class="flex gap-12 w-full">
                  <div>
                    <label class="text-corBase">Grupo Cor de Vidros/Chapas</label>
                    <Select2 v-model="grupo_cor_vidros_chapas"
                            class="w-96 z-10"
                            :options="lista_grupo_cor_vidros_chapas"
                            :settings="{ width: '100%' }"
                    />
                  </div>
                  <div>
                    <label class="text-corBase">Grupo Cor de Perfis</label>
                    <Select2 v-model="grupo_cor_perfis"
                            class="w-96 z-10"
                            :options="lista_grupo_cor_perfis"
                            :settings="{ width: '100%' }"
                    />
                  </div>
                  <div>
                    <label class="text-corBase">Grupo Cor de Componentes</label>
                    <Select2 v-model="grupo_cor_componentes"
                            class="w-96 z-10"
                            :options="lista_grupo_cor_componentes"
                            :settings="{ width: '100%' }"
                    />
                  </div>
                </div>

                  <div class="flex gap-12 w-full">
                    <div>
                      <label class="text-corBase">CFOP SAIDA</label>
                      <input type="text" class="input-campos" v-model="cfop_saida" required>
                    </div>
                    <div>
                      <label class="text-corBase">CFOP FORA UF</label>
                      <input type="text" class="input-campos" v-model="cfop_fora_uf" required>
                    </div>

                  </div>

                </div>
              </div>

              <!-- Seção 3 -->
              <div>
                <button @click.prevent="toggleSection(3)"
                        class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                  Esquadria
                </button>
                <div v-if="activeSection === 3" class="p-4 border border-corBase/60 flex flex-col gap-8">
                  <!-- Campos de cadastro para a terceira seção -->
                  <div class="flex items-end gap-6 w-full">
                    <div>
                      <label class="text-corBase">TEMPO PRODUÇÃO</label>
                      <input type="text" class="input-campos text-xs" v-model="temp_producao">
                    </div>
                    <div>
                      <label class="text-corBase">TEMPO INSTALAÇÃO</label>
                      <input type="text" class="input-campos text-xs" v-model="temp_instalacao">
                    </div>
                  </div>

                  <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase text-xs">Perfis</label>
                    <Select2 v-model="perfis"
                             class="w-full text-xs"
                             :options="lista_perfis"
                             :settings="{ width: '100%', multiple: true }"
                    />
                  </div>

                  <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase text-xs">Vidros</label>
                    <Select2 v-model="vidros"
                             class="w-full text-xs"
                             :options="lista_vidros"
                             :settings="{ width: '100%', multiple: true }"
                    />
                  </div>

                  <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase text-xs">Componentes</label>
                    <Select2 v-model="componentes"
                             class="w-full text-xs"
                             :options="lista_componentes"
                             :settings="{ width: '100%', multiple: true }"
                    />
                  </div>

                  <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase text-xs">Serviços</label>
                    <Select2 v-model="servicos"
                             class="w-full text-xs"
                             :options="lista_servicos"
                             :settings="{ width: '100%', multiple: true }"
                    />
                  </div>

                </div>
              </div>

              <!-- Seção 4 -->
              <div>
                <button @click.prevent="toggleSection(4)"
                        class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                  Normatização
                </button>
                <div v-if="activeSection === 4" class="p-4 border border-corBase/60 flex flex-col gap-8">
                  <!-- Campos de cadastro para a quarta seção -->
                  <div class="flex gap-12 w-full">
                    <div>
                      <label class="font-bold text-corBase">ALTURA MINIMA</label>
                      <input type="text" class="input-campos" v-model="altura_minima" required>
                    </div>
                    <div>
                      <label class="font-bold text-corBase">ALTURA MAXIMA</label>
                      <input type="text" class="input-campos" v-model="altura_maxima" required>
                    </div>
                    <div>
                      <label class="font-bold text-corBase">LARGURA MINIMA</label>
                      <input type="text" class="input-campos" v-model="largura_minima" required>
                    </div>
                    <div>
                      <label class="font-bold text-corBase">LARGURA MAXIMA</label>
                      <input type="text" class="input-campos" v-model="largura_maxima" required>
                    </div>
                  </div>

                  <div class="flex gap-12 w-full">
                    <div class="w-full">
                      <label class="text-corBase">LISTA DE ESPESSURAS PERMITIDAS EX.: 4,6,8,10 (NÃO EDITAR POR ENQUANTO)</label>
                      <Select2 v-model="espessuras"
                               class="w-full"
                               :options="lista_espessuras"
                               :settings="{ width: '100%', multiple: true }"
                      />
                    </div>
                  </div>

                </div>
              </div>

              <!-- Botao para salvar -->
            </div>
          </div>
          <div class="flex w-full gap-8 justify-end">
            <router-link to="/lista-projetos" class="btn-enviar !bg-inativo">Cancelar</router-link>
            <button class="btn-enviar" type="submit">Salvar</button>
          </div>
        </form>
  </section>

  <SucessoRapido v-if="sucesso" mensagem="Dados do projeto atualizados com sucesso"/>
</template>


<script>
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import axios from "axios";
import Select2 from "vue3-select2-component";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import vSelect from "vue-select";

export default {
  name: 'ProjetoDadosComponent',

  components: {
    MensagemSucesso,
    Select2,
    SucessoRapido,
    vSelect
  },

  data() {
    return {
      projeto_id: '',
      projeto: {},
      // variaveis dos formularios
      sistemista: '',
      tipologia: '',
      linha: '',
      cod_projeto: '',
      descricao_fixa: '',
      descricao_variavel: '',
      unidade: '',
      ncm: '',
      lib_venda: '',
      grupo_cor_vidros_chapas: '',
      grupo_cor_perfis: '',
      grupo_cor_componentes: '',
      kit: '',
      kit_cor_vidro: '',
      kit_cor_perfil: '',
      kit_cor_acessorio: '',
      cfop_saida: '',
      cfop_fora_uf: '',
      temp_producao: '',
      temp_instalacao: '',
      status_projeto: '',
      perfis: [],
      vidros: [],
      servicos: [],
      componentes: [],
      altura_minima: '',
      altura_maxima: '',
      largura_minima: '',
      largura_maxima: '',
      espessuras: [],

      // viaveis para lista
      lista_sistemistas: [],
      lista_tipologias: [],
      lista_status: [],
      lista_perfis: [],
      lista_vidros: [],
      lista_servicos: [],
      lista_componentes: [],
      lista_espessuras: [],
      lista_linhas: [],
      lista_grupo_cor_vidros_chapas: [],
      lista_grupo_cor_componentes: [],
      lista_grupo_cor_perfis: [],

      // variaveis para manipulaçao de elementos
      activeSection: 1,
      continuar: false,
      sucesso: false

    }
  },

  props: ['dadosProjeto'],

  created() {
    this.defineVariaveisProjeto()
    this.getListaSistemistas()
    this.getListaTipologia()
    this.getListaStatus()
    this.getListaPerfis()
    this.getListaVidros()
    this.getListaComponentes()
    this.getListaServicos()
    this.getListaEspessuras()
  },

  computed: {
    projeto_id() {
      return this.$route.params.id
    },
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  watch: {
    sucesso(){
      if(this.sucesso) {
        setTimeout(() => {
          this.sucesso = false
        }, 2000)
      }
    },

    sistemista(newValue){
      if(newValue){
        this.getListaLinhas(newValue)
      }

      if(!newValue) {
        this.linha = ''
        this.lista_linhas = []
      }
    }
  },

  methods: {

    continuar_cadastro() {
      this.continuar = !this.continuar
    },

    toggleSection(sectionNumber) {
      // Alterna a exibição da seção com base no número do acordeão clicado
      this.activeSection = this.activeSection === sectionNumber ? null : sectionNumber;
    },

    defineVariaveisProjeto() {
      this.cod_projeto = this.dadosProjeto.codigo
      this.descricao_fixa = this.dadosProjeto.descricao_fixa
      this.descricao_variavel = this.dadosProjeto.descricao_variavel
      this.sistemista = {
        id: this.dadosProjeto.sistemista.id,
        text: this.dadosProjeto.sistemista.nome
      }
      this.tipologia = {
        id: this.dadosProjeto.tipologia.id,
        text: this.dadosProjeto.tipologia.nome
      }
      this.linha = {
        id: this.dadosProjeto.linha_sistema.id,
        text: this.dadosProjeto.linha_sistema.linha
      }
      this.unidade = this.dadosProjeto.unidade
      this.ncm = this.dadosProjeto.ncm
      this.status_projeto = {
        id: this.dadosProjeto.status_projeto.id,
        text: this.dadosProjeto.status_projeto.descricao
      }
      // this.grupo_cor_vidros_chapas = this.dadosProjeto.
      this.grupo_cor_perfis = this.dadosProjeto.grupo_cor_perfis
      this.grupo_cor_componentes = this.dadosProjeto.grupo_cor_componentes
      this.cfop_saida = this.dadosProjeto.cfop_saida
      this.cfop_fora_uf = this.dadosProjeto.cfop_fora_uf
      this.temp_producao = this.dadosProjeto.producao
      this.temp_instalacao = this.dadosProjeto.instalacao
      this.altura_maxima = this.dadosProjeto.altura_maxima
      this.altura_minima = this.dadosProjeto.altura_minima
      this.largura_maxima = this.dadosProjeto.largura_maxima
      this.largura_minima = this.dadosProjeto.largura_minima
      this.espessuras = this.dadosProjeto.espessuras_permitidas.map(espessura => {
        return { id: espessura.id, text: espessura.espessura }
      })
    },

    async alterarDadosProjeto() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')
        
        console.log(this.sistemista)

        const updatedData = {
          codigo: this.cod_projeto,
          descricao_fixa: this.descricao_fixa,
          descricao_variavel: this.descricao_variavel,
          sistemista: this.sistemista,
          ncm: this.ncm,
          producao: this.temp_producao,
          instalacao: this.temp_instalacao,
          tipologia: this.tipologia.id,
          status_projeto: this.status_projeto.id,
          linha_sistema: this.linha.id,
          unidade: this.unidade,
          cfop_saida: this.cfop_saida,
          cfop_fora_uf: this.cfop_fora_uf,
          altura_minima: this.altura_minima,
          altura_maxima: this.altura_maxima,
          largura_minima: this.largura_minima,
          largura_maxima: this.largura_maxima,
          espessuras_permitidas: this.espessuras.map(espessura => (espessura.id))
        }

        await axios.put(`projeto/projeto/${this.dadosProjeto.id}/`, updatedData, {
          headers: {
            'Authorization': `Token ${token}`
          },
        })
        this.sucesso = true
      }catch(error){
        console.error(error)
      }
    },

    async getProjeto() {

      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/projeto/${this.projeto_id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
      .then(response => {
        this.projeto = response.data.results
        this.tipologia = response.data.results.tipologia
        this.cod_projeto = response.data.results.codigo
        this.descricao_fixa = response.data.results.descricao_fixa
        this.descricao_variavel = response.data.results.descricao_variavel
        this.unidade = response.data.results.unidade
        this.ncm = response.data.results.ncm
        this.lib_venda = response.data.results.lib_venda
        this.cfop_saida = response.data.results.cfop_saida
        this.cfop_fora_uf = response.data.results.cfop_fora_uf
        this.temp_producao = response.data.results.producao
        this.temp_instalacao = response.data.results.instalacao
        this.status_projeto = response.data.results.status_projeto
        this.perfis = response.data.results.perfil
        this.vidros = response.data.results.vidro
        this.servicos = response.data.results.servico
        this.componentes = response.data.results.componente
        this.altura_maxima = response.data.results.altura_maxima
        this.altura_minima = response.data.results.altura_minima
        this.largura_maxima = response.data.results.largura_maxima
        this.largura_minima = response.data.results.largura_minima
        this.espessuras = response.data.results.espessuras_permitidas

      })
      .catch(error => {
        console.log(error)
      })

    },


    // get das listas usadas no projeto

    async getListaSistemistas() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/projetista-sistemista/?page_size=999', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_sistemistas = response.data.results.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })

    },
    async getListaTipologia() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/tipologia', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_tipologias = response.data.results.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })

    },

    async getListaLinhas(sistemistaId){
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/linha-sistema/?projetista_sistemista_id=${sistemistaId}&page_size=999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
      .then(response => {
            this.lista_linhas = response.data.results.map(item => ({
              id: item.id,
              text: item.linha
            }));
          })
          .catch(error => {
            console.log(error)
          })
    },


    async getListaStatus() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/status-projeto', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_status = response.data.results.map(item => ({
              id: item.id,
              text: item.descricao
            }));
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getListaPerfis() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/perfil', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_perfis = response.data.results.map(item => ({
              id: item.id,
              text: item.nome_especifico
            }));
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getListaVidros() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/vidro', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_vidros = response.data.results.map(item => ({
              id: item.id,
              text: item.nome_especifico
            }));
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getListaComponentes() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/componente-acessorio', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_componentes = response.data.results.map(item => ({
              id: item.id,
              text: item.nome_especifico
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getListaServicos() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/servico', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_servicos = response.data.results.map(item => ({
              id: item.id,
              text: item.nome_especifico
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getListaEspessuras() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/espessura', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_espessuras = response.data.results.map(item => ({
              id: item.id,
              text: item.espessura
            }));
          })
          .catch(error => {
            console.log(error)
          })
    },
  }

}

</script>


<style lang="scss">
.input-disabled {
  position: relative;
  transition: opacity 0.3s ease, cursor 0.3s ease;

  &:disabled {
    opacity: 0.6;  // Deixa o botão opaco
    cursor: not-allowed;  // Muda o cursor para bloqueado

    // Estilização para a mensagem flutuante
    &::after {
      content: 'Selecione um sistemista'; // Mensagem flutuante
      position: absolute;
      top: -35px;  // Posição acima do botão
      left: -10%;
      transform: translateX(-50%);
      background-color: rgba(0, 0, 0, 0.75);  // Fundo da mensagem
      color: white;  // Cor da mensagem
      padding: 5px 10px;  // Espaçamento interno
      border-radius: 4px;  // Bordas arredondadas
      font-size: 12px;  // Tamanho da fonte
      white-space: nowrap;
      opacity: 0;  // Inicialmente invisível
      transition: opacity 0.3s ease;
      pointer-events: none;  // Previne que a mensagem receba cliques
    }

    &:hover::after {
      opacity: 1;  // Torna a mensagem visível ao passar o mouse
    }
  }
}

/* Estilo para a caixa de pesquisa */
.select2-container .select2-selection--single {
  box-sizing: border-box;
  appearance: none;
  cursor: pointer;
  display: block;
  height: 43px;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container--default .select2-search__field {
  border: 0px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 10px;
  right: 3px;
  color: rgba(22, 78, 99, 1);
  width: 20px;
}

/* Estilo para a lista de opções */
.select2-container--default .select2-results__option {
  padding: 5px;
  cursor: pointer;
}

/* Estilo para a opção selecionada */
.select2-container--default .select2-selection--single {
  border-top: 2px solid rgba(22, 78, 99, 0.1);
  border-left: 2px solid rgba(22, 78, 99, 0.1);
  border-right: 2px solid rgba(22, 78, 99, 0.1);
  border-bottom: 2px solid rgba(22, 78, 99, 0.2);
  border-radius: 0px;
  padding: 5px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

/* Estilo quando o elemento está em foco */
.select2-container--default .select2-selection--single:focus {
  border-bottom: 2px solid rgba(22, 78, 99, 0.8);
}

/* Estilo select multiple */
.select2-container--default .select2-selection--multiple {
  background-color: white;
  border-top: 2px solid rgba(22, 78, 99, 0.1);
  border-left: 2px solid rgba(22, 78, 99, 0.1);
  border-right: 2px solid rgba(22, 78, 99, 0.1);
  border-bottom: 2px solid rgba(22, 78, 99, 0.2);
  border-radius: 0px !important;
  cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  appearance: none;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}
</style>