<template>


  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Espessuras</h1>
      </div>

      <router-link to="/produtos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <div class="flex items-center gap-3 mb-10">

      <div class="flex gap-8">
        <div>
          <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="getListaEspessuras(1)">Pesquisar
          </button>
        </div>
      </div>
      <div v-if="isadmin === true">
        <button @click="$router.push(`/espessura-cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 ">Adicionar
        </button>
      </div>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              #
            </th>
            <th class="px-6 text-start ">
              Espessura
            </th>
            <th scope="col" class="px-6 py-3 text-center ">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(espessura, index) in lista_espessura" :key="index" class="escopo-tabela">

            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                  v-model="espessura.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-base">
              {{ espessura.espessura + 'mm' }}
            </td>

            <template v-if="espessura.ativo === true">
              <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
                <i class="fa-solid fa-circle-check  text-ativo"></i>
              </td>
            </template>
            <template v-if="espessura.ativo === false">
              <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
                <i class="fa-solid fa-circle-xmark text-inativo"></i>
              </td>
            </template>

          </tr>
        </tbody>
      </table>

      <Pagination v-if="lista_espessura.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
    </div>
  </div>

</template>

<script>
import axios from "axios";
import Pagination from "@/utils/Pagination.vue";

export default {
  name: "ListaEspessura",

  components: {
    Pagination
  },

  data() {
    return {
      lista_espessura: [],

      checkAll: false,
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      countItens: '',

      error: [],
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },


  },

  created() {

  },

  methods: {

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },


    async getListaEspessuras(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      this.$store.dispatch("setLoading", true)

      try {
        const response = await axios.get(`/produtos/espessura/?page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.lista_espessura = response.data.results;
        this.countItens = response.data.count;
        console.log(this.lista_espessura);

      } catch (error) {
        console.error("Erro ao buscar espessuras:", error);

      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },


    mudarPagina(pagina) {
      this.paginaAtual = pagina;
      this.getListaEspessuras(pagina);
    },

    // exibe apenas 10 items da tabela e salva o resto
    paginarTabela() {
      const startIndex = (this.paginaAtual - 1) * this.itemsPorPagina;
      const endIndex = startIndex + this.itemsPorPagina;
      this.listaEspessuraPaginada = this.lista_espessura.slice(startIndex, endIndex);
    },


  }
}
</script>

<style lang="scss"></style>