<template>
    <div class="container-formulario">
        <!-- Cabeçalho da lista de tipologias -->
        <div class="container-cabecalho flex justify-between mb-4">
            <div>
                <h1 class="text-xl text-corBase font-bold">Tipologias do Vão</h1>
            </div>

            <router-link :to="`/obra/${$route.params.id_obra}`" class="text-xl text-corBase font-bold">
                <i class="fas fa-chevron-left"></i>
            </router-link>
        </div>

        <!-- Filtros e Botões -->
        <div class="flex gap-4 mb-6 items-end w-full">
            <div class="w-full">
                <input type="text" class="input-form-chapa" v-model="nome_linha" placeholder="Nome" />
            </div>
            <div class="w-full">
                <input type="text" class="input-form-chapa" v-model="modelo" placeholder="Categoria" />
            </div>
            <div>
                <button class="btn-add-fisica" @click="getListaTipologias(paginaAtual)">Pesquisar</button>
            </div>
            <button class="btn-add-fisica whitespace-nowrap" @click="$router.push(`/adicionar-tipologia/${this.$route.params.id_obra}/${this.$route.params.id}`)">Adicionar Tipologia</button>
        </div>

        <!-- Tabela de Tipologias -->
        <div class="container-modelo-tabela">
            <table class="container-tabela">
                <thead class="cabecalho-tabela">
                    <tr>
                        <th class="py-2 px-4 bg-gray-200 border-b">#</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Imagem</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Nome</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Categoria</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Linha</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(tipologia, index) in lista_tipologia" :key="index" class="escopo-tabela">
                        <td class="py-2 px-4 border-b  gap-2">{{ index + 1 }}</td>
                        <td class="py-2 px-4 border-b  gap-2">
                            <img src="./img/vao2.png" alt="Foto do Vão" class="foto-vao"
                                @click="abrirModal('./img/vao2.png')" />
                        </td>
                        <td class="py-2 px-4 border-b gap-2">{{ tipologia.nome }}</td>
                        <td class="py-2 px-4 border-b gap-2">{{ tipologia.modelo.descricao }}</td>
                        <td class="py-2 px-4 border-b gap-2">
                            Linha Chroma
                        </td>
                    </tr>
                </tbody>
            </table>
            <Pagination v-if="lista_tipologia.length > 0" :offset="paginaAtual" :total="countItens" :limit="5"
                @change-page="mudarPagina" />
        </div>

        <!-- Modal de Foto Ampliada -->
        <div v-if="fotoAmpliada" class="modal-mask" @click="fecharModal">
            <div class="modal-wrapper">
                <img src="./img/vao2.png" class="modal-foto" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Pagination from "@/utils/Pagination.vue";

export default {
    name: "TipologiaVao",
    components: { Pagination },
    data() {
        return {
            lista_tipologia: [],
            paginaAtual: 1,
            countItens: 0,
            nome_linha: '',
            modelo: '',
            fotoAmpliada: null,
        };
    },
    
    methods: {
        abrirModal(foto) {
            this.fotoAmpliada = foto;
        },
        fecharModal() {
            this.fotoAmpliada = null;
        },
        async getListaTipologias(pagina) {
            const token = await this.$store.dispatch("getAuthTokenCookie");
            await this.$store.dispatch("fetchURLrequest");

            try {
                const response = await axios.get(
                    `/projeto/tipologia/?linhasistema=${this.nome_linha}&modelo=${this.modelo}&page=${pagina}`,
                    { headers: { Authorization: `Token ${token}` } }
                );
                this.lista_tipologia = response.data.results;
                this.countItens = response.data.count;
            } catch (error) {
                console.error("Erro ao buscar tipologias:", error);
            }
        },
        mudarPagina(pagina) {
            this.paginaAtual = pagina;
            this.getListaTipologias(pagina);
        },
    },
    created() {
        this.getListaTipologias(1);
    },
};
</script>

<style scoped>
.foto-vao {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
}

.modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-foto {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
}
</style>