<template>


  <preview-image v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />
  <div v-if="importacao != true" class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Perfil</h1>
      </div>

      <router-link to="/produtos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <div class="flex items-end mb-6 gap-8">
      <div class="w-full">
        <label class="text-corBase">Cor:</label>
        <v-select v-model="cor_perfil" :options="lista_cor_perfil" :reduce="option => option.id" label="text"
          class="w-full z-10" />
      </div>
      <div class="w-full">
        <label class="text-corBase">Tipo:</label>
        <v-select v-model="tipo_perfil" :options="lista_tipo_perfil" :reduce="option => option.id" label="text"
          class="w-full z-10" />
      </div>
      <div class="w-full">
        <label class="text-corBase">Linha:</label>
        <v-select v-model="linha_perfil" :options="lista_linha_perfil" :reduce="option => option.id" label="text"
          class="w-full z-10" />
      </div>
      <div>
        <button
          class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase text-white hover:text-corBase py-2 px-4"
          @click="getListaPerfil(1)">Pesquisar
        </button>
      </div>
      <div>
        <button @click="$router.push(`/perfil/cadastro`)"
          class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo text-white hover:text-ativo py-2 px-4">Adicionar
        </button>
      </div>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              #
            </th>
            <th class="px-6 py-3">
              Nome
            </th>
            <th class="px-6 py-3">
              Imagem
            </th>
            <th class="px-6 py-3">
              Linha
            </th>
            <th scope="col" class="px-6 py-3">
              Fornecedor
            </th>
            <th scope="col" class="px-6 py-3">
              Produto Base
            </th>
            <th scope="col" class="px-6 py-3">
              Preço Custo
            </th>
            <th scope="col" class="px-6 py-3">
              Status
            </th>
            <th scope="col" class="px-6 py-3">
              Editar
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(perfil, index) in lista_perfil" :key="index" class="escopo-tabela">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox" v-model="perfil.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-xs">
              {{ perfil.nome_perfil_especifico }}
            </td>
            <td class="px-6 py-2 text-xs">
              <button @click.prevent="openPreviewImage(perfil.imagem.url_documento)">
                <img v-if="perfil.imagem" :src="perfil.imagem.url_documento" :alt="perfil.imagem.descricao"
                  class="w-10 hover:scale-500" />
                <p v-else>Sem imagem</p>
              </button>
            </td>
            <td class="px-6 py-2 text-xs">
              {{ perfil.linha_aplicacao.nome }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ perfil.perfil_base_fornecedor.fornecedor.pessoa.nome_razao }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ perfil.perfil_base_fornecedor.nome_especifico }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ perfil.perfil_base_fornecedor.preco_custo }}
            </td>
            <template v-if="perfil.ativo === true">
              <td class="px-6 py-2 text-xs">
                <i class="fa-solid fa-circle-check  text-ativo ml-3"></i>
              </td>
            </template>
            <template v-if="perfil.ativo === false">
              <td class="px-6 py-2">
                <i class="fa-solid fa-circle-xmark text-inativo ml-3"></i>
              </td>
            </template>

            <td class="px-6 py-2 text-xs">
              <a @click="selecionarPerfilEditar(perfil.id)" href="#" class="font-medium text-corBase hover:underline">
                <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
              </a>
            </td>

          </tr>
        </tbody>
      </table>
      <Pagination v-if="listaPerfil.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
      <editar-perfil v-if="mostrarModal" :dadosPerfil="perfilSelecionado" @fecharModal="toggleModal"></editar-perfil>
    </div>
  </div>

  <div v-if="importacao != false" class="container-formulario">
    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Perfil</h1>
      </div>

      <router-link to="/produtos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <importarcao-perfil />
  </div>

</template>

<script>
import axios from "axios";
import EditarPerfil from "@/components/midais/Editar/produtos/EditarPerfil";
import vSelect from "vue-select";
import ImportarcaoPerfil from "@/components/midais/importacoes/ImportarcaoPerfil";
import PreviewImage from "@/components/midais/ged/PreviewImage";
import Pagination from "@/utils/Pagination.vue";

export default {
  name: "ListaPeril",

  components: {
    EditarPerfil,
    vSelect,
    ImportarcaoPerfil,
    PreviewImage,
    Pagination
  },

  data() {
    return {
      importacao: false,
      checkAll: false,
      // lista usada para salvar o retorno da requisição do get
      listaPerfil: [],
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      countItens: '',

      perfilSelecionado: null,
      mostrarModal: false,

      // filtros
      lista_cor_perfil: [],
      cor_perfil: '',
      lista_tipo_perfil: [],
      tipo_perfil: '',
      lista_linha_perfil: [],
      linha_perfil: '',
      preview_imagem: '',

      lista_perfil: [],

      error: []
    }
  },

  created() {
    this.getTipoPerfil()
    this.getCorPerfil()
    this.getLinhaPerfil()
  },

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },

    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  methods: {
    closed_preview_image() {
      this.preview_imagem = ''
    },
    openPreviewImage(url) {
      this.preview_imagem = url
    },
    abrirImportacao() {
      this.importacao = !this.importacao
    },
    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    selecionarPerfilEditar(perfil){
      this.perfilSelecionado = perfil
      this.toggleModal()
    },

    toggleModal(){
      this.mostrarModal = !this.mostrarModal
    },

    async getListaPerfil(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      this.$store.dispatch("setLoading", true);

      try {
        const response = await axios.get(`produtos/perfil/?cor_perfil_id=${this.cor_perfil}&tipo_perfil_id=${this.tipo_perfil}&linha_aplicacao_id=${this.linha_perfil}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.lista_perfil = response.data.results;
        this.countItens = response.data.count
        console.log(this.lista_perfil);

      } catch (error) {
        console.error("Erro ao buscar perfis:", error);

      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina;
      this.getListaPerfil(pagina);
    },


    // Requisição para obter a cor dos perfis
    async getCorPerfil() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get('produtos/cor-perfil/?page_size=9999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        // Mapear os dados recebidos a partir de response.data.results
        console.log(response.data.results);
        this.lista_cor_perfil = response.data.results.map(item => ({
          id: item.id,
          text: item.nome
        }));

      } catch (error) {
        console.error("Erro ao buscar cores dos perfis:", error);
      }
    },

    // Requisição para obter o tipo dos perfis
    async getTipoPerfil() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get('produtos/tipo-perfil/?page_size=9999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        // Mapear os dados recebidos a partir de response.data.results
        console.log(response.data.results);
        this.lista_tipo_perfil = response.data.results.map(item => ({
          id: item.id,
          text: item.nome
        }));

      } catch (error) {
        console.error("Erro ao buscar tipos de perfis:", error);
      }
    },

    // Requisição para obter a linha de aplicação dos perfis
    async getLinhaPerfil() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get('produtos/linha-aplicacao/?page_size=9999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        // Mapear os dados recebidos a partir de response.data.results
        console.log(response.data.results);
        this.lista_linha_perfil = response.data.results.map(item => ({
          id: item.id,
          text: item.nome
        }));

      } catch (error) {
        console.error("Erro ao buscar linhas de aplicação dos perfis:", error);
      }
    },

  }
}
</script>

<style lang="scss"></style>