<template>
    <div>
        <!-- Cabeçalho -->
        <div class="container-modelo-tabela">
            <table class="container-tabela overflow-auto">
                <thead class="cabecalho-tabela">
                    <tr>
                        <th class="py-2 px-4 bg-gray-200 border-b">Ações</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">
                            <div class="flex items-center">
                                <p class="mr-2">Pessoa</p>
                                <button class="text-ativo" @click.prevent="mostrarModalPessoa">
                                    <i class="fas fa-plus-circle"></i>
                                </button>
                            </div>
                        </th>
                        <th class="py-2 px-4 bg-gray-200 border-b whitespace-nowrap">
                            <div class="flex items-center">
                                <p class="mr-2">Função na Obra</p>
                                <button class="text-ativo" @click.prevent="mostrarModalResponsabilidade">
                                    <i class="fas fa-plus-circle"></i>
                                </button>
                            </div>
                        </th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Contato</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- Criação de Stakeholders Obrigatórios se ainda estiverem visíveis -->
                    <template v-if="stakeholdersObrigatoriosVisiveis">
                        <tr v-for="(stakeholder, index) in stakeholdersObrigatorios" :key="`obrigatorio-${index}`"
                            class="escopo-tabela">
                            <td class="py-2 px-4"></td>
                            <td class="py-2 px-4 border-b w-96">
                                <!-- Seleção da pessoa para stakeholders obrigatórios -->
                                <v-select v-model="stakeholder.pessoa" :options="listaPessoas" label="text"
                                    class="w-full" />
                            </td>
                            <td class="py-2 px-4 border-b">{{ stakeholder.funcao }}</td>
                            <td class="py-2 px-4 border-b">
                                <input v-model="stakeholder.contato" type="text" class="input-form"
                                    placeholder="DDD + Telefone (apenas números)" v-mask="'(##) #####-####'"
                                    minlength="15" maxlength="15">
                            </td>
                        </tr>
                    </template>

                    <!-- Exibir Stakeholders -->
                    <tr v-for="(stakeholder, index) in stakeholdersFiltrados" :key="index" class="escopo-tabela">
                        <!-- {{ stakeholder }} -->
                        <td class="py-2 px-4 flex gap-2">
                            <button @click.prevent="" class="text-gray-500" title="Excluir stakeholder">
                                <i class="fas fa-times"></i>
                            </button>
                            <button @click.prevent="" class="text-gray-500" title="Editar stakeholder">
                                <i class="fas fa-paperclip"></i>
                            </button>
                        </td>
                        <td class="py-2 px-4 border-b">
                            {{
                                stakeholder.pessoa?.nome_razao ||
                                stakeholder.pessoa.text ||
                                stakeholder.pessoa
                            }}
                        </td>
                        <td class="py-2 px-4 border-b">
                            {{
                                stakeholder.responsabilidade?.descricao ||
                                stakeholder.funcao.text ||
                                stakeholder.funcao
                            }}
                        </td>
                        <td class="py-2 px-4 border-b">
                            {{
                                stakeholder.pessoa?.pessoa_fisica?.telefone_pessoa?.length > 0
                                    ? formataTelefonePessoa(stakeholder.pessoa.pessoa_fisica.telefone_pessoa[0].ddd,
                                        stakeholder.pessoa.pessoa_fisica.telefone_pessoa[0].telefone)
                                    : stakeholder.contato
                            }}
                        </td>
                    </tr>

                    <!-- Linha de input para adicionar novo stakeholder -->
                    <tr v-if="addStakeholder">
                        <td class="py-2 px-4 flex gap-2"></td>
                        <td class="py-2 px-4 border-b w-96">
                            <v-select v-model="novoStakeholder.pessoa" :options="listaPessoas" label="text"
                                class="w-full" />
                        </td>
                        <td class="py-2 px-4 border-b">
                            <v-select v-model="novoStakeholder.funcao" :options="listaResponsabilidades" label="text"
                                class="w-full" />
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="novoStakeholder.contato" type="text" class="input-form"
                                placeholder="DDD + Telefone (apenas números)" readonly @input="validatePhone"
                                minlength="11" maxlength="11">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Botão de Salvar Stakeholders Obrigatórios -->
        <div class="flex justify-end mt-4" v-if="stakeholdersObrigatoriosVisiveis">
            <button class="btn-salvar" @click.prevent="salvarStakeholdersObrigatorios"
                :disabled="!obrigatoriosPreenchidos">Adicionar Stakeholders Obrigatórios</button>
        </div>

        <!-- Botão de Adicionar Stakeholder -->
        <div class="flex justify-end mt-4" v-if="addStakeholder">
            <button class="btn-salvar" @click.prevent="adicionarStakeholder"
                :disabled="!stakeholderPreenchido">Adicionar
                Stakeholder</button>
        </div>

        <!-- Botão para adicionar novo stakeholder -->
        <div class="flex justify-center mt-4">
            <button class="text-ativo" @click.prevent="modalAdicionarStakeholder">
                <i class="fas fa-plus-circle"></i>
            </button>
        </div>

        <!-- Botão para avançar ou salvar formulário
        <div class="flex w-full justify-end itens-center mt-6">
            <button type="submit" @click="$emit('salvarObra')" class="btn-salvar">Salvar Stakeholders</button>
        </div> -->
    </div>

    <ModalCriacaoPessoa ref="modalCriacaoPessoa" @getPessoasFisicas="getPessoasFisicas" />
    <ModalCriacaoResponsabilidade ref="modalCriacaoResponsabilidade"
        @responsabilidadeCriada="adicionarResponsabilidade" />
    <SucessoRapido v-if="sucesso === true" mensagem="Função criada com sucesso." />
    <ErroRapido v-if="erro === true" mensagem="Ocorreu um erro ao criar a função." />

</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import ModalCriacaoPessoa from "@/utils/ModalCriacaoPessoa.vue";
import ModalCriacaoResponsabilidade from "./Modal/ModalCriacaoResponsabilidade.vue";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ErroRapido from "@/components/toasts/toast_erro/ErroRapido.vue";
import FormatarTelefonePessoa from "@/utils/FormatarTelefonePessoa";

export default {
    name: "ListarStakeholders",
    components: {
        vSelect,
        ModalCriacaoPessoa,
        ModalCriacaoResponsabilidade,
        SucessoRapido,
        ErroRapido,
        FormatarTelefonePessoa
    },
    emits: ['atualizarStakeholders', 'atualizarStakeholdersObrigatorios'],
    data() {
        return {
            sucesso: false,
            erro: false,
            stakeholdersObrigatoriosVisiveis: true,
            stakeholdersObrigatorios: [
                { funcao: 'Cliente', pessoa: '', contato: '' },
                { funcao: 'Patrocinador', pessoa: '', contato: '' },
                { funcao: 'Responsável', pessoa: '', contato: '' },
            ],
            stakeholders: [],
            addStakeholder: false,
            novoStakeholder: {
                pessoa: '',
                funcao: '',
                contato: '',
            },
            listaPessoas: [],
            listaResponsabilidades: [],
        };
    },
    props: {
        obra: Object,
    },
    watch: {
        stakeholdersObrigatorios: {
            handler(newStakeholders) {
                newStakeholders.forEach(stakeholder => {
                    if (stakeholder.pessoa && stakeholder.pessoa.telefone) {
                        stakeholder.contato = `(${stakeholder.pessoa.telefone.ddd}) ${stakeholder.pessoa.telefone.telefone}`;
                    }
                });
                // Emitir os stakeholders obrigatórios atualizados para o componente pai
                this.$emit('atualizarStakeholders', [...this.stakeholders, ...newStakeholders]);
            },
            deep: true
        },

        'novoStakeholder.pessoa': {
            handler(newPessoa) {
                if (newPessoa && newPessoa.telefone) {
                    // Preenche o campo de contato com o telefone selecionado
                    this.novoStakeholder.contato = `(${newPessoa.telefone.ddd}) ${newPessoa.telefone.telefone}`;
                }
            },
            immediate: true,
            deep: true
        }
    },
    computed: {
        isadmin() {
            return this.$store.state.isAdminUser;
        },

        obrigatoriosPreenchidos() {
            return this.stakeholdersObrigatorios.every(
                stakeholder => stakeholder.pessoa !== '' && stakeholder.contato !== ''
            );
        },

        stakeholderPreenchido() {
            return (
                this.novoStakeholder.pessoa !== '' &&
                this.novoStakeholder.funcao !== '' &&
                this.novoStakeholder.contato !== ''
            );
        },

        stakeholdersFiltrados() {
            const uniqueStakeholders = [];
            const funcaoSet = new Set(); // Usado para verificar funções duplicadas

            this.stakeholders.forEach(stakeholder => {
                if (!funcaoSet.has(stakeholder.funcao)) {
                    funcaoSet.add(stakeholder.funcao); // Adiciona a função para evitar duplicatas
                    uniqueStakeholders.push(stakeholder); // Adiciona o stakeholder à lista filtrada
                }
            });

            return uniqueStakeholders;
        }
    },

    created() {
        // if (this.stakeholders.length == 0 && this.obra.stakeholders.length >= 1) {
        this.stakeholders = [...this.obra.stakeholders];
        this.ajustarStakeholders();
        // }
        this.getPessoasFisicas();
        this.getResponsabilidades();

        if (this.stakeholders.some(stakeholder => stakeholder.funcao !== 'Cliente')) {
            this.stakeholdersObrigatoriosVisiveis = false;
        }
    },

    methods: {

        formataTelefonePessoa(ddd, telefone) {
            return FormatarTelefonePessoa(ddd, telefone)
        },

        ajustarStakeholders() {
            // Stakeholders Cliente, Patrocinador e Responsável
            if (this.obra.cliente?.nome_razao) {
                this.stakeholders = [
                    {
                        funcao: 'Cliente',
                        pessoa: this.obra?.cliente?.nome_razao || '',
                        contato: this.obra?.cliente?.pessoa_fisica?.telefone_pessoa?.length
                            ? this.formataTelefonePessoa(this.obra.cliente.pessoa_fisica.telefone_pessoa[0].ddd, this.obra.cliente.pessoa_fisica.telefone_pessoa[0].telefone)
                            : ''
                    },
                    {
                        funcao: 'Patrocinador',
                        pessoa: this.obra?.patrocinador?.nome_razao || '',
                        contato: this.obra?.patrocinador?.pessoa_fisica?.telefone_pessoa?.length
                            ? this.formataTelefonePessoa(this.obra.patrocinador.pessoa_fisica.telefone_pessoa[0].ddd, this.obra.patrocinador.pessoa_fisica.telefone_pessoa[0].telefone)
                            : ''
                    },
                    {
                        funcao: 'Responsável',
                        pessoa: this.obra?.responsavel?.nome_razao || '',
                        contato: this.obra?.responsavel?.pessoa_fisica?.telefone_pessoa?.length
                            ? this.formataTelefonePessoa(this.obra.responsavel.pessoa_fisica.telefone_pessoa[0].ddd, this.obra.responsavel.pessoa_fisica.telefone_pessoa[0].telefone)
                            : ''
                    }
                ];
                // Caso seja uma edição, adicionar os stakeholders existentes
                if (this.obra.stakeholders?.length > 0) {
                    this.stakeholders.push(...this.obra.stakeholders);
                }
            }
        },


        mostrarModalPessoa() {
            this.$refs.modalCriacaoPessoa.abrirModal();
        },

        modalAdicionarStakeholder() {
            this.addStakeholder = true;
        },

        adicionarStakeholder() {
            if (this.stakeholderPreenchido) {
                this.stakeholders.push(this.novoStakeholder);
                this.novoStakeholder = {
                    pessoa: '',
                    funcao: '',
                    contato: '',
                };
                this.addStakeholder = false;
                // Emitir para garantir que a lista seja atualizada corretamente
                this.$emit('atualizarStakeholders', this.stakeholders);
            }
        },

        mostrarModalResponsabilidade() {
            this.$refs.modalCriacaoResponsabilidade.abrirModal();
        },

        async adicionarResponsabilidade(novaResponsabilidade) {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.post('/obra/responsabilidade/',
                    {
                        descricao: novaResponsabilidade,
                    },
                    {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    })

                this.sucesso = true
                setTimeout(() => {
                    this.sucesso = false;
                }, 3000);
                this.getResponsabilidades()
            } catch (error) {
                console.error(error)
                this.erro = true
                setTimeout(() => {
                    this.erro = false;
                }, 3000);
            }
        },

        salvarStakeholdersObrigatorios() {
            // Adicionar os obrigatórios à lista visual
            this.stakeholders.push(...this.stakeholdersObrigatorios);

            // Emite os obrigatórios para o componente pai
            // console.log(this.stakeholdersObrigatorios)
            const newStakeholdersObrigatorios = {
                cliente: this.stakeholdersObrigatorios.find(stakeholder => stakeholder.funcao === 'Cliente'),
                patrocinador: this.stakeholdersObrigatorios.find(stakeholder => stakeholder.funcao === 'Patrocinador'),
                responsavel: this.stakeholdersObrigatorios.find(stakeholder => stakeholder.funcao === 'Responsável'),
            };
            this.$emit('salvarStakeholdersObrigatorios', newStakeholdersObrigatorios);

            this.stakeholdersObrigatoriosVisiveis = false;
        },

        validatePhone(event) {
            event.target.value = event.target.value.replace(/\D/g, '');
        },

        async getPessoasFisicas() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get('pessoas/fisicas/?ativo=True', {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });
                // console.log(response.data)
                this.listaPessoas = response.data.results.map(person => ({
                    id: person.id,
                    text: person.nome_razao,
                    telefone: person.pessoa_fisica.telefone_pessoa[0]
                }));
            } catch (error) {
                console.error(error);
            }
        },

        async getResponsabilidades() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get('/obra/responsabilidade/?page_size=9999', {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                })

                this.listaResponsabilidades = response.data.results.map(responsabilidade => ({
                    id: responsabilidade.id,
                    text: responsabilidade.descricao
                }));
            } catch (error) {
                console.error(error)
            }
        }
    }
};
</script>

<style scoped>
.input-form {
    border: 1px solid #ccc;
    padding: 8px;
    width: 100%;
}

/* Para o Google Chrome, Safari, Edge e Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Para o Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
</style>
