<template>

  <div class="container-formulario">

    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Pessoas Físicas</h1>
      </div>

      <router-link to="/pessoas" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <div class="mb-8 flex items-end gap-8">
      <div class="campo-chapa">
        <label>Tipo Pessoa</label>
        <v-select v-model="tipo_pessoa" :options="lista_tipo_pessoa" class="w-full" label="label"
          :reduce="option => option.value" />
      </div>

      <div class="campo-chapa">
        <label>Status</label>
        <select v-model="status" class="input-campos">
          <option value="True">Ativo</option>
          <option value="False">Inativo</option>
        </select>
      </div>
      <div>
        <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="getListaPessoas(1)">Pesquisar
        </button>
      </div>
    </div>
    <div class="mb-8">
      <button @click="$router.push(`/pessoa/pessoa-fisica`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 mt-2 mr-2">Adicionar
      </button>
    </div>


    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              #
            </th>
            <th class="px-6 py-3">
              Nome
            </th>
            <th class="px-6 py-3">
              CPF
            </th>
            <th scope="col" class="px-6 py-3">
              Tipo
            </th>
            <th scope="col" class="px-6 py-3">
              Status
            </th>
            <th scope="col" class="px-6 py-3">
              Ações
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(pessoa, index) in listaPessoas" :key="index" class="escopo-tabela">
            <template v-if="pessoa.pessoa_fisica !== null">
              <td class="w-4 p-4">
                <div class="flex items-center">
                  <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                    v-model="pessoa.selected">
                  <label class="sr-only">checkbox</label>
                </div>
              </td>
              <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                {{ index + 1 }}
              </th>
              <td class="px-6 py-2 text-xs">
                {{ pessoa.nome_razao }}
              </td>
              <td class="px-6 py-2 text-xs">
                {{ formatarCPF(pessoa.pessoa_fisica.cpf) }}
              </td>
              <template v-if="pessoa.pessoa_fisica !== null">
                <td class="px-6 py-2 text-xs">
                  {{ pessoa.tipo_pessoa_geral_descricao }}
                </td>
              </template>
              <template v-if="pessoa.pessoa_fisica !== null">
                <template v-if="pessoa.pessoa_fisica.ativo === true">
                  <td class="px-6 py-2 text-xs">
                    <i class="fa-solid fa-circle-check  text-ativo ml-3"></i>
                  </td>
                </template>
                <template v-if="pessoa.pessoa_fisica.ativo === false">
                  <td class="px-6 py-2">
                    <i class="fa-solid fa-circle-xmark text-inativo ml-3"></i>
                  </td>
                </template>
              </template>

              <td class="px-6 py-2 text-xs">
                <!-- <EditarDados :dadosPessoa="pessoa"></EditarDados> -->
              </td>
            </template>

          </tr>
        </tbody>
      </table>
      <Pagination v-if="listaPessoas.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
    </div>
  </div>

</template>

<script>
import EditarDados from "@/components/midais/Editar/EditarDados";
import Select2 from "vue3-select2-component";
import vSelect from "vue-select";
import Pagination from "@/utils/Pagination.vue";
import axios from "axios";

export default {
  name: "ListaPessoaFisica",

  components: {
    EditarDados,
    Select2,
    vSelect,
    Pagination
  },

  data() {
    return {
      checkAll: false,
      listaPessoas: [],
      paginaAtual: 1,
      countItens: '',
      filtros: false,
      lista_tipo_pessoa: [],
      tipo_pessoa: '',
      status: ''
    }
  },

  created() {
    this.getListaTipoPessoaGeral()
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  methods: {
    selectAll() {
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    async getListaTipoPessoaGeral() {
      const token = await this.$store.state.token;
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get('pessoas/tipo-pessoas/?page_size=9999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.lista_tipo_pessoa = response.data.results.map(item => ({
          value: item.id,
          label: item.descricao
        }));

      } catch (error) {
        console.error(error);
      }
    },

    async getListaPessoas(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      this.$store.dispatch("setLoading", true);

      try {
        const response = await axios.get(`/pessoas/fisicas/?tipo_pessoa_geral_id=${this.tipo_pessoa || ''}&ativo=${this.status}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.listaPessoas = response.data.results;
        this.countItens = response.data.count;
        console.log(this.listaPessoas);

      } catch (error) {
        console.error("Erro ao buscar lista de pessoas:", error);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina;
      this.getListaPessoas(pagina);
    },

    formatarCPF(cpf) {
      // Remove caracteres não numéricos
      let cpfNumerico = cpf.replace(/\D/g, '');

      // Formata o CPF para o formato XXX.XXX.XXX-XX
      return cpfNumerico.replace(/(\d{3})(\d{3})(\d{3})(\d{0,2})/, '$1.$2.$3-$4');
    }

  }
}
</script>

<style scoped></style>