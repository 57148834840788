<template>
  <div>
    <div class="container-modal-editar">
      <div class="sombra-modal">
        <div class="conteudo-modal-editar">
          <form class="form-editar text-white">
            <div class="flex justify-end">
              <button @click.prevent="$emit('fecharModal')">
                <i class="fas fa-close text-2xl text-corBase"></i>
              </button>
            </div>
            <h2 class="text-lg text-corBase font-bold mb-2">Editar Dados Serviço</h2>
            <!--Campos da Pessoa-->
            <div>
              <div class="container-campos">
                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Produto Base:</label>
                  <input type="text" required class="input-campos" v-model="servico.produto_base.nome_base">
                </div>

                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Fornecedor:</label>
                  <input type="text" required class="input-campos" v-model="servico.fornecedor.pessoa.nome_razao" readonly>
                  <!-- v-model="fornecedor.nome_razao" -->
                </div>

                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Código Fornecedor:</label>
                  <input type="text" required class="input-campos" v-model="servico.codigo_fornecedor">
                </div>

              </div>

              <div class="container-campos">
                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Nome Especifico:</label>
                  <input type="text" required class="input-campos" v-model="servico.nome_especifico">
                </div>
                <div class="campos-mobile">
                  <label class="label-campos">Grupo de Aplicação:</label>
                  <input type="text" required class="input-campos" placeholder="" v-model="servico.grupo_servico.nome">
                </div>
                <div class="campos-mobile">
                  <label class="label-campos">Tipo de Serviço:</label>
                  <input type="text" required class="input-campos" placeholder="" v-model="servico.tipo_servico.nome">
                </div>
              </div>
            </div>
            <!--Campos de endereço-->
            <div>

              <div class="container-campos">
                <div class="flex flex-col mt-2 w-full md:w-60">
                  <label class="label-campos">Custo Médio do Serviço:</label>
                  <input type="text" required placeholder="" class="input-campos" v-model="servico.custo_medio_servico">
                </div>

                <div class="campos campos-mobile">
                  <label class="label-campos">Tempo Médio do Serviço:</label>
                  <input type="text" required class="input-campos" placeholder="" v-model="servico.tempo_medio_servico">
                </div>

                <!-- <div class="flex flex-col mt-2 w-full md:w-60">
                  <label class="label-campos">Cor Chapa:</label>
                  <input type="text" required class="input-campos" v-model="corchapas.nome">
                </div>
                <div class="flex flex-col mt-2 w-full md:w-[500px]">
                  <label class="label-campos">Tipo Chapa:</label>
                  <div class="">
                    <input type="text" required class="input-campos" placeholder="" v-model="tipochapa.nome">
                  </div>
                </div>  -->
              </div>

              <!-- <div class="container-campos  ">


              </div>

              <div class=" container-campos ">

                <div class="campos-mobile">
                  <label class="label-campos">Peso por Metro:</label>
                  <input type="text" required class="input-campos" placeholder="" v-model="servico.peso">
                </div>
                <div class="campos-mobile">
                  <label class="label-campos">Preço Médio:</label>
                  <input type="text" required class="input-campos" placeholder="" v-model="servico.preco_medio">
                </div>

                <div class="campos-mobile">
                  <label class="label-campos">Preço Base:</label>
                  <div class="flex">
                    <input type="text" required class="input-campos" placeholder="" v-model="servico.preco_base">
                  </div>
                </div>

                <div class="campos-mobile">
                  <label class="label-campos">Transporte:</label>
                  <div class="flex">
                    <input type="text" required class="input-campos" placeholder="" v-model="servico.transporte">
                  </div>
                </div>
              </div>

              <div class=" container-campos ">
                <div class="campos-mobile">

                </div>
              </div> -->

            </div>

            <!--Botão-->
            <div class="btn-formulario-modal">
              <button class="btn-formulario-salvar" @click.prevent="putDadosEditado">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
      <SucessoRapido v-if="sucesso === true" mensagem="Serviço atualizado atualizado com sucesso!" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";

export default {
  name: "EditarServico",
  components: {
    SucessoRapido
  },
  data() {
    return {
      statusModal: false,
      sucesso: false,
      servico: [],
      produtoBase: {},
      fonecedorChapa: {},
      grupoaplicacao: {},
      corchapas: {},
      tipochapa: {}

    }
  },
  props: ["dadosServico"],
  emits: ["fecharModal"],
  created() {
    this.getDadosServico()
    // this.getProdutoBase()
    // this.getFornecedor()
    // this.getGrupoAplicacao()
    // this.getCorChapa()
    // this.getTipoChapa()
  },
  methods: {
    async getDadosServico() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')
      try {
        const response = await axios.get(`produtos/servico/${this.dadosServico}/`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.servico = response.data
        console.log(response.data)
      } catch (error) {
        console.error(error)
      }
    },

    async putDadosEditado() {
      const dadosAtualizados = new FormData();
      dadosAtualizados.append('ativo', this.servico.ativo)
      dadosAtualizados.append('custo_medio_servico', this.servico.custo_medio_servico)
      dadosAtualizados.append('grupo_servico', this.servico.grupo_servico.id)
      dadosAtualizados.append('tempo_medio_servico', this.servico.tempo_medio_servico)
      dadosAtualizados.append('tipo_pessoa', this.servico.tipo_pessoa.id)
      dadosAtualizados.append('tipo_servico', this.servico.tipo_servico.id)


      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.patch(`produtos/servico/${this.servico.id}/`, dadosAtualizados, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        });
        // console.log(response);
        this.sucesso = true;
        setTimeout(() => {
          this.sucesso = false;
          this.$emit('fecharModal')
        }, 3000);

      } catch (error) {
        console.error(error)
      }
    },

    async getProdutoBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/produto/${this.servico.produto_base.id}/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.produtoBase = response.data
          // console.log(this.produtoBase)
        })
        .catch(error => {
          console.error(error)
        })

    },

    async getFornecedor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`pessoas/fornecedor/${this.servico.fornecedor.id}/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.fornecedorChapa = response.data
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getGrupoAplicacao() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await axios.get(`produtos/grupo-aplicacao-chapa/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.grupoaplicacao = response.data
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getCorChapa() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await axios.get(`produtos/cor-chapa/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.corchapas = response.data
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getTipoChapa() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await axios.get(`produtos/tipo-chapa/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.tipochapa = response.data
        })
        .catch(error => {
          console.error(error)
        })
    }

  }
}
</script>

<style>
.sombra-modal {
  @apply fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-100
}
</style>