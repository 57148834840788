<template>

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Sistemista</h1>
      </div>

      <router-link to="/projetos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="flex gap-8 items-end mb-6 w-[60%]">
      <div class="w-full">
        <input 
          type="text" 
          class="input-form-chapa" 
          v-model="projetista_sistemista"
          placeholder="Nome"
        >
      </div>
      <div>
        <button class="btn-add-fisica" @click="getListaProjetistaSistemista(paginaAtual)">Pesquisar</button>
      </div>
      <div v-if="isadmin === true">
        <button @click="$router.push(`/projetista-sistemista/cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-1 rounded-sm px-4 mt-2 mr-2">Adicionar
        </button>
      </div>
    </div>


    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="p-4">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-6 py-3">
            #
          </th>
          <th class="px-6 text-start w-full">
            Nome
          </th>
          <th v-if="isadmin === true" class="text-center px-10">
            Código
          </th>
          <th v-if="isadmin === true" scope="col" class="text-center px-10">
            Status
          </th>
          <th v-if="isadmin === true" scope="col" class=" px-10 text-start">
            Ações
          </th>
        </tr>
        </thead>
        <tbody>

        <template v-if="isadmin === true">
          <tr v-for="(projetista, index) in lista_projetista_sistemista" :key="index"
              class="escopo-tabela">

            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                       v-model="projetista.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row"
                class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-sm">
              {{ projetista.nome }}
            </td>
            <td class="px-6 py-2 text-sm text-center" v-if="isadmin === true">
              {{ projetista.codigo }}
            </td>

            <template v-if="projetista.ativo === true && isadmin === true">
              <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
                <i class="fa-solid fa-circle-check  text-ativo"></i>
              </td>
            </template>
            <template v-if="projetista.ativo === false && isadmin === true">
              <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
                <i class="fa-solid fa-circle-xmark text-inativo"></i>
              </td>
            </template>


            <td class="px-6 py-2 text-xs" v-if="isadmin === true">
              <editar-projetista-sistemista
                  :projetistaSistemista="projetista">
              </editar-projetista-sistemista>
            </td>


          </tr>

        </template>

        <template v-else>

          <tr v-for="(projetista, index) in lista_projetista_sistemista" :key="index"
              class="escopo-tabela">

            <template v-if="projetista.ativo === true">

              <td class="w-4 p-4">
                <div class="flex items-center">
                  <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                         v-model="projetista.selected">
                  <label class="sr-only">checkbox</label>
                </div>
              </td>
              <th scope="row"
                  class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                {{ index + 1 }}
              </th>
              <td class="px-6 py-2 text-sm">
                {{ projetista.nome }}
              </td>
              <td class="px-6 py-2 text-sm">
                {{ projetista.empresa?.nome_razao }}
<!--                <span v-if="projetista_sistemista.empresa.nome_razao"> -->
<!--                  {{ projetista_sistemista.empresa.nome_razao }} -->
<!--                </span> -->
<!--                <span v-else> -->
<!--                  {{ projetista_sistemista.empresa }} -->
<!--                </span> -->
              </td>
              <td class="px-6 py-2 text-sm text-center" v-if="isadmin === true">
                {{ projetista.codigo }}
              </td>

              <template v-if="projetista.ativo === true && isadmin === true">
                <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
                  <i class="fa-solid fa-circle-check  text-ativo"></i>
                </td>
              </template>
              <template v-if="projetista.ativo === false && isadmin === true">
                <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
                  <i class="fa-solid fa-circle-xmark text-inativo"></i>
                </td>
              </template>


              <td class="px-6 py-2 text-xs" v-if="isadmin === true">
                <editar-projetista-sistemista
                    :projetistaSistemista="projetista">
                </editar-projetista-sistemista>
              </td>

            </template>


          </tr>


        </template>
        </tbody>
      </table>

      <!-- <div class="paginacao">
        <button @click.prevent="paginaAnterior" class="btn-paginacao">
          <i class="fas fa-chevron-left"></i>
        </button>
        <span v-for="pagina in paginasExibidas" :key="pagina">
          <button @click.prevent="irParaPagina(pagina)"
                  :class="{ 'btn-paginacao': true, 'pagina-atual': pagina === paginaAtual }">
            {{ pagina }}
          </button>
        </span>
        <button @click.prevent="proximaPagina" class="btn-paginacao">
          <i class="fas fa-chevron-right"></i>
        </button>
      </div> -->
      <Pagination
        v-if="lista_projetista_sistemista.length > 0"
        :offset="paginaAtual"
        :total="countItens"
        :limit="20"
        @change-page="mudarPagina"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import EditarProjetistaSistemista from "@/components/midais/Editar/projeto/EditarProjetistaSistemista";
import Pagination from '@/utils/Pagination.vue';

export default {
  name: "ListaProjetistaSistemista",

  components: {
    EditarProjetistaSistemista,
    Pagination
  },

  data() {
    return {
      lista_projetista_sistemista: [],
      projetista_sistemista: '',

      checkAll: false,
      // lista usada para salvar o retorno da requisição do get
      listaProjetistaSistemista: [],
      // lista usada para salvar esse retorno depois de dividido
      listaProjetistaSistemistaPaginada: [],
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      countItens: 0,
      next_page: null,
      itemsPorPagina: 5,

      error: [],
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },
    // paginasExibidas() {
    //   const paginas = [];
    //   const paginasVisiveis = 5; // Número de páginas visíveis de cada vez

    //   let inicio = Math.max(1, this.paginaAtual - Math.floor(paginasVisiveis / 2));
    //   let fim = inicio + paginasVisiveis - 1;

    //   if (inicio + paginasVisiveis > this.totalDePaginas) {
    //     inicio = Math.max(1, this.totalDePaginas - paginasVisiveis + 1);
    //     fim = this.totalDePaginas;
    //   }

    //   for (let i = inicio; i <= fim; i++) {
    //     paginas.push(i);
    //   }

    //   return paginas;
    // },
  },

  created() {
  },

  methods: {

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    async getListaProjetistaSistemista(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`/projeto/projetista-sistemista/?nome=${this.projetista_sistemista}&page=${pagina}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_projetista_sistemista = response.data.results
            this.countItens = response.data.count
          })
       //else {
      //   await axios.get(`/projeto/projetista-sistemista/?nome=${this.projetista_sistemista}&page=${this.paginaAtual}`, {
      //     headers: {
      //       'Authorization': `Token ${token}`
      //     }
      //   })
      //       .then(response => {
      //         this.lista_projetista_sistemista = response.data.results
      //         console.log(this.lista_projetista_sistemista)
      //         this.totalDePaginas = response.data.count / 5
      //         console.log(this.totalDePaginas)
      //         this.paginaAtual += 1
      //       })
      // }

    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina
      this.getListaProjetistaSistemista(pagina)
    },

    proximaPagina() {
      this.getListaProjetistaSistemista();
    },

    paginaAnterior() {
      this.paginaAtual -= 1
      this.getListaProjetistaSistemista()
    },

    // async irParaPagina(pagina) {
    //   const token = await this.$store.dispatch('getAuthTokenCookie')
    //   console.log(pagina)
    //   this.paginaAtual = pagina
    //   await axios.get(`/projeto/projetista-sistemista/?nome=${this.projetista_sistemista}&page=${this.paginaAtual}`, {
    //       headers: {
    //         'Authorization': `Token ${token}`
    //       }
    //     })
    //         .then(response => {
    //           this.lista_projetista_sistemista = response.data.results
    //           console.log(this.lista_projetista_sistemista)
    //           this.totalDePaginas = response.data.count / 5
    //         })
    // }

  }
}
</script>

<style lang="scss"></style>