<template>

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Lista de Pessoas</h1>
      </div>

      <router-link to="/cadastros" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="mb-8">
      <span class="text-corBase mr-2">Adicionar: </span>
      <button @click="$router.push(`/pessoa/pessoa-fisica`)" class="btn-add-juridica mr-2">Física</button>
      <button @click="$router.push(`/pessoa/pessoa-juridica`)" class="btn-add-juridica">Jurídica</button>
    </div>


    <!-- Filtros -->
    <div class="flex items-end gap-8 mb-6">
      <div class="w-1/2">
        <!--        <input type="text" v-model="cor" class="input-campos">-->
        <label class="text-xl text-corBase font-bold">Tipo:</label>
        <v-select v-model="tipo" :options="[
          { id: 'fisica', text: 'Física' },
          { id: 'juridica', text: 'Jurídica' }
        ]" label="text" :reduce="option => option.id" class="w-full" />
      </div>
      <div>
        <label class="text-xl text-corBase font-bold">Nome ou Razão:</label>
        <input type="text" v-model="nome_razao" class="input-campos">
      </div>
      <!-- <div>
        <label class="text-xl text-corBase font-bold">Nome ou Razão:</label>
        <input type="text" v-model="produto_base" class="input-campos">
      </div>
      <div>
        <input type="text" v-model="grupo_aplicacao" class="input-campos">
      </div> -->
      <div>
        <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="getListaPessoas(1)">Pesquisar</button>
      </div>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox">
                <label for="checkbox-all" class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              #
            </th>
            <th scope="col" class="px-6 py-3">
              Nome/Razão
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Tipo
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Status
            </th>
            <th scope="col" class="px-6 py-3">
              Ações
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(pessoa, index) in listaPessoas" :key="index" class="escopo-tabela">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input id="checkbox-table-1" type="checkbox" class="checkbox">
                <label for="checkbox-table-1" class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-4">
              {{ pessoa.nome_razao }}
            </td>
            <template v-if="pessoa.pessoa_fisica !== null">
              <td class="px-6 py-4 flex justify-center" title="Pessoa Física">
                <i class="fas fa-user"></i>
              </td>
            </template>
            <template v-if="pessoa.pessoa_juridica !== null">
              <td class="px-6 py-4 flex justify-center" title="Pessoa Jurídica">
                <i class="fa-solid fa-industry"></i>
              </td>
            </template>
            <template v-if="pessoa.pessoa_fisica !== null">
              <template v-if="pessoa.pessoa_fisica.ativo === true">
                <td class="px-6 py-4 text-center">
                  <i class="fa-solid fa-circle-check  text-ativo"></i>
                </td>
              </template>
              <template v-if="pessoa.pessoa_fisica.ativo === false">
                <td class="px-6 py-4 text-center">
                  <i class="fa-solid fa-circle-xmark text-inativo"></i>
                </td>
              </template>
            </template>

            <template v-if="pessoa.pessoa_juridica !== null">
              <template v-if="pessoa.pessoa_juridica.ativo === true">
                <td class="px-6 py-4 text-center">
                  <i class="fa-solid fa-circle-check  text-ativo"></i>
                </td>
              </template>
              <template v-if="pessoa.pessoa_juridica.ativo === false">
                <td class="px-6 py-4 text-center">
                  <i class="fa-solid fa-circle-xmark  text-inativo"></i>
                </td>
              </template>
            </template>

            <td class="px-6 py-4">
              <!-- <EditarDados :dadosPessoa="pessoa"></EditarDados> -->
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination v-if="listaPessoas.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
    </div>
  </div>


</template>

<script>
import axios from "axios";
import EditarDados from "@/components/midais/Editar/EditarDados";
import Pagination from "@/utils/Pagination.vue";
import vSelect from "vue-select";

export default {
  name: 'ListaDePessoas',

  components: {
    EditarDados,
    Pagination,
    vSelect
  },

  data() {
    return {
      // lista usada para salvar o retorno da requisição do get
      listaPessoas: [],
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      countItens: '',
      // variavel que manipula os estados dos modais
      tipo: '',
      nome_razao: '',
    }
  },

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  methods: {

    // busca a lista de pessoas cadastrada no banco de dados
    async getListaPessoas(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get(`/pessoas/?tipo=${this.tipo}&nome_razao=${this.nome_razao}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.listaPessoas = response.data.results;
        this.countItens = response.data.count;
        console.log(this.listaPessoas);

      } catch (error) {
        console.error("Erro ao buscar lista de pessoas:", error);
      }
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina;
      this.getListaPessoas(pagina);
    },


  }
}
</script>

<style lang="scss">
//estilo paginacao
.paginacao {
  @apply flex w-full justify-end py-2 px-2
}

.btn-paginacao {
  @apply flex px-3 py-1 border border-corBase/30 items-center text-corBase/50
}

.numero-paginacao {
  @apply flex px-3 py-1 border border-corBase/10 text-corBase
}

.numero-paginacao-ativa {
  @apply flex px-3 py-1 border border-corBase/80 text-corBase
}

// estilo da lista até 768 pixels
.tela-lista-pessoas {
  @apply w-[95%] mt-6 bg-screenCor rounded-3xl p-4
}

.botoes-cabecalho {
  @apply flex flex-col gap-3 mt-6
}

.btn-add-juridica {
  @apply py-1 px-3 bg-screenCor rounded-sm text-corBase border border-corBase hover:bg-corBase hover:border hover:border-screenCor hover:text-screenCor
}

.btn-add-fisica {
  @apply bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4
}


// estilo lista de pessoas acima de 768 pixels
.container-lista-pessoas768 {
  @apply ml-24 mt-24 absolute w-[90%] p-4 bg-white rounded-3xl xl:w-[88%]
}


// modelo tabela estilo
.container-modelo-tabela {
  @apply relative overflow-x-auto shadow-md sm:rounded-lg rounded-lg;
}

.container-tabela {
  @apply w-full text-sm text-left text-gray-500 dark:text-gray-400;
}

.cabecalho-tabela {
  @apply text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400;
}

.checkbox {
  @apply w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600;
}

.escopo-tabela {
  @apply bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600;
}
</style>
