<template>


  <div class="container-formulario">

    <div class="cabecalho-dashboard">
      <h1>Cadastro de Pessoas</h1>

      <button @click="$router.push(`/cadastros`)" class="text-2xl">
        <i class="fas fa-chevron-left mr-1 "></i>
      </button>
    </div>

    <div class="flex flex-wrap">

      <button class="escopo-dashboard-desktop" @click="$router.push('/cliente')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Clientes</h2>
            <i class="fas fa-users"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Clientes presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/fornecedor')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Fornecedores</h2>
            <i class="fa-solid fa-truck-field"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Fornecedores presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/pessoa-fisica/lista')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Pessoa Física</h2>
            <i class="fas fa-user"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as Pessoas Físicas presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/pessoa-juridica/lista')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Pessoa Jurídica</h2>
            <i class="fa-solid fa-industry"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as Pessoas Jurídicas presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/outros')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Outros</h2>
            <i class="fas fa-user-friends"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>
      </button>

    </div>


  </div>


</template>

<script>
export default {
  name: "PessoasDashboard",
}
</script>

<style lang="scss"></style>