<template>
    <div>
        <h2 class="text-lg text-corBase font-bold">Produtos da Tabela</h2>

        <!-- Adicionando uma div para rolagem lateral -->
        <div class="container-modelo-tabela">
            <table class="container-tabela overflow-auto">
                <thead class="cabecalho-tabela">
                    <tr>
                        <th class="py-2 px-4 bg-gray-200 border-b">Ações</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Preço por KG</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Acréscimo por Projeto</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Acréscimo por Balcão</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Valor final por Projeto</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Valor final por Balcão</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- Exibir os produtos existentes -->
                    <tr v-for="produto in produtosPorKG" :key="produto.id">
                        <td class="py-2 px-4 border-b flex gap-2">
                            <button @click="deletePerfil(produto.id)" class="text-gray-500" title="Excluir Perfil">
                                <i class="fas fa-times"></i>
                            </button>
                            <button @click="abrirModalEditarProduto(produto)" class="text-gray-500"
                                title="Editar Perfil">
                                <i class="fas fa-paperclip"></i>
                            </button>
                        </td>
                        <td class="py-2 px-4 border-b">R$ {{ parseFloat(produto.preco).toFixed(2) }}</td>
                        <td class="py-2 px-4 border-b">{{ parseFloat(produto.margem_lucro_projeto).toFixed(2) }}%</td>
                        <td class="py-2 px-4 border-b">{{ parseFloat(produto.margem_lucro_balcao).toFixed(2) }}%</td>
                        <td class="py-2 px-4 border-b">R$ {{ calcularValorFinalProdutoExistente(produto) }}</td>
                        <td class="py-2 px-4 border-b">R$ {{ calcularValorFinalBalcaoProdutoExistente(produto) }}</td>
                    </tr>

                    <!-- Linha de input para adicionar novo produto -->
                    <tr v-if="adicionarProdutoAtivo">
                        <td class="py-2 px-4 border-b"></td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="novoProduto.preco" type="number" min="0" class="input-form">
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="novoProduto.margem_lucro_projeto" type="number" min="0" class="input-form">
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="novoProduto.margem_lucro_balcao" type="number" min="0" class="input-form">
                        </td>
                        <td class="py-2 px-4 border-b">{{ calcularValorFinal(novoProduto) }}</td>
                        <td class="py-2 px-4 border-b">{{ calcularValorFinalBalcao(novoProduto) }}</td>
                    </tr>

                    <!-- Modal inline de edição de produto -->
                    <tr v-if="editarProdutoAtivo">
                        <td class="py-2 px-4 border-b"></td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="produtoSelecionado.preco" type="number" min="0" class="input-form">
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="produtoSelecionado.margem_lucro_projeto" type="number" min="0"
                                class="input-form">
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="produtoSelecionado.margem_lucro_balcao" type="number" min="0"
                                class="input-form">
                        </td>
                        <td class="py-2 px-4 border-b">{{ calcularValorFinal(produtoSelecionado) }}</td>
                        <td class="py-2 px-4 border-b">{{ calcularValorFinalBalcao(produtoSelecionado) }}</td>
                    </tr>

                </tbody>
            </table>
        </div>

        <!-- Botão de Adicionar Produto -->
        <div class="flex justify-end mt-4" v-if="adicionarProdutoAtivo">
            <button class="btn-salvar" @click.prevent="adicionarProduto" :disabled="!produtoPreenchido">
                Adicionar Produto
            </button>
        </div>

        <!-- Botão para adicionar novo produto -->
        <div class="flex justify-center mt-4" v-if="produtosPorKG.length == 0">
            <button class="text-ativo" @click.prevent="abrirLinhaAdicionarProduto">
                <i class="fas fa-plus-circle"></i>
            </button>
        </div>

        <!-- Botão de salvar as edições no modal de edição -->
        <div class="flex justify-end mt-4" v-if="editarProdutoAtivo">
            <button class="btn-salvar" @click.prevent="salvarProdutoEditado">Salvar Alterações</button>
        </div>
    </div>

    <SucessoRapido v-if="sucesso === true" mensagem="Alteração feita com sucesso." />
</template>

<script>
import axios from 'axios'
import formatarData from '@/utils/FormatarData';
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";

export default {
    name: 'PrecoKG',
    props: {
        dadosPerfil: Object
    },
    components: {
        SucessoRapido
    },
    created() {
        console.log(this.dadosPerfil)
        this.buscarPerfilPorKG()
    },
    data() {
        return {
            produtosPorKG: [],
            adicionarProdutoAtivo: false,
            novoProduto: {
                preco: 0,
                margem_lucro_projeto: 0,
                margem_lucro_balcao: 0
            },
            editarProdutoAtivo: false,
            produtoSelecionado: {},
            sucesso: false,
        };
    },
    methods: {
        async buscarPerfilPorKG() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`precificacao/itens-perfil-kg/?id_tabela_preco=${this.$route.params.id}`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });
                this.produtosPorKG = response.data.results;
                console.log(this.produtosPorKG)
                this.countItens = response.data.count
            } catch (error) {
                console.error("Erro ao buscar lista de perfil por KG:", error);
            }
        },

        abrirModalEditarProduto(produto) {
            console.log(this.produto)
            this.produtoSelecionado = {
                ...produto,
            };
            this.editarProdutoAtivo = true;
        },

        fecharModal() {
            this.editarProdutoAtivo = false;
        },

        async salvarProdutoEditado() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                // Requisição para salvar as alterações do produto editado
                await axios.put(
                    `precificacao/itens-perfil-kg/${this.produtoSelecionado.id}/`,
                    {
                        tabela_preco_perfil: this.dadosPerfil.id,
                        preco: this.produtoSelecionado.preco,
                        margem_lucro_projeto: this.produtoSelecionado.margem_lucro_projeto,
                        margem_lucro_balcao: this.produtoSelecionado.margem_lucro_balcao,
                        valor_final_venda_projeto: this.calcularValorFinal(this.produtoSelecionado),
                        valor_final_venda_balcao: this.calcularValorFinalBalcao(this.produtoSelecionado)
                    },
                    {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    }
                );

                this.buscarPerfilPorKG()

                this.sucesso = true
                setTimeout(() => {
                    this.sucesso = false;
                }, 3000);
                this.fecharModal();
            } catch (error) {
                console.error('Erro ao salvar o produto editado:', error);
            }
        },

        async deletePerfil(id) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                // Realiza a exclusão do perfil
                await axios.patch(`precificacao/itens-perfil-kg/${id}/`, { ativo: 'False' }, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                this.buscarPerfilPorKG();
                this.sucesso = true
                setTimeout(() => {
                    this.sucesso = false;
                }, 3000);
            } catch (error) {
                console.error("Erro ao excluir o Perfil:", error);
            }
        },

        calcularValorFinal(produto) {
            const valor = parseFloat(produto.preco) + parseFloat(produto.preco) * (parseFloat(produto.margem_lucro_projeto) / 100);
            return parseFloat(valor).toFixed(2);
        },

        calcularValorFinalBalcao(produto) {
            const valor = parseFloat(produto.preco) + parseFloat(produto.preco) * (parseFloat(produto.margem_lucro_balcao) / 100);
            return parseFloat(valor).toFixed(2);
        },

        calcularValorFinalProdutoExistente(produto) {
            const preco = parseFloat(produto.preco) || 0;
            const margemLucroML = parseFloat(produto.margem_lucro_projeto) || 0;
            const valor = preco + preco * (margemLucroML / 100);
            return valor.toFixed(2);
        },

        calcularValorFinalBalcaoProdutoExistente(produto) {
            const preco = parseFloat(produto.preco) || 0;
            const margemLucroBalcao = parseFloat(produto.margem_lucro_balcao) || 0;
            const valor = preco + preco * (margemLucroBalcao / 100);
            return valor.toFixed(2);
        },

        formatarDataProdutos(dataISO) {
            return formatarData(dataISO)
        },

        abrirLinhaAdicionarProduto() {
            this.adicionarProdutoAtivo = true;
        },

        async adicionarProduto() {
            if (this.produtoPreenchido) {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');
                try {
                    const response = await axios.post(`precificacao/itens-perfil-kg/`, {
                        tabela_preco_perfil: this.dadosPerfil.id,
                        margem_lucro_projeto: this.novoProduto.margem_lucro_projeto,
                        margem_lucro_balcao: this.novoProduto.margem_lucro_balcao,
                        preco: this.novoProduto.preco,
                        valor_final_venda_projeto: this.calcularValorFinal(this.novoProduto),
                        valor_final_venda_balcao: this.calcularValorFinalBalcao(this.novoProduto)
                    }, {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    });

                    this.buscarPerfilPorKG()

                    this.novoProduto = {
                        preco: 0,
                        margem_lucro_projeto: 0,
                        margem_lucro_balcao: 0
                    }

                    this.sucesso = true
                    setTimeout(() => {
                        this.sucesso = false;
                    }, 3000);
                    this.adicionarProdutoAtivo = false;
                } catch (error) {
                    console.error("Erro ao adicionar produto:", error);
                }
            }
        },
    },
    computed: {
        produtoPreenchido() {
            return (
                this.novoProduto.preco > 0 &&
                this.novoProduto.margem_lucro_projeto > 0 &&
                this.novoProduto.margem_lucro_balcao > 0
            );
        }
    },
}
</script>

<style scoped>
.input-form {
    border: 1px solid #ccc;
    padding: 8px;
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 8px;
    text-align: left;
}

thead th {
    background-color: #f8f9fa;
}

tr:hover {
    background-color: #f1f1f1;
}

.overflow-x-auto {
    overflow-x: auto;
}
</style>