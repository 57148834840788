<template>
  
  <div v-if="abrir" class="container-modal-editar">
    <MensagemConfirmacaoExclusao
      mensagem="Deseja mesmo excluir os itens selecionados?"
      :abrir="modal_confirmacao_opcao_variavel"
      @feharModal="feharModalConfirmacao"
      @confirmarExclusao="excluirSelecionados"
    />
    <preview-image 
      v-if="preview_imagem" 
      @closed="closed_preview_image" 
      :imagem="preview_imagem"
    />

    <Galeria 
      v-if="galeria === true"
      @fechar_galeria="abrir_galeria"
      @objetoClicado="atualizarFileInput"
    />
    <div class="sombra-modal">
      <div class="conteudo-modal-editar">
        <div class="flex justify-end">
          <button @click.prevent="fechar()">
            <i class="fas fa-close text-2xl text-corBase"></i> 
          </button>
        </div>

        <div>
          <div class="cabecalho-formulario">
            <h1 class="text-xl text-corBase font-bold">Lista de Valores para a Variável</h1>
          </div>

          <div class="container-modelo-tabela">
            <table class="container-tabela overflow-auto">
              <thead class="cabecalho-tabela text-center">
                <tr>
                  <th scope="col" class="px-3 py-3">
                    #
                  </th>
                  <th scope="col" class="p-4">
                      <div class="flex items-center">
                        <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                        <label class="sr-only">checkbox</label>
                      </div>
                  </th>
                  <th scope="col" class="px-3 py-3">
                    Opções
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Variável
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Nome
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Valor
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Descrição
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Imagem
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Condição
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  v-for="(opcao, index) in opcoesFiltradas" 
                  :key="index" 
                  class="escopo-tabela text-center"
                >
                  <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                    {{ index + 1 }}
                  </th>
                  <td class="w-4 p-4">
                    <div class="selec">
                      <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox-opcao" v-model="opcao.selected">
                      <label class="sr-only">checkbox</label>
                    </div>
                  </td>
                  <td class="flex py-2 gap-6 justify-center items-center">
                    <button @click.prevent="opcao.edit = true">
                      <i class="fa-solid fa-pen-to-square"></i>
                    </button>
                  </td>
                  <td class="px-6 py-2 text-xs">
                    {{ opcao.variavel.variavel }}
                  </td>
                  <td class="px-6 py-2 text-xs">
                    {{ opcao.variavel.descricao }}
                  </td>
                  <td class="px-6 py-2 text-xs" v-if="!opcao.edit">
                    {{ opcao.valor }}
                  </td>
                  <td class="py-2 text-xs" v-else>
                    <input 
                      type="text" 
                      class="w-full p-1 text-corBase border border-corBase/40" 
                      v-model="opcao.valor"
                    >
                  </td>
                  <td class="px-6 py-2 text-xs" v-if="!opcao.edit">
                    {{ opcao.descricao }}
                  </td>
                  <td class="py-2 text-xs" v-else>
                    <input 
                      type="text" 
                      class="w-full p-1 text-corBase border border-corBase/40" 
                      v-model="opcao.descricao"
                    >
                  </td>
                  <td class="px-6 py-2 text-xs">
                    <div class="flex justify-center text-center gap-6">
                      <!-- <button 
                        @click.prevent="abrir_galeria"
                        v-if="opcao.edit"
                      >
                        <i class="fa-solid fa-image fa-xl"></i>
                        Escolha uma Imagem
                      </button> -->

                      <img 
                        v-if="opcao.imagem"
                        :src="opcao.imagem.url_documento"
                        class="w-10 cursor-pointer"
                        @click="openPreviewImage(opcao.imagem.url_documento)"
                      >
          
                      <img 
                        v-else
                        src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                        class="w-10"
                      >
                    </div>
                  </td>
                  <td class="px-6 py-2 text-xs">
                    {{ opcao.condicao }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="container-tabela overflow-auto" v-if="novasOpcoes.length">
              <thead class="cabecalho-tabela text-center">
                <tr>
                  <th scope="col" class="px-6 py-3">
                    Variável
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Nome
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Valor
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Descrição
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Imagem
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Condição
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(opcao, index) in novasOpcoes" :key="index" class="escopo-tabela text-center">
                  <td class="px-6 py-2 text-xs">
                    {{ variavel.variavel }}
                  </td>
                  <td class="px-6 py-2 text-xs">
                    {{ variavel.descricao }}
                  </td>
                  <td class="px-6 py-2 text-xs">
                    <input 
                      type="text" 
                      class="w-full p-1 text-corBase border border-corBase/40"
                      v-model="opcao.valor"
                    >
                  </td>
                  <td class="px-6 py-2 text-xs">
                    <input 
                      type="text" 
                      class="w-full p-1 text-corBase border border-corBase/40"
                      v-model="opcao.descricao"
                    >
                  </td>
                  <td class="px-6 py-2 text-xs">
                    <button @click.prevent="abrir_galeria">
                      <i class="fa-solid fa-image fa-xl"></i>
                      Escolha uma Imagem
                    </button>
                  </td>
                  <td class="px-6 py-2 text-xs">
                    <input 
                      type="text" 
                      class="w-full p-1 text-corBase border border-corBase/40"
                      v-model="opcao.condicao"
                    >
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="flex w-full justify-end itens-center mt-6 px-4 mb-5" v-if="novasOpcoes.length">
              <button 
                class="btn-cancelar" 
                @click.prevent="() => novasOpcoes = []"
              >
                Cancelar
              </button>
              <button 
                class="btn-salvar" 
                @click.prevent="postNovaOpcao">
                  Adicionar
              </button>
            </div>

            <div class="flex justify-center" v-if="!novasOpcoes.length">
              <button class="text-ativo" @click.prevent="adicionarOpcao">
                <i class="fas fa-plus-circle"></i>
              </button>
            </div>

          </div>
          <div class="flex justify-end mt-5">
            <button
              class="btn-cancelar" 
              @click.prevent="modal_confirmacao_opcao_variavel = true"
              v-if="checkSome || checkAll"
            >
              Excluir Selecionados
            </button>
            <button class="btn-cancelar" @click.prevent="() => $emit('getListaOpcoesVariavel')">Cancelar</button>
            <button class="btn-salvar" @click.prevent="() => $emit('atualizarOpcoesVariavel', opcoesVariavel)">Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </div> 
</template>

<script>
import axios from 'axios'
import Galeria from '../ged/Galeria.vue';
import PreviewImage from '@/components/midais/ged/PreviewImage.vue'
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue';

export default{
  name: 'ModalOpcoesVariaveis',

  data() {
    return {
      novasOpcoes: [],
      galeria: false,
      previewImage: {},
      preview_imagem: '',

      checkAll: false,
      modal_confirmacao_opcao_variavel: false,
    }
  },

  props: ['abrir', 'variavel', 'opcoesVariavel'],

  components: {
    Galeria,
    PreviewImage,
    MensagemConfirmacaoExclusao
  },

  computed: {
    opcoesFiltradas() {
      return this.opcoesVariavel.filter(opcao => opcao.variavel.id === this.variavel.id);
    },

    checkSome() {
      return this.opcoesVariavel.some(opcao => opcao.selected)
    },
  },

  methods: {
    atualizarOpcoesVariavel(opcoesVariavelAAtualizar) {
      this.$emit('atualizarOpcoesVariavel', opcoesVariavelAAtualizar)
    },

    feharModalConfirmacao() {
      this.modal_confirmacao_opcao_variavel = false
    },

    async deletarOpcaoVariavel(opcaoVariavelADeletar) {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')

        const opcaoVariaveisADeletarPromisses = []

        for(const opcaoVariavel of opcaoVariavelADeletar){

          opcaoVariaveisADeletarPromisses.push(
            axios.delete(`projeto/opcao-variavel-local/${opcaoVariavel.id}/`, {
              headers: {
                Authorization: `Token ${token}`
              }
            })
          )
        }

        await Promise.all(opcaoVariaveisADeletarPromisses)

      }catch(e){
        console.error(e)
      }
    },

    async excluirSelecionados() {
      if(this.checkAll) {
        await this.deletarOpcaoVariavel(this.opcoesFiltradas)
        this.modal_confirmacao_opcao_variavel = false
        this.checkAll = false
        this.$emit('getListaOpcoesVariavel')
        return
      }
      const opcoesVariavelADeletar = this.opcoesFiltradas.filter((opcao) => {
        if(opcao.selected) {
          return opcao
        }
      })

      await this.deletarOpcaoVariavel(opcoesVariavelADeletar)
      this.modal_confirmacao_opcao_variavel = false
      this.$emit('getListaOpcoesVariavel')
    },

    abrirModal(variavelSelecionada) {
      this.variavelAtual = variavelSelecionada
      this.modal_opcoes = true
    },

    forcarPrefixo(variavel) {
      const prefixo = this.valorPadrao;
      if (!variavel.variavel.startsWith(prefixo)) {
        variavel.variavel = prefixo + variavel.variavel.slice(prefixo.length);
      }
      // Converte o restante para maiúsculas
      variavel.variavel = variavel.variavel.toUpperCase();
    },


    selectAll() {
      const checkboxes = document.getElementsByClassName('checkbox-opcao');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    closed_preview_image() {
      this.preview_imagem = ''
    },

    openPreviewImage(url) {
      this.preview_imagem = url
    },

    atualizarFileInput(objeto) {
      this.previewImage = objeto;
    },

    abrir_galeria() {
      this.galeria = !this.galeria
    },

    fechar() {
      this.novasOpcoes = []
      this.checkAll = false
      this.opcoesFiltradas = 
        this.opcoesFiltradas.map(opcao => {
          opcao.selected = false
          return opcao
        })
      this.$emit("fechar")
    },

    adicionarOpcao() {
      this.novasOpcoes.push({
        variavel: this.variavel.id,
        valor: '',
        descricao: '',
        imagem: this.previewImage.id,
        condicao: ''
      })
    },

    async postNovaOpcao() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')

        const novasOpcoesPromisses = []

        for(const novaOpcao of this.novasOpcoes) {
          novaOpcao.imagem = this.previewImage.id

          novasOpcoesPromisses.push(
            axios.post('projeto/opcao-variavel-local/', novaOpcao, {
              headers: {
                Authorization: `Token ${token}`
              }
            })
          )
        }

        await Promise.all(novasOpcoesPromisses)

        this.novasOpcoes = []
        this.$emit('getListaOpcoesVariavel')

      } catch (error) {
        console.error(error)
      }
    },
  }
} 

</script>

<style scoped lang="scss">

</style>
