<template>

  <div class="Tela">

    <div class="LogoCentral">
      <img src="../assets/imagens/logo.png" alt="" class="Logo">
      <img src="../assets/imagens/tipografia.png" alt="" class="NomeLogo">
    </div>

    <div class="LadoEsquerdo">


      <div class="Conteudo">


        <div class="DivLogo">
          <img src="../assets/imagens/logo.png" alt="" class="Logo">
          <img src="../assets/imagens/tipografia.png" alt="" class="NomeLogo">
        </div>


        <div class="Descricao">
          <h2>Simplifique a gestão da sua produção com o nosso sistema.</h2>
          <h3>Aumente a produtividade com nosso sistema de gerenciamento.</h3>
        </div>


      </div>


      <div class="Fundo">
        <div class="bg-black/40 absolute z-10 w-full h-[100vh]"></div>
        <img src="../assets/imagens/esquadria.jpg" class="imgFundo">

      </div>


    </div>

    <!--Tela Lado Direito-->

    <div class="LadoDireito">

      <div class="Login">
        <h2>Login</h2>
      </div>

      <form @submit.prevent="submitForm">

        <input type="email" name="" placeholder="Email" class="Inp" v-model.trim="email">

        <div class="relative">
          <input :type="senhaVisivel ? 'text' : 'password'" placeholder="Senha" class="Inp" v-model.trim="senha">
          <div class="absolute right-3 top-[12px] cursor-pointer" @click="toggleSenhaVisivel">
            <i :class="senhaVisivel ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'"></i>
          </div>
        </div>


        <input type="text" name="" placeholder="Minha empresa" class="Inp" v-model.trim="minhaempresa">

        <div class="AlertaSenha">
          <a href="#">Esqueceu a senha?</a>
        </div>

        <button class="btn" type="submit">Entrar</button>

      </form>

      <div class="termos">
        <span class="cadastre-se">
          Não possui uma conta?
          <router-link to="/cadastro" class="underline">Cadastre-se na ManagerGlass</router-link>
        </span>
        <p class="mt-4">Ao se inscrever, você concorda com os nossos Termos os e Politicas de segurança</p>
      </div>

    </div>
    <redirecionado-sucesso v-if="redirecionadoComSucesso == true" />
    <sucesso-login v-if="sucessoLogin == true" />
    <erro-login v-if="erroLogin == true" />
    <erro-login-schema v-if="erroLoginSchema == true" />
  </div>


</template>

<script>
import axios from "axios";
import { mapActions } from 'vuex';
import RedirecionadoSucesso from "@/components/toasts/toast_sucesso/RedirecionadoSucesso";
import SucessoLogin from "@/components/toasts/toast_sucesso/SucessoLogin";
import ErroLogin from "@/components/toasts/toast_erro/ErroLogin";
import ErroLoginSchema from "@/components/toasts/toast_erro/ErroLoginSchema";

export default {
  name: 'Login',
  components: {
    RedirecionadoSucesso,
    SucessoLogin,
    ErroLogin,
    ErroLoginSchema
  },
  data() {
    return {

      // variaveis do formulario
      email: '',
      senha: '',
      senhaVisivel: false,
      minhaempresa: '',

      // variavel que armazena as respostas de erros
      errors: [],

      // variaveis que manipulam o estado dos taosts
      redirecionadoComSucesso: false,
      sucessoLogin: false,
      erroLogin: false,
      erroLoginSchema: false

    };
  },

  beforeCreate() {
    // remove o token do localStore
    localStorage.removeItem('token');
    axios.defaults.headers.common['Authorization'] = "";
  },

  created() {
    // adiciona valor à variável minhaempresa caso esteja em uma URL com subdomínio
    const hostOrigin = window.location.origin;
    const baseAppUrl = this.$store.state.baseAppUrl;

    if (hostOrigin === `${this.$store.state.baseProtocol}www.${baseAppUrl}`) {
      this.minhaempresa = "";
    } else {
      const subdomain = window.location.hostname.split('.')[0];
      if (subdomain !== "www") {
        this.minhaempresa = subdomain;
      } else {
        // Se o subdomínio for "www", remove o "www" do início
        this.minhaempresa = window.location.hostname.replace("www.", "");
      }
    }
  },

  methods: {

    // Mudar visibilidade da senha
    toggleSenhaVisivel() {
        this.senhaVisivel = !this.senhaVisivel;
    },

    // verifica se o campo minhaempresa esta vazio
    // se estiver vazio faz a requisição no banco publico
    // se estiver com valor faz a requisição no banco com o subdominio do valor
    async submitForm() {
      this.$store.dispatch("setLoading", true);
      if (this.minhaempresa === '') {
        await this.submitFormManagerGlass();
      } else {
        await this.submitFormDefault();
      }
      this.$store.dispatch("setLoading", false);
    },

    // requisição de login no banco publico
    ...mapActions(['updateIsAdminUser']),
    async submitFormManagerGlass() {
      // constante que monta o objeto a ser passado para a requisição
      const formData = {
        email: this.email,
        password: this.senha
      };

      // variavel que delimita a api padrao para login no banco publico
      await this.$store.dispatch('fetchURLrequest')

      // Log para verificar a URL da requisição
      console.log('URL da requisição (pública):', '/token/login');

      try {
        // requisição de login no banco publico para retornar o token de autenticação
        const response = await axios.post('/token/login', formData);
        const token = response.data.auth_token;

        this.$store.dispatch('setAuthTokenCookie', token)
        this.$store.commit('setIsAdminUser', true)

        // adiciona o token no localStorage
        this.$store.commit('setToken', token);
        axios.defaults.headers.common['Authorization'] = 'Token ' + token;
        localStorage.setItem('token', token);

        // exibe o toasts de sucesso pro login e redireciona para a pagina de dashboard
        this.sucessoLogin = true
        await this.delay(2000)
        this.$router.push({
          path: `/dashboard`
        })
        this.sucessoLogin = false
        this.updateIsAdminUser(true)
      } catch (error) {
        if (error.response) {
          // salva o retorno do erro e exibe um toasts de error de login ou senha incorretas
          for (const property in error.response.data) {
            this.errors.push(`${property}: ${error.response.data[property]}`);

            this.erroLogin = true
            await this.delay(4000)
            this.erroLogin = false
          }
        } else if (error.message) {
          this.errors.push('Something went wrong. Please try again!');
        }
      }
    },

    async submitFormDefault() {
      // Monta o objeto que será passado na requisição do banco do schema
      const formData = {
        email: this.email,
        password: this.senha
      };

      // Variável que monta a URL da API com o subdomínio
      await this.$store.dispatch('fetchURLrequest')

      this.$store.commit('setMinhaEmpresa', this.minhaempresa);
      localStorage.setItem('minhaEmpresa', this.minhaempresa)

      try {
        // Requisição no banco do schema para obter o token de autenticação
        const response = await axios.post(`/token/login`, formData);
        const token = response.data.auth_token;
        console.log(response.data)
        this.$store.dispatch('setAuthTokenCookie', token)
        this.$store.commit('setIsAdminUser', false)

        // Adiciona o token no localStorage
        this.$store.commit('setToken', token);
        axios.defaults.headers.common['Authorization'] = 'Token ' + token;
        localStorage.setItem('token', token);

        // Requisição na API que retorna os schemas para verificar se o schema existe no banco
        axios.get(`/schemas/`)
          .then(async response => {
            // Variável que salva o retorno da requisição
            const schemas = response.data.schemas;

            // Verifica se o schema de retorno inclui o nome da empresa
            // Se existir no banco e estiver na URL sem o subdomínio, é redirecionado para a nova URL
            // Se já estiver na URL com o subdomínio, é redirecionado para o dashboard
            await this.delay(2000)
            if (schemas.includes(this.minhaempresa)) {
              if (window.location.origin === 'www.managerglass.com.br') {
                this.redirecionadoComSucesso = true;
                await this.delay(2000);
                this.$router.push({
                  path: `/login`
                });
                this.redirecionadoComSucesso = false;
              } else {
                this.sucessoLogin = true;
                await this.delay(2000);
                this.$router.push({
                  path: `/dashboard`
                })
                this.sucessoLogin = false;
              }
            }
          })
          .catch(async error => {
            // Se o valor da variável minhaempresa não existir no retorno dos schemas
            // É retornado um toast de erro informando que a empresa não está cadastrada
            this.erroLoginSchema = true;
            await this.delay(4000);
            console.log(error);
            this.erroLoginSchema = false;
          });
      } catch (error) {
        // Verifica se a mensagem de erro é do schema ou do login/senha e retorna um toast de erro
        if (error.code === 'ERR_BAD_REQUEST') {
          this.erroLogin = true;
          await this.delay(4000);
          this.erroLogin = false;
        } else if (error.code === 'ERR_NETWORK') {
          this.erroLoginSchema = true;
          await this.delay(4000);
          this.erroLoginSchema = false;
        } else {
          console.log('erro desconhecido');
        }
      }
    },

    // metodo para adicionar tempo de espera
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
  },
};

</script>


<style lang="scss">
/*Tela toda*/
.Tela {

  @apply bg-BrancoSistema h-screen flex justify-center items-center sm:grid sm:grid-cols-2
}

.LogoCentral {
  @apply flex items-center absolute mx-auto top-10 block sm:hidden
}

.LogoCentral>.Logo {
  @apply h-8 mr-4
}

.LogoCentral>.NomeLogo {
  @apply h-4
}

/*Lado Esquerdo da Tela*/
.LadoEsquerdo {

  @apply h-screen relative overflow-hidden hidden sm:block
}


.Fundo {

  @apply absolute w-[200vh] h-[200vh] rounded-full right-[0%] top-[-50vh] overflow-hidden flex items-center justify-center
}

.imgFundo {

  @apply max-w-full max-h-full object-contain transform translate-x-56 translate-y-8
}


/*Conteudo lado esquerdo da tela*/
.Conteudo {

  @apply absolute z-20 pl-6
}

/*Div Logo*/
.DivLogo {

  @apply flex items-center pt-6
}


.DivLogo>.Logo {

  @apply mr-4 h-6 md:h-8 lg:h-11
}


.DivLogo>.NomeLogo {

  @apply h-3 md:h-4 lg:h-6
}

/*Div Descrição*/
.Descricao {

  @apply text-BrancoSistema flex flex-col justify-center pr-4 max-w-[600px] sm:h-[calc(100vh-48px)] md:h-[calc(100vh-56px)] lg:h-[calc(100vh-68px)]
}

.Descricao>h2 {

  @apply font-bold mb-4 text-xl lg:text-3xl
}

.Descricao>h3 {

  @apply text-sm lg:text-xl
}


/*Lado Direito da Tela*/
.LadoDireito {
  @apply flex justify-center items-center flex-col
}

.Login {
  @apply text-AzulSistema text-2xl font-bold mb-12 w-72 lg:w-96
}

.Inp {
  @apply block rounded-md shadow-md shadow-AzulSistema/10 bg-transparent border-2 border-AzulSistema/10 mb-5 w-72 h-10 pl-2 lg:w-96 lg:h-12 lg:pl-4 outline-none focus:border-b-corBase/60 focus:border-2 focus:transition focus:duration-300
}

.AlertaSenha {
  @apply text-AzulSistema flex justify-between
}

.AlertaSenha>div {
  @apply flex
}

.btn {
  @apply bg-AzulSistema text-BrancoSistema rounded-md w-24 h-11 mr-4 mt-5 lg:w-32 lg:h-11 lg:text-xl hover:bg-screenCor hover:text-corBase hover:border-2 hover:border-corBase hover:transition hover:duration-700
}

.btn[type="button"] {
  @apply text-AzulSistema bg-transparent border-2 border-AzulSistema/10
}

.termos {
  @apply text-AzulSistema text-xs mt-24 w-72 lg:w-96
}
</style>