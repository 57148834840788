<template>
  <div>
    <div class="container-modal-editar">
      <div class="sombra-modal">
        <div class="conteudo-modal-editar">
          <form class="form-editar text-white">
            <div class="flex justify-end">
              <button @click.prevent="$emit('fecharModal')">
                <i class="fas fa-close text-2xl text-corBase"></i>
              </button>
            </div>
            <h2 class="text-lg text-corBase font-bold mb-2">Editar Dados Chapa</h2>
            <!--Campos da Pessoa-->
            <div>
              <div class="container-campos">
                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Produto Base:</label>
                  <input type="text" required class="input-campos" v-model="chapa.chapa_base.nome_especifico">
                </div>

                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Fornecedor:</label>
                  <input type="text" required class="input-campos" v-model="chapa.chapa_base.fornecedor.pessoa.nome_razao" readonly>
                </div>

                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Código Fornecedor:</label>
                  <input type="text" required class="input-campos" v-model="chapa.chapa_base.fornecedor.codigo_fornecedor">
                </div>

              </div>

              <div class="container-campos">
                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Nome Especifico:</label>
                  <input type="text" required class="input-campos" v-model="chapa.nome_especifico">
                </div>
                <div class="campos-mobile">
                  <label class="label-campos">Grupo de Aplicação Chapa:</label>
                  <input type="text" required class="input-campos" v-model="chapa.grupo_aplicacao_chapa.nome">
                </div>
                <div class="flex flex-col mt-2 w-full md:w-60">
                  <label class="label-campos">Cor Chapa:</label>
                  <input type="text" required class="input-campos" v-model="chapa.cor_chapas.nome">
                </div>
              </div>
            </div>
            <!--Campos de endereço-->
            <div>

              <div class="container-campos">
                <div class="flex flex-col mt-2 w-full md:w-60">
                  <label class="label-campos">Altura:</label>
                  <input type="text" required placeholder="" class="input-campos" v-model="chapa.altura_chapa">
                </div>

                <div class="campos campos-mobile">
                  <label class="label-campos">Largura:</label>
                  <input type="text" required class="input-campos" placeholder="" v-model="chapa.largura_chapa">
                </div>

                <div class="flex flex-col mt-2 w-full md:w-[500px]">
                  <label class="label-campos">Tipo Chapa:</label>
                  <div class="">
                    <input type="text" required class="input-campos" placeholder="" v-model="chapa.tipo_chapas.nome">
                  </div>
                </div>
              </div>


              <div class=" container-campos ">
                <div class="campos-mobile">

                </div>
              </div>

            </div>

            <!--Botão-->
            <div class="btn-formulario-modal">
              <button class="btn-formulario-salvar" @click.prevent="putDadosEditado">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EditarChapas",
  data() {
    return {
      statusModal: false,
      chapa: [],
      produtoBase: {},
      fonecedorChapa: {},
      grupoaplicacao: {},
      corchapas: {},
      tipochapa: {}

    }
  },
  props: ["dadosChapa"],
  emits: ["fecharModal"],
  created() {
    // this.getProdutoBase()
    // this.getFornecedor()
    // this.getGrupoAplicacao()
    // this.getCorChapa()
    // this.getTipoChapa()
    this.getDadosChapa()
  },
  methods: {
    async getDadosChapa() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')
      try {
        const response = await axios.get(`produtos/chapa/${this.dadosChapa}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        console.log(response.data)
        this.chapa = response.data
      } catch (error) {
        console.error(error)
      }
    },

    async putDadosEditado() {
      const dadosAtualizados = new FormData();
      dadosAtualizados.append('ativo', this.chapa.ativo)
      dadosAtualizados.append('chapa_base', this.chapa.chapa_base.id)
      dadosAtualizados.append('nome_especifico', this.chapa.nome_especifico)
      dadosAtualizados.append('grupo_aplicacao_chapa', this.chapa.grupo_aplicacao_chapa.id)
      dadosAtualizados.append('cor_chapas', this.chapa.cor_chapas.id)
      dadosAtualizados.append('altura_chapa', this.chapa.altura_chapa)
      dadosAtualizados.append('largura_chapa', this.chapa.largura_chapa)
      dadosAtualizados.append('tipo_chapas', this.chapa.tipo_chapas.id)


      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.put(`produtos/chapa/${this.dadosChapa}/`, dadosAtualizados, {
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          }
        });
        console.log(response);
        this.sucesso = true;
        setTimeout(() => {
          this.sucesso = false;
          this.$emit('fecharModal')
        }, 3000);;

      } catch (error) {
        console.log(error)
      }
    },

    async getProdutoBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/produto/?page_size=9999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.produtoBase = response.data
          console.log(this.produtoBase)
        })
        .catch(error => {
          console.log(error)
        })

    },

    async getFornecedor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`pessoas/fornecedor/?page_size=9999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.fornecedorChapa = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getGrupoAplicacao() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/grupo-aplicacao-chapa/?page_size=9999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.grupoaplicacao = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getCorChapa() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/cor-chapa/?page_size=9999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.corchapas = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getTipoChapa() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/tipo-chapa/?page_size=9999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.tipochapa = response.data
        })
        .catch(error => {
          console.log(error)
        })
    }

  }
}
</script>

<style>
.container-modal-editar {
  @apply fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full
}

.conteudo-modal-editar {
  @apply bg-screenCor p-6 w-[75%] shadow-xl shadow-screenCor/30 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[80vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-screenCor/40
}

.form-editar {
  @apply text-corBase
}
</style>