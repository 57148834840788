<template>
    <div class="container-formulario">
        <section>
            <!-- Cabeçalho com botão de retorno -->
            <div class="container-cabecalho flex justify-between mb-4">
                <span class="font-bold text-corBase text-xl mb-4">Editar {{ obra.descricao }}</span>
                <router-link to="/obras" class="text-xl text-corBase font-bold">
                    <i class="fas fa-chevron-left"></i>
                </router-link>
            </div>

            <form @submit.prevent="salvarObra">
                <div class="space-y-4">
                    <!-- Seção 1: Dados da Obra -->
                    <div>
                        <button @click.prevent="toggleSection(1)"
                            class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Obra
                        </button>
                        <div v-if="activeSection === 1"
                            class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <Obra :obra="obra" />
                        </div>
                    </div>

                    <!-- Seção 2: Endereço -->
                    <div>
                        <button @click.prevent="toggleSection(2)"
                            class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Endereço
                        </button>
                        <div v-if="activeSection === 2"
                            class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <Endereco :obra="obra" />
                        </div>
                    </div>

                    <!-- Seção 3: Stakeholders -->
                    <div>
                        <button @click.prevent="toggleSection(3)"
                            class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Stakeholders
                        </button>
                        <div v-if="activeSection === 3"
                            class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <ListarStakeholders :obra="obra" @atualizarStakeholders="atualizarStakeholders"
                                @atualizarStakeholdersObrigatorios="atualizarStakeholdersObrigatorios" />
                        </div>
                    </div>

                    <!-- Seção 4: Documentos -->
                    <div>
                        <button @click.prevent="toggleSection(4)"
                            class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Documentos
                        </button>
                        <div v-if="activeSection === 4"
                            class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <Documentos :obra="obra" @atualizarLista="buscarDocumentosObra()"/>
                        </div>
                    </div>

                    <!-- Seção 5: Financeiro -->
                    <div v-if="isadmin === true">
                        <button @click.prevent="toggleSection(5)"
                            class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Financeiro
                        </button>
                        <div v-if="activeSection === 5"
                            class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <Financeiro :obra="obra" />
                        </div>
                    </div>
                </div>

                <!-- Botões para salvar ou cancelar -->
                <div class="flex w-full justify-end itens-center mt-6">
                    <button class="btn-cancelar" @click.prevent="$router.push('/obras')">Cancelar</button>
                    <button class="btn-salvar" @click.prevent="salvarObra">Salvar</button>
                </div>
            </form>
        </section>

        <SucessoRapido v-if="sucesso === true" :mensagem="sucessoMessage" />
        <ErroRapido v-if="erro === true" :mensagem="erroMessage" />
    </div>
</template>

<script>
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import Obra from './EtapasCriacaoObra/Obra.vue';
import Endereco from './EtapasCriacaoObra/Endereco.vue';
import ListarStakeholders from './EtapasCriacaoObra/ListarStakeholders.vue';
import Documentos from './EtapasCriacaoObra/Documentos.vue';
import Financeiro from './EtapasCriacaoObra/Financeiro.vue';
import axios from 'axios';

export default {
    name: 'EditarObra',
    components: {
        SucessoRapido,
        ErroRapido,
        Obra,
        Endereco,
        ListarStakeholders,
        Documentos,
        Financeiro,
    },
    data() {
        return {
            sucesso: false,
            erro: false,
            erroMessage: '',
            sucessoMessage: '',
            activeSection: 1,
            obra: {
                descricao: '',
                imagem: {},
                endereco: '',
                numero: '',
                apartamento: '',
                bloco: '',
                complemento: '',
                bairro: '',
                cidade: '',
                estado: '',
                cep: '',
                localizacao: '',
                status_obra: '',
                comissao: 0,
                valor_rt: 0,
                cliente: {},
                patrocinador: {},
                responsavel: {},
                stakeholders: [],
                arquivos: [],
            },
            obraId: '',
            countArquivos: '',
        };
    },
    computed: {
        isadmin() {
            return this.$store.state.isAdminUser;
        },
    },
    created() {
        this.obraId = this.$route.params.obra;
        this.buscarDadosObra();
    },
    methods: {
        toggleSection(sectionNumber) {
            this.activeSection = this.activeSection === sectionNumber ? null : sectionNumber;
        },

        async buscarDadosObra() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            this.$store.dispatch("setLoading", true);
            try {
                const response = await axios.get(`/obra/obra/${this.obraId}`, {
                    headers: { Authorization: `Token ${token}` },
                });
                this.obra = response.data;
                await this.buscarPessoasAlocadasObra();
                await this.buscarDocumentosObra();
            } catch (error) {
                console.error(error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },
        
        async buscarPessoasAlocadasObra() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            this.$store.dispatch("setLoading", true);
            try {
                const response = await axios.get(`/obra/pessoa-alocada/?obra=${this.obraId}`, {
                    headers: { Authorization: `Token ${token}` },
                });
                this.obra.stakeholders = response.data.results;
            } catch (error) {
                console.error(error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async buscarDocumentosObra() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            this.$store.dispatch("setLoading", true);
            try {
                const response = await axios.get(`/obra/documentos-obra/?obra=${this.obraId}`, {
                    headers: { Authorization: `Token ${token}` },
                });
                this.obra.arquivos = response.data.results;
                this.countArquivos = response.data.count
            } catch (error) {
                console.error(error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },


        atualizarStakeholders(stakeholdersAtualizados) {
            this.obra.stakeholders = stakeholdersAtualizados;
        },

        atualizarStakeholdersObrigatorios(stakeholdersObrigatorios) {
            this.obra.cliente = stakeholdersObrigatorios.cliente;
            this.obra.patrocinador = stakeholdersObrigatorios.patrocinador;
            this.obra.responsavel = stakeholdersObrigatorios.responsavel;
        },

        async salvarObra() {
            const formDataObra = new FormData();
            formDataObra.append('descricao', this.obra.descricao);
            formDataObra.append('ativo', 'true');
            if (this.obra.imagem) {
                formDataObra.append('imagem', this.obra.imagem.id);
            }
            formDataObra.append('endereco', this.obra.endereco);
            formDataObra.append('numero', this.obra.numero);
            formDataObra.append('apartamento', this.obra.apartamento);
            formDataObra.append('bloco', this.obra.bloco);
            formDataObra.append('complemento', this.obra.complemento);
            formDataObra.append('bairro', this.obra.bairro);
            formDataObra.append('cidade', this.obra.cidade);
            formDataObra.append('estado', this.obra.estado);
            formDataObra.append('cep', this.obra.cep);
            formDataObra.append('localizacao', this.obra.localizacao);
            formDataObra.append('status_obra', this.obra.status_obra);
            formDataObra.append('comissao', this.obra.comissao);
            formDataObra.append('valor_rt', this.obra.valor_rt);
            formDataObra.append('cliente', this.obra.cliente.id);
            formDataObra.append('patrocinador', this.obra.patrocinador.id);
            formDataObra.append('responsavel', this.obra.responsavel.id);

            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            this.$store.dispatch("setLoading", true);
            try {
                await axios.patch(`obra/obra/${this.obraId}/`, formDataObra, {
                    headers: { Authorization: `Token ${token}` },
                });

                if (this.obra.stakeholders.length >= 4) {
                    this.salvarPessoasAlocadas(this.obraId);
                }
                if (this.obra.arquivos.length > this.countArquivos) {
                    this.salvarArquivos(this.obraId);
                }
                this.sucesso = true;
                this.sucessoMessage = 'Obra editada com sucesso.';
                setTimeout(() => {
                    this.sucesso = false;
                    this.sucessoMessage = '';
                    this.$router.push('/obras');
                }, 3000);
            } catch (error) {
                console.error('Erro ao editar a obra:', error);
                this.erro = true;
                this.erroMessage = 'Ocorreu um erro ao editar a Obra.';
                setTimeout(() => {
                    this.erro = false;
                    this.erroMessage = '';
                }, 3000);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async salvarPessoasAlocadas(id_obra) {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            const stakeholders = this.obra.stakeholders.filter(
                stakeholder => !['Cliente', 'Patrocinador', 'Responsável'].includes(stakeholder.funcao)
            );
            try {
                for (const stakeholder of stakeholders) {
                    if (!stakeholder.id) {
                        await axios.post(`obra/pessoa-alocada/`, {
                            obra: id_obra,
                            pessoa: stakeholder.pessoa.id,
                            responsabilidade: stakeholder.funcao.id,
                        }, {
                            headers: { Authorization: `Token ${token}` },
                        });
                    } else {
                        await axios.patch(`obra/pessoa-alocada/${stakeholder.id}/`, {
                            obra: id_obra,
                            pessoa: stakeholder.pessoa.id,
                            responsabilidade: stakeholder.funcao,
                        }, {
                            headers: { Authorization: `Token ${token}` },
                        });
                    }
                }

                // this.sucesso = true;
                // this.sucessoMessage = 'Stakeholders alocados com sucesso.';
                setTimeout(() => {
                    // this.sucesso = false;
                    // this.sucessoMessage = '';
                    this.$router.push('/obras');
                }, 3000);
            } catch (error) {
                console.error('Erro ao salvar pessoas alocadas:', error);
                this.erro = true;
                this.erroMessage = 'Ocorreu um erro ao salvar as pessoas alocadas.';
                setTimeout(() => {
                    this.erro = false;
                    this.erroMessage = '';
                }, 3000);
            }
        },

        async salvarArquivos(id_obra){
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            const arquivos = this.obra.arquivos.filter((arquivo) => !arquivo.url_documento)
            // console.log(arquivos)
            try {
                for (const file of arquivos) {
                    
                    const formData = new FormData();
                    formData.append('obra', id_obra);
                    formData.append('ativo', 'True');
                    formData.append('descricao', `${file.name}`);
                    formData.append('url_documento', file);

                    const response = await axios.post(`obra/documentos-obra/`, formData, {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    });

                    console.log("Arquivo enviado:", response);
                }
            } catch(error){
                console.error(error)
            }
        }
    },
};
</script>

<style scoped>
input,
textarea,
select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
}

.form-group {
    margin-bottom: 15px;
}
</style>
