<template>
  <div class="relative w-full">
    <input
      type="text"
      v-model="searchQuery"
      @input="searchFunction(e)"
      class="input-campos w-full"
      :placeholder="defaultValue"
    />
    <ul v-if="results.length && searchQuery" class="absolute w-full bg-white border border-gray-300 max-h-40 overflow-auto z-10">
      <li
        v-for="result in results"
        :key="result.id"
        @click="selectResult(result)"
        class="cursor-pointer px-4 py-2 hover:bg-gray-200"
      >
        {{ result[labelKey] }}
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'Search2',
  props: {
    defaultValue: String ,
    labelKey: String,
    results: Array
  },
  data() {
    return {
      searchQuery: '',
    };
  },

  methods: {
    searchFunction() {
      this.$emit('searchFunction', this.searchQuery)
    },

    selectResult(result) {
      this.searchQuery = ''
      this.$emit('input', result[this.labelKey])
      this.$emit('selected', result); 
    }
  }
};
</script>

<style scoped>
.input-campos {
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 4px;
}

.input-campos::placeholder {
  color: #333; 
  opacity: 1; 
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
</style>
