<template>
  <div>
    <div class="container-modal-editar">
      <div class="sombra-modal">
        <div class="conteudo-modal-editar">
          <form class="form-editar text-white">
            <div class="flex justify-end">
              <button @click.prevent="$emit('fecharModal')">
                <i class="fas fa-close text-2xl text-corBase"></i>
              </button>
            </div>
            <h2 class="text-lg text-corBase font-bold mb-2">Editar Dados Uso Consumo</h2>
            <!--Campos da Pessoa-->
            <img :src="consumo.imagem.url_documento" alt="Imagem do Uso Consumo" class="w-32">
            <div>
              <div class="container-campos">
                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Produto Base:</label>
                  <input type="text" required class="input-campos" v-model="consumo.uso_consumo_base.nome_especifico">
                </div>

                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Fornecedor:</label>
                  <input type="text" required class="input-campos" v-model="consumo.uso_consumo_base.fornecedor.pessoa.nome_razao" readonly>
                </div>

                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Cor Uso Consumo:</label>
                  <input type="text" required class="input-campos" v-model="consumo.cor_uso_consumo.nome">
                </div>

              </div>

              <div class="container-campos">
                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Grupo de Aplicação:</label>
                  <input type="text" required class="input-campos" v-model="consumo.grupo_aplicacao_uso_consumo.nome">
                </div>
                <div class="campos-mobile">
                  <label class="label-campos">Tipo Uso Consumo:</label>
                  <input type="text" required class="input-campos" placeholder="" v-model="consumo.tipo_uso_consumo.nome">
                </div>
              </div>
            </div>

            <!--Botão-->
            <div class="btn-formulario-modal">
              <button class="btn-formulario-salvar" @click.prevent="putDadosEditado">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
      <SucessoRapido v-if="sucesso === true" mensagem="Uso Consumo atualizado com sucesso!" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";

export default {
  name: "EditarUsoConsumo",
  components: {
    SucessoRapido
  },
  data() {
    return {
      statusModal: false,
      consumo: [],
      sucesso: false,
      produtoBase: {},
      fonecedorChapa: {},
      grupoaplicacao: {},
      corchapas: {},
      tipochapa: {}

    }
  },
  props: ["dadosConsumo"],
  emits: ["fecharModal"],
  created() {
    // this.getProdutoBase()
    // this.getFornecedor()
    // this.getGrupoAplicacao()
    // this.getCorChapa()
    // this.getTipoChapa()
    this.getDadosConsumo()
  },
  methods: {
    async getDadosConsumo() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')
      try {
        const response = await axios.get(`produtos/uso-consumo/${this.dadosConsumo}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        console.log(response.data)
        this.consumo = response.data
      } catch(error){
        console.error(error)
      }
    },

    async putDadosEditado() {
      const dadosAtualizados = new FormData();
      dadosAtualizados.append('ativo', this.consumo.ativo)
      dadosAtualizados.append('uso_consumo_base', this.consumo.uso_consumo_base.id)
      // dadosAtualizados.append('uso_consumo_base.fornecedor', this.consumo.uso_consumo_base.fornecedor.id)
      dadosAtualizados.append('cor_uso_consumo', this.consumo.cor_uso_consumo.id)
      dadosAtualizados.append('grupo_aplicacao_uso_consumo', this.consumo.grupo_aplicacao_uso_consumo.id)
      dadosAtualizados.append('tipo_uso_consumo', this.consumo.tipo_uso_consumo.id)
      
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.patch(`produtos/uso-consumo/${this.dadosConsumo}/`, dadosAtualizados, {
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          }
        });
        console.log(response);
        this.sucesso = true;
        setTimeout(() => {
          this.sucesso = false;
          this.$emit('fecharModal')
        }, 3000);

      } catch (error) {
        console.log(error)
      }
    },

    async getProdutoBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/produto/${this.consumo.produto_base.id}/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.produtoBase = response.data
          console.log(this.produtoBase)
        })
        .catch(error => {
          console.log(error)
        })

    },

    async getFornecedor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`pessoas/fornecedor/${this.consumo.fornecedor.id}/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.fornecedorChapa = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getGrupoAplicacao() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/grupo-aplicacao-chapa/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.grupoaplicacao = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getCorChapa() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/cor-chapa/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.corchapas = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getTipoChapa() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/tipo-chapa/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.tipochapa = response.data
        })
        .catch(error => {
          console.log(error)
        })
    }

  }
}
</script>

<style></style>