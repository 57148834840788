<template>

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Linhas</h1>
      </div>

      <router-link to="/projetos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="flex gap-8 items-end mb-6 w-[80%]">
      <div class="w-full">
        <input type="text" class="input-form-chapa" v-model="linha" placeholder="Nome">
      </div>
      <div class="w-full">
        <input type="text" class="input-form-chapa" v-model="nome_projetista" placeholder="Sistemista">
      </div>
      <div class="w-full">
        <input type="text" class="input-form-chapa" v-model="bitola" placeholder="Bitola">
      </div>
      <div>
        <button class="btn-add-fisica" @click="getListaLinhasSistemistas(paginaAtual)">Pesquisar</button>
      </div>
      <div v-if="isadmin === true">
        <button @click="$router.push(`/linha-sistema/cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-1 rounded-sm px-4 mt-2 mr-2">Adicionar
        </button>
      </div>
    </div>


    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">#</th>
            <th class="px-6 text-start w-full">Nome</th>
            <th class="px-20 text-start">Bitola</th>
            <th class="px-20 text-start">Gestor</th>
            <th class="text-center px-10">Codigo Auxiliar</th>
            <th scope="col" class="text-center px-10">Status</th>
            <th v-if="isadmin === true" scope="col" class="px-10 text-start">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(linha_sistema, index) in lista_linha_sistema" :key="index" class="escopo-tabela">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                  v-model="linha_sistema.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-xs">{{ linha_sistema.linha }}</td>
            <td class="px-6 py-2 text-xs text-center">{{ linha_sistema.bitola }}</td>
            <td class="text-center py-2 text-xs w-full">{{ linha_sistema.projetistasistemistas.nome }}</td>
            <td class="px-6 py-2 text-xs text-center">{{ linha_sistema.codigo }}</td>

            <!-- Status -->
            <td>
              <div class="px-1 sm:px-6 py-1 sm:py-4 text-center">
                <i v-if="linha_sistema.ativo" class="fa-solid fa-circle-check text-ativo"></i>
                <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
              </div>
            </td>

            <!-- Ações -->
            <td class="px-6 py-2 text-xs" v-if="isadmin === true">
              <editar-linha-sistema :linhaSistema="linha_sistema" :paginaAtual="paginaAtual"></editar-linha-sistema>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination v-if="lista_linha_sistema.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";
import EditarLinhaSistema from "@/components/midais/Editar/projeto/EditarLinhaSistema";
import Pagination from "@/utils/Pagination.vue";

export default {
  name: "ListaLinhasSistemistas",

  components: {
    Select2,
    EditarLinhaSistema,
    Pagination
  },

  data() {
    return {
      lista_linha_sistema: [],
      linha_sistema: '',

      checkAll: false,
      // lista usada para salvar o retorno da requisição do get
      listaLinhasSistemistas: [],
      // lista usada para salvar esse retorno depois de dividido
      listaLinhasSistemistasPaginada: [],
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      countItens: 0,
      next_page: null,
      totalDePaginas: 0,
      itemsPorPagina: 5,

      // filtros
      nome_projetista: '',
      linha: '',
      bitola: '',

      error: [],
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },

    // paginasExibidas() {
    //   const paginas = [];
    //   const paginasVisiveis = 5; // Número de páginas visíveis de cada vez

    //   let inicio = Math.max(1, this.paginaAtual - Math.floor(paginasVisiveis / 2));
    //   let fim = inicio + paginasVisiveis - 1;

    //   if (inicio + paginasVisiveis > this.totalDePaginas) {
    //     inicio = Math.max(1, this.totalDePaginas - paginasVisiveis + 1);
    //     fim = this.totalDePaginas;
    //   }

    //   for (let i = inicio; i <= fim; i++) {
    //     paginas.push(i);
    //   }

    //   return paginas;
    // },
  },

  methods: {

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },
    async getListaLinhasSistemistas(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`/projeto/linha-sistema/?projetistasistemistas=${this.nome_projetista}&linha=${this.linha}&bitola=${this.bitola}&page=${pagina}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_linha_sistema = response.data.results
          this.countItens = response.data.count
        })
      // } else {
      //   await axios.get(`/projeto/linha-sistema/?projetistasistemistas=${this.nome_projetista}&linha=${this.linha}&bitola=${this.bitola}&page=${this.paginaAtual}`, {
      //     headers: {
      //       'Authorization': `Token ${token}`
      //     }
      //   })
      //       .then(response => {
      //         this.lista_linha_sistema = response.data.results
      //         console.log(this.lista_linha_sistema)
      //         this.totalDePaginas = response.data.count / 5
      //         console.log(this.totalDePaginas)
      //         this.paginaAtual += 1
      //       })
      // }

    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina
      this.getListaLinhasSistemistas(pagina)
    },


    proximaPagina() {
      this.getListaProdutos();
    },

    paginaAnterior() {
      this.paginaAtual -= 1
      this.getListaProdutos()
    },

    // async irParaPagina(pagina) {
    //   const token = await this.$store.dispatch('getAuthTokenCookie')
    //   console.log(pagina)
    //   this.paginaAtual = pagina
    //   await axios.get(`/projeto/linha-sistema/?projetistasistemistas=${this.nome_projetista}&linha=${this.linha}&bitola=${this.bitola}&page=${this.paginaAtual}`, {
    //       headers: {
    //         'Authorization': `Token ${token}`
    //       }
    //     })
    //         .then(response => {
    //           this.lista_linha_sistema = response.data.results
    //           console.log(this.lista_linha_sistema)
    //           this.totalDePaginas = response.data.count / 5
    //         })
    // }


  }
}
</script>

<style lang="scss"></style>