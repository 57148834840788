<template>
  <button @click.prevent="toggleSidebar" class="hidden md:block">
    <i v-if="!isExpanded" class="fas fa-chevron-right btn-abrir-menu"></i>
    <i v-if="isExpanded" class="fas fa-chevron-left btn-fechar-menu"></i>
  </button>
  <section class="container-sidebar" v-if="!isExpanded">
    <div class="sidebar">
      <nav class="conteudo-sidebar">
        <div class="container-opcoes-sidebar">
          <button @click.prevent="toggleSidebar">
            <span><i class="opcao-dropdown-curto fas fa-chart-line"></i></span>
          </button>
          <button @click.prevent="toggleSidebar">
            <span><i class="opcao-dropdown-curto fas fa-user-edit"></i></span>
          </button>
          <button @click.prevent="toggleSidebar">
            <span><i class="opcao-dropdown-curto fas fa-file-invoice-dollar"></i></span>
          </button>
          <button @click.prevent="toggleSidebar">
            <span><i class="opcao-dropdown-curto fas fa-project-diagram"></i></span>
          </button>
          <button @click.prevent="toggleSidebar">
            <span><i class="opcao-dropdown-curto fas fa-shopping-cart"></i></span>
          </button>
          <button @click.prevent="toggleSidebar">
            <span><i class="opcao-dropdown-curto fas fa-boxes"></i></span>
          </button>
          <button @click.prevent="toggleSidebar">
            <span><i class="opcao-dropdown-curto fa-solid fa-gear"></i></span>
          </button>
          <button @click.prevent="toggleSidebar">
            <span><i class="opcao-dropdown-curto fas fa-wrench"></i></span>
          </button>
        </div>
      </nav>
    </div>
  </section>


  <Transition>
    <section v-if="isExpanded" class="container-sidebar-aberta">
      <nav class="conteudo-sidebar-aberta">
        <div class="container-opcoes-sidebar-aberta">
          <button class="btn-sidebar-aberta" :class="{ expanded: isOpen['Relatorio'] }"
            @click.prevent="toggleOpcoes('Relatorio')">
            <span class="opcao-dropdown-aberto"><i class="fas fa-chart-line"></i></span>
            <span class="opcao-dropdown-titulo-aberto">Relatórios</span>
            <span class="opcao-dropdown-aberto" v-if="!isOpen['Relatorio']"><i class="fas fa-chevron-down"></i></span>
            <span class="opcao-dropdown-aberto" v-if="isOpen['Relatorio']"><i class="fas fa-chevron-up"></i></span>
          </button>
          <div class="container-subopcoes" v-if="isOpen['Relatorio']">
            <router-link to="/" class="opcoes-dropdown" @click="toggleSidebar">Relatorio Geral</router-link>
            <router-link to="/" class="opcoes-dropdown" @click="toggleSidebar">Relatorio Vendas</router-link>
            <router-link to="/" class="opcoes-dropdown" @click="toggleSidebar">Relatorio Orçamento</router-link>
          </div>
        </div>
        <div class="container-opcoes-sidebar-aberta">
          <button class="btn-sidebar-aberta" :class="{ expanded: isOpen['Cadastros'] }"
            @click.prevent="toggleOpcoes('Cadastros')">
            <span class="opcao-dropdown-aberto"><i class="fas fa-user-edit"></i></span>
            <span class="opcao-dropdown-titulo-aberto">Cadastros</span>
            <span class="opcao-dropdown-aberto" v-if="!isOpen['Cadastros']"><i class="fas fa-chevron-down"></i></span>
            <span class="opcao-dropdown-aberto" v-if="isOpen['Cadastros']"><i class="fas fa-chevron-up"></i></span>
          </button>
          <div class="container-subopcoes" v-if="isOpen['Cadastros']">
            <router-link to="/cadastros" class="opcoes-dropdown" @click="toggleSidebar">Pessoas</router-link>
            <router-link to="/" class="opcoes-dropdown" @click="toggleSidebar">Produtos</router-link>
            <router-link to="/" class="opcoes-dropdown" @click="toggleSidebar">???????</router-link>
          </div>
        </div>
        <div class="container-opcoes-sidebar-aberta">
          <button class="btn-sidebar-aberta" :class="{ expanded: isOpen['Orçamentos'] }"
            @click.prevent="toggleOpcoes('Orçamentos')">
            <span class="opcao-dropdown-aberto"><i class="fas fa-file-invoice-dollar"></i></span>
            <span class="opcao-dropdown-titulo-aberto">Orçamentos</span>
            <span class="opcao-dropdown-aberto" v-if="!isOpen['Orçamentos']"><i class="fas fa-chevron-down"></i></span>
            <span class="opcao-dropdown-aberto" v-if="isOpen['Orçamentos']"><i class="fas fa-chevron-up"></i></span>
          </button>
          <div class="container-subopcoes" v-if="isOpen['Orçamentos']">
            <router-link to="/" class="opcoes-dropdown" @click="toggleSidebar">Relatorio Geral</router-link>
            <router-link to="/" class="opcoes-dropdown" @click="toggleSidebar">Relatorio Vendas</router-link>
            <router-link to="/" class="opcoes-dropdown" @click="toggleSidebar">Relatorio Orçamento</router-link>
          </div>
        </div>
        <div class="container-opcoes-sidebar-aberta">
          <button class="btn-sidebar-aberta" :class="{ expanded: isOpen['Projetos'] }"
            @click.prevent="toggleOpcoes('Projetos')">
            <span class="opcao-dropdown-aberto"><i class="fas fa-project-diagram"></i></span>
            <span class="opcao-dropdown-titulo-aberto">Projetos</span>
            <span class="opcao-dropdown-aberto" v-if="!isOpen['Projetos']"><i class="fas fa-chevron-down"></i></span>
            <span class="opcao-dropdown-aberto" v-if="isOpen['Projetos']"><i class="fas fa-chevron-up"></i></span>
          </button>
          <div class="container-subopcoes" v-if="isOpen['Projetos']">
            <router-link to="/" class="opcoes-dropdown" @click="toggleSidebar">Relatorio Geral</router-link>
            <router-link to="/" class="opcoes-dropdown" @click="toggleSidebar">Relatorio Vendas</router-link>
            <router-link to="/" class="opcoes-dropdown" @click="toggleSidebar">Relatorio Orçamento</router-link>
          </div>
        </div>

        <div class="container-opcoes-sidebar-aberta">
          <button class="btn-sidebar-aberta" :class="{ expanded: isOpen['Vendas'] }"
            @click.prevent="toggleOpcoes('Vendas')">
            <span class="opcao-dropdown-aberto"><i class="fas fa-shopping-cart"></i></span>
            <span class="opcao-dropdown-titulo-aberto">Vendas</span>
            <span class="opcao-dropdown-aberto" v-if="!isOpen['Vendas']"><i class="fas fa-chevron-down"></i></span>
            <span class="opcao-dropdown-aberto" v-if="isOpen['Vendas']"><i class="fas fa-chevron-up"></i></span>
          </button>
          <div class="container-subopcoes" v-if="isOpen['Vendas']">
            <router-link to="/" class="opcoes-dropdown" @click="toggleSidebar">Relatorio Geral</router-link>
            <router-link to="/" class="opcoes-dropdown" @click="toggleSidebar">Relatorio Vendas</router-link>
            <router-link to="/" class="opcoes-dropdown" @click="toggleSidebar">Relatorio Orçamento</router-link>
          </div>
        </div>

        <div class="container-opcoes-sidebar-aberta">
          <button class="btn-sidebar-aberta" :class="{ expanded: isOpen['Estoque'] }"
            @click.prevent="toggleOpcoes('Estoque')">
            <span class="opcao-dropdown-aberto"><i class="fas fa-boxes"></i></span>
            <span class="opcao-dropdown-titulo-aberto">Estoque</span>
            <span class="opcao-dropdown-aberto" v-if="!isOpen['Estoque']"><i class="fas fa-chevron-down"></i></span>
            <span class="opcao-dropdown-aberto" v-if="isOpen['Estoque']"><i class="fas fa-chevron-up"></i></span>
          </button>
          <div class="container-subopcoes" v-if="isOpen['Estoque']">
            <router-link to="/" class="opcoes-dropdown" @click="toggleSidebar">Relatorio Geral</router-link>
            <router-link to="/" class="opcoes-dropdown" @click="toggleSidebar">Relatorio Vendas</router-link>
            <router-link to="/" class="opcoes-dropdown" @click="toggleSidebar">Relatorio Orçamento</router-link>
          </div>
        </div>

        <div class="container-opcoes-sidebar-aberta">
          <button class="btn-sidebar-aberta" :class="{ expanded: isOpen['Configuracoes'] }"
            @click.prevent="toggleOpcoes('Configuracoes')">
            <span class="opcao-dropdown-aberto"><i class="fa-solid fa-gear"></i></span>
            <span class="opcao-dropdown-titulo-aberto">Configurações</span>
            <span class="opcao-dropdown-aberto" v-if="!isOpen['Configuracoes']"><i
                class="fas fa-chevron-down"></i></span>
            <span class="opcao-dropdown-aberto" v-if="isOpen['Configuracoes']"><i class="fas fa-chevron-up"></i></span>
          </button>
          <div class="container-subopcoes" v-if="isOpen['Configuracoes']">
            <router-link to="/empresa" class="opcoes-dropdown" @click="toggleSidebar">Empresa</router-link>
            <router-link to="/usuarios" class="opcoes-dropdown" @click="toggleSidebar">Usuários</router-link>
            <router-link to="/precificacao" class="opcoes-dropdown" @click="toggleSidebar">Precificação</router-link>
          </div>
        </div>

        <div class="container-opcoes-sidebar-aberta">
          <button class="btn-sidebar-aberta" :class="{ expanded: isOpen['Ferramentas'] }"
            @click.prevent="toggleOpcoes('Ferramentas')">
            <span class="opcao-dropdown-aberto"><i class="fas fa-wrench"></i></span>
            <span class="opcao-dropdown-titulo-aberto">Ferramentas</span>
            <span class="opcao-dropdown-aberto" v-if="!isOpen['Ferramentas']"><i class="fas fa-chevron-down"></i></span>
            <span class="opcao-dropdown-aberto" v-if="isOpen['Ferramentas']"><i class="fas fa-chevron-up"></i></span>
          </button>
          <div class="container-subopcoes" v-if="isOpen['Ferramentas']">
            <router-link to="/agenda" class="opcoes-dropdown" @click="toggleSidebar">Agenda</router-link>
            <router-link to="/ged" class="opcoes-dropdown" @click="toggleSidebar">GED</router-link>
            <router-link to="/kanban" class="opcoes-dropdown" @click="toggleSidebar">Kanban</router-link>
          </div>
        </div>
      </nav>
    </section>
  </Transition>

</template>

<script>

export default {

  name: 'Sidebar',

  data() {
    return {
      isOpen: {},
      isExpanded: false,
    }
  },


  methods: {
    toggleOpcoes(dropdown) {
      // Fecha todas as outras opções abertas
      for (let key in this.isOpen) {
        if (key !== dropdown) {
          this.isOpen[key] = false;
        }
      }

      // Abre ou fecha a opção clicada
      if (this.isOpen.hasOwnProperty(dropdown)) {
        this.isOpen[dropdown] = !this.isOpen[dropdown];
      } else {
        this.isOpen[dropdown] = true;
      }
    },
    toggleSidebar() {
      // Fecha todas as opções abertas ao fechar a sidebar
      if (!this.isExpanded) {
        for (let key in this.isOpen) {
          this.isOpen[key] = false;
        }
      }
      this.isExpanded = !this.isExpanded;
    },
  }
}

</script>

<style lang="scss">
.btn-abrir-menu {
  @apply text-xl text-screenCor fixed left-[36px] top-24 z-[20] px-2 py-1 rounded-full bg-corBarraLateral
}

.btn-fechar-menu {
  @apply text-xl text-screenCor fixed left-[210px] top-24 z-[20] px-2 py-1 rounded-full bg-corBarraLateral
}

.container-sidebar {
  @apply fixed w-14 bg-corBarraLateral hidden md:block mt-24 rounded-tr-lg rounded-br-lg pb-8
}

.sidebar {
  @apply flex flex-col items-center w-full
}

.conteudo-sidebar {
  @apply text-xl text-screenCor
}

.container-opcoes-sidebar {
  @apply flex flex-col my-5 w-full
}

.opcao-dropdown-curto {
  @apply hover:bg-corBase/30 w-14 py-3
}


// estilo sidebar aberta
.container-sidebar-aberta {
  @apply fixed w-auto bg-corBarraLateral hidden md:block mt-24 rounded-tr-lg rounded-br-lg h-[380px] overflow-auto scrollbar-thin scrollbar-thumb-corBase/20 z-[10];
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0) transparent;
}

.conteudo-sidebar-aberta {
  @apply my-5
}

.container-opcoes-sidebar-aberta {
  @apply flex flex-col text-screenCor w-full divide-y divide-corBase/30
}

.btn-sidebar-aberta {
  @apply w-full flex justify-between text-center py-2 px-6
}

.opcao-dropdown-aberto {
  @apply text-lg
}

.opcao-dropdown-titulo-aberto {
  @apply text-lg w-full px-3
}

.container-subopcoes {
  @apply flex flex-col text-lg text-corBase bg-corBase/20 mt-2 w-full
}

.opcoes-dropdown {
  @apply text-base text-screenCor py-2 hover:bg-corBase/30 w-full px-6
}

// css para transition da sitebar
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>