<template>
    <div class="container-formulario">
        <!-- Cabeçalho -->
        <div class="container-cabecalho flex justify-between mb-4">
            <div>
                <h1 class="text-xl text-corBase font-bold">Criar Vão para {{ obra.descricao }}</h1>
            </div>

            <router-link :to="`/obra/${$route.params.obra}`" class="text-xl text-corBase font-bold">
                <i class="fas fa-chevron-left"></i>
            </router-link>
        </div>

        <!-- Formulário de criação de vão -->
        <div>
            <div class="form-group flex items-center">
                <label for="descricao" class="mr-2 text-corBase">Descrição</label>
                <input v-model="vao.descricao" type="text" id="descricao" placeholder="Digite aqui..." required />
            </div>

            <div class="form-group flex items-center">
                <label for="tipologia" class="mr-2 text-corBase">Tipologia</label>
                <select class="cursor-pointer" v-model="tipologiaSelecionada" @change="onTipologiaChange">
                    <option value="" disabled>Selecione uma tipologia</option>
                    <option v-for="tipologia in tipologias" :key="tipologia.id" :value="tipologia.id">
                        {{ tipologia.nome }}
                    </option>
                </select>
            </div>

            <!-- Lista de linhas baseada na tipologia selecionada -->
            <div class="form-group" v-if="linhas.length > 0">
                <p>Linhas disponíveis:</p>
                <div v-for="linha in linhas" :key="linha.id" class="flex">
                    <label class="mr-2">{{ linha.nome }}</label>
                    <input type="checkbox" :value="linha" v-model="linhasSelecionadas" />
                </div>
                <button type="button" class="btn-salvar" @click="adicionarTipologia">Adicionar</button>
            </div>

            <!-- Seção de abas -->
            <div class="bg-gray-300 p-5 pt-0 rounded-lg">
                <div class="abas-container">
                    <template v-for="(aba, index) in abas" :key="index">
                        <div :class="['aba', { 'aba-selecionada': abaSelecionada === index }]">
                            {{ aba.nome }}
                        </div>
                    </template>
                </div>

                <!-- Conteúdo da aba selecionada -->
                <div v-if="abas.length > 0">
                    <div v-for="(aba, index) in abas" :key="index" v-show="abaSelecionada === index">

                        <!-- Aba Geral -->
                        <GeralComponent v-if="aba.nome === 'Geral'" :vao="vao" :orcamento="orcamento"
                            @update-file="onFileChange" />

                        <!-- Aba correspondente a tipologia -->
                        <div v-else class="flex items-start">
                            <!-- Coluna das imagens -->
                            <div class="w-1/2 flex flex-col items-center text-center">
                                <h4 class="text-corBase">Imagem da tipologia:</h4>
                                <img :src="aba.imagem.url_documento" class="foto-tipologia w-32 h-auto cursor-pointer"
                                    alt="Imagem tipologia" @click="abrirModal(aba.imagem.url_documento)">

                                <div v-if="vao.imagem">
                                    <h4 class="text-corBase mt-10">Imagem do vão:</h4>
                                    <img :src="vao.imagem.url_documento"
                                        class="foto-tipologia w-32 h-auto cursor-pointer object-cover"
                                        alt="Pré-visualização" @click="abrirModal(vao.imagem.url_documento)" />
                                </div>
                            </div>

                            <!-- Linha de separação vertical -->
                            <div class="w-px h-full bg-gray-600 mx-4"></div>

                            <!-- Coluna das informações da linha -->
                            <div class="w-1/2 flex flex-col space-y-4">
                                <!-- Abas internas para linhas -->
                                <div class="linhas-abas-container">
                                    <template v-for="(linha, linhaIndex) in aba.linhas" :key="linhaIndex">
                                        <div :class="['linha-aba', { 'linha-aba-selecionada': linhaSelecionada === linhaIndex }]"
                                            @click="selecionarLinha(linhaIndex, aba)">
                                            {{ linha.nome }}
                                        </div>
                                    </template>
                                </div>

                                <!-- Exibe o componente CriarVariaveisOrcamento quando o projeto for selecionado -->
                                <!-- <div v-if="mostrarVariaveisPorLinha[`${aba.id}_${aba.linhas[linhaSelecionada]?.nome}`]">
                                    <CriarVariaveisOrcamento
                                        :id="projetoSelecionadoPorLinha[`${aba.id}_${aba.linhas[linhaSelecionada]?.nome}`]" />
                                </div> -->

                                <!-- Conteúdo da linha selecionada -->
                                <div v-if="aba.linhas.length > 0">
                                    <div v-for="(linha, linhaIndex) in aba.linhas" :key="linhaIndex"
                                        v-show="linhaSelecionada === linhaIndex">

                                        <!-- Verifica se um projeto foi selecionado -->
                                        <div v-if="projetosSelecionados[`${aba.id}_${linha.nome}`]">
                                            <p class="text-corBase font-semibold text-center pb-5">Projeto Selecionado
                                                para linha {{
                                                    linha.nome }}:</p>
                                            <div class="flex flex-wrap gap-6 justify-center">
                                                <div
                                                    class="flex flex-col items-center w-60 p-4 bg-white border border-corBase/40 shadow-md rounded-md">
                                                    <img src="@/assets/imagens/fotos/janela2folhas.png"
                                                        alt="Imagem do Projeto" class="w-40 h-40 object-cover mb-4">
                                                    <p class="text-sm font-medium text-center">
                                                        {{
                                                            projetosSelecionados[`${aba.id}_${linha.nome}`].projeto.descricao_fixa
                                                        }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Exibição dos projetos -->
                                        <div v-else>
                                            <p class="text-corBase font-semibold text-center pb-5">Lista de Projetos</p>
                                            <!-- Projetos do tenant atual -->
                                            <ListaProjetos v-if="listaProjetos[linha.nome]?.length > 0"
                                                :projetos="listaProjetos[linha.nome]" :abaId="aba.id"
                                                :nomeDaLinha="linha" @projeto-selecionado="salvarProjetos" />

                                            <!-- Projetos do tenant público -->
                                            <ListaProjetos v-if="listaProjetosPublic[linha.nome]?.length > 0"
                                                :projetos="listaProjetosPublic[linha.nome]" :abaId="aba.id"
                                                :nomeDaLinha="linha" @projeto-selecionado="salvarProjetos" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- Botões para navegar entre as abas -->
                <div class="flex w-full justify-end itens-center mt-6">
                    <button v-if="abaSelecionada > 0" @click="voltarAba" class="btn-cancelar">
                        Voltar
                    </button>
                    <button v-if="abaSelecionada < abas.length - 1" @click="onAvancarAba" class="btn-salvar">
                        Avançar
                    </button>
                    <button v-else type="button" @click="onAvancarAba" class="btn-salvar">
                        Salvar
                    </button>
                </div>

            </div>
        </div>

        <!-- Modal de Foto Ampliada -->
        <div v-if="fotoAmpliada" class="modal-mask z-9999" @click="fecharModal">
            <div class="modal-wrapper z-9999">
                <img :src="fotoAmpliada" class="modal-foto z-9999" />
            </div>
        </div>

    </div>
    <SucessoRapido v-if="sucesso" mensagem="Vão criado com sucesso." />
    <ErroRapido v-if="erro" :mensagem="erroMessage" />
</template>

<script>
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import GeralComponent from './ComponentesCriacaoVao/GeralComponent.vue';
import CriarVariaveisOrcamento from './ComponentesCriacaoVao/CriarVariaveisOrcamento.vue';
import ListaProjetos from './ComponentesCriacaoVao/ListaProjetos.vue'
import axios from 'axios';

export default {
    name: 'CriarVao',

    components: {
        SucessoRapido,
        ErroRapido,
        GeralComponent,
        CriarVariaveisOrcamento,
        ListaProjetos
    },

    data() {
        return {
            vao: {
                descricao: null,
                largura: null,
                altura: null,
                cor_componente: null,
                cor_perfil: null,
                cor_vidro: null,
                local_vao: null,
                imagem: null,
            },
            orcamento: {
                tipoMedidaContramarco: '',
                contramarco: false,
                arremate: false,
                arrematePiso: false
            },

            obra: [],

            orcamentoCriado: false,

            cliente: '',

            idOrcamento: '',
            idItemOrcamento: '',

            mostrarVariaveis: false,
            mostrarVariaveisPorLinha: {},

            tipologiaSelecionada: null,
            tipologias: [],

            linhas: [],
            linhasSelecionadas: [],

            abas: [{ nome: 'Geral', ativo: true }],
            abaSelecionada: 0,
            linhaSelecionada: 0,

            sucesso: false,
            erro: false,
            erroMessage: '',

            fotoAmpliada: null,

            id_vao_criado: '',

            projetosPorLinha: {},
            projetosSelecionados: {},

            listaProjetosSelecionados: [],

            listaProjetos: {},
            listaProjetosPublic: {},
            projetoSelecionadoPorLinha: {}
        };
    },

    created() {
        this.getTipologias();
        this.getDadosObra()
    },

    methods: {

        async getDadosObra(){
            const token = await this.$store.dispatch("getAuthTokenCookie");
            await this.$store.dispatch("fetchURLrequest");

            try {
                this.$store.dispatch("setLoading", true);
                const response = await axios.get(`obra/obra/${this.$route.params.obra}`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })
                this.obra = response.data
            } catch(error){
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async onAvancarAba() {
            if (this.abas[this.abaSelecionada].nome === 'Geral') {
                // Verifica se todos os campos obrigatórios estão preenchidos
                if (!this.vao.descricao || !this.vao.largura || !this.vao.altura || !this.vao.cor_componente || !this.vao.cor_perfil || !this.vao.cor_vidro) {
                    this.erro = true
                    this.erroMessage = "Preencha todos os campos obrigatórios."
                    setTimeout(() => {
                        this.erro = false;
                        this.erroMessage = ''
                    }, 3000);
                    return;
                }

                // Verifica se há tipologia adicionada
                const hasTipologiaAdicionada = this.abas.some(aba => aba.nome !== 'Geral');
                if (!hasTipologiaAdicionada) {
                    this.salvarVao()
                    return;
                }

                // Cria o orçamento apenas uma vez
                // if (!this.orcamentoCriado) {
                //     await this.criarOrcamento();
                // }
            }

            if (this.abaSelecionada === this.abas.length - 1) {
                await this.salvarVao();
            } else {
                this.proximaAba();
            }
        },

        proximaAba() {
            if (this.abaSelecionada < this.abas.length - 1) {
                this.abaSelecionada++;
            }
        },

        voltarAba() {
            if (this.abaSelecionada > 0) {
                this.abaSelecionada--;
            }
        },

        onFileChange(file) {
            this.vao.imagem = file;
        },

        abrirModal(fotoUrl) {
            this.fotoAmpliada = fotoUrl;
        },

        fecharModal() {
            this.fotoAmpliada = null;
        },

        async getTipologias() {
            const token = await this.$store.dispatch("getAuthTokenCookie");
            await this.$store.dispatch("fetchURLrequest");

            try {
                this.$store.dispatch("setLoading", true);
                const response = await axios.get(`/projeto/tipologia/?ativo=True&page_size=9999`, {
                    headers: { Authorization: `Token ${token}` }
                });
                this.tipologias = response.data.results
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async onTipologiaChange() {
            await this.getLinhas();
            this.linhasSelecionadas = [];
        },

        async getLinhas() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');

            try {
                const response = await axios.get(`/produtos/linha-aplicacao/`, {
                    headers: { Authorization: `Token ${token}` }
                });
                this.linhas = response.data.results;
            } catch (error) {
                console.error('Erro ao carregar linhas:', error);
            }
        },

        // Adicionando a aba da tipologia
        async adicionarTipologia() {
            const tipologia = this.tipologias.find(t => t.id === this.tipologiaSelecionada);
            if (tipologia && this.linhasSelecionadas.length > 0) {
                const uniqueAbaId = `${tipologia.nome}_${this.abas.length}`;

                this.abas.push({
                    nome: `${tipologia.nome}`,
                    ativo: true,
                    id: uniqueAbaId,
                    id_tipologia: tipologia.id,
                    linhas: [...this.linhasSelecionadas],
                    imagem: tipologia.imagem
                });

                for (const linha of this.linhasSelecionadas) {
                    const projetos = await this.buscarProjetos(tipologia.id, linha.nome);
                    this.projetosPorLinha[`${uniqueAbaId}_${linha.nome}`] = projetos;
                }

                this.tipologiaSelecionada = null;
                this.linhasSelecionadas = [];
                this.linhas = [];
            }
        },

        // Busco projetos com aquela tipologia e aquela linha
        async buscarProjetos(tipologiaId, linhaNome) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            this.$store.dispatch("setLoading", true);
            try {
                const response = await axios.get(
                    `/projeto/projeto/?tipologia=${tipologiaId}&linha_nome=${linhaNome}`, {
                    headers: { Authorization: `Token ${token}` }
                });

                this.listaProjetos[linhaNome] = response.data.current_tenant;
                this.listaProjetosPublic[linhaNome] = response.data.public_tenant;
            } catch (error) {
                console.error('Erro ao buscar projetos:', error);
                return [];
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        // Criar o Vão
        async salvarVao() {
            console.log(this.vao)
            const formDataVao = new FormData();
            formDataVao.append('obra', this.$route.params.obra);
            formDataVao.append('ativo', 'true');
            formDataVao.append('descricao', this.vao.descricao);
            formDataVao.append('largura', this.vao.largura);
            formDataVao.append('altura', this.vao.altura);
            // formDataVao.append('orcamento', this.idOrcamento);
            formDataVao.append('local_vao', this.vao.local_vao);
            if (this.vao.imagem) {
                formDataVao.append('imagem', this.vao.imagem.id);
            }
            formDataVao.append('cor_componente', this.vao.cor_componente);
            formDataVao.append('cor_perfil', this.vao.cor_perfil);
            formDataVao.append('cor_vidro', this.vao.cor_vidro);

            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            this.$store.dispatch("setLoading", true);
            try {
                const response = await axios.post('obra/vao-obra/', formDataVao, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                this.id_vao_criado = response.data.id
                this.salvarListaProjetos(this.id_vao_criado)

                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$router.push(`/obra/${this.$route.params.obra}`);
                }, 3000);
            } catch (error) {
                console.error('Erro ao criar vão:', error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        salvarProjetos(projeto) {
            // Salva o projeto selecionado no objeto `projetosSelecionados`
            this.projetosSelecionados[`${projeto.abaId}_${projeto.nomeDaLinha.nome}`] = projeto;
            console.log(this.projetosSelecionados);
        },

        // Função para salvar os projetos associados ao vão
        async salvarListaProjetos(id_vao) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            // Verifica se há projetos selecionados
            const hasProjetos = Object.keys(this.projetosSelecionados).length > 0;

            if (hasProjetos) {
                // Envia os projetos selecionados com suas linhas e tipologias
                for (const chave in this.projetosSelecionados) {
                    const projetoIndex = this.projetosSelecionados[chave];

                    if (projetoIndex && projetoIndex.projeto && projetoIndex.nomeDaLinha && projetoIndex.projeto.id) {
                        const { projeto, nomeDaLinha } = projetoIndex;

                        // Encontra a tipologia associada à chave do projeto e linha específicos
                        const [tipologiaNome, linhaNome] = chave.split('_');
                        const tipologiaAssociada = this.abas.find(aba => aba.nome === tipologiaNome);

                        if (tipologiaAssociada && tipologiaAssociada.id_tipologia) {
                            const formDataProjeto = new FormData();

                            formDataProjeto.append('vao_obra', id_vao);
                            formDataProjeto.append('ativo', 'true');
                            formDataProjeto.append('projeto', projeto.id);
                            formDataProjeto.append('linhas', nomeDaLinha.id);
                            formDataProjeto.append('tipologias', tipologiaAssociada.id_tipologia);

                            this.$store.dispatch("setLoading", true);
                            try {
                                await axios.post(`obra/linhas-projeto-vao-obra/`, formDataProjeto, {
                                    headers: {
                                        Authorization: `Token ${token}`
                                    }
                                });
                                console.log(`Projeto ${projeto.id} com tipologia ${tipologiaAssociada.nome} e linha ${nomeDaLinha.nome} salvo com sucesso.`);
                            } catch (error) {
                                console.error(`Erro ao enviar o projeto ${projeto.id} com linha ${nomeDaLinha.nome}:`, error);
                            } finally {
                                this.$store.dispatch("setLoading", false);
                            }
                        } else {
                            console.warn(`Tipologia associada não encontrada ou inválida para o projeto ${projeto.id} e linha ${nomeDaLinha.nome}`);
                        }
                    } else {
                        console.warn(`Projeto ou linha inválido na chave ${chave}.`);
                    }
                }
            }

            // Salva as tipologias que não possuem projetos associados
            for (const aba of this.abas) {
                if (aba.nome !== 'Geral' && Array.isArray(aba.linhas) && aba.linhas.length > 0 && aba.id_tipologia) {
                    const formDataSemProjetos = new FormData();

                    formDataSemProjetos.append('vao_obra', id_vao);
                    formDataSemProjetos.append('ativo', 'true');
                    formDataSemProjetos.append('tipologias', aba.id_tipologia);

                    // Verifica se a tipologia já foi associada a um projeto selecionado, caso contrário, salva separadamente
                    const isAssociatedWithProject = Object.values(this.projetosSelecionados).some(projeto =>
                        projeto.nomeDaLinha && aba.linhas.some(linha => linha.id === projeto.nomeDaLinha.id)
                    );

                    if (!isAssociatedWithProject) {
                        // Adiciona as linhas específicas da tipologia atual
                        for (const linha of aba.linhas) {
                            if (linha.id) {
                                formDataSemProjetos.append('linhas', linha.id);
                            }
                        }

                        this.$store.dispatch("setLoading", true);
                        try {
                            await axios.post(`obra/linhas-projeto-vao-obra/`, formDataSemProjetos, {
                                headers: {
                                    Authorization: `Token ${token}`
                                }
                            });
                            console.log(`Tipologia ${aba.id_tipologia} com suas linhas salvas com sucesso (sem projetos).`);
                        } catch (error) {
                            console.error(`Erro ao salvar tipologia ${aba.id_tipologia} com suas linhas:`, error);
                        } finally {
                            this.$store.dispatch("setLoading", false);
                        }
                    }
                }
            }
        },
        async buscarCliente() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')
            try {
                const response = await axios.get(`/obra/?obra=${this.$route.params.obra}`,
                    {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    }
                )
                this.cliente = response.data.results[0].cliente.id
            } catch (error) {
                console.error(error)
            }
        },

        async criarOrcamento() {
            await this.buscarCliente();

            const data = {
                descricao: this.vao.descricao,
                contramarco: this.orcamento.contramarco,
                tipo_medida_contramarco: this.orcamento.tipoMedidaContramarco === 'Externa' ? 'E' : 'I',
                arremate: this.orcamento.arremate,
                arremate_piso: this.orcamento.arrematePiso,
                cliente: this.cliente
            };

            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.post('orcamentos/', data, {
                    headers: { Authorization: `Token ${token}` }
                });

                this.idOrcamento = response.data.id;
                this.orcamentoCriado = true
            } catch (error) {
                console.error(error);
            }
        },

        async incluirItemOrcamento(projeto) {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                const data = {
                    orcamento: this.idOrcamento,
                    projeto: projeto.projeto.id,
                    descricao: this.vao.descricao,
                    quantidade: this.quantidade,
                    largura: this.vao.largura,
                    altura: this.vao.altura,
                    contramarco: this.orcamento.contramarco,
                    arremate: this.orcamento.arremate,
                    arremate_piso: this.orcamento.arrematePiso,
                    cor_componentes: this.vao.cor_componente,
                    cor_perfis: this.vao.cor_perfil,
                    cor_vidros: this.vao.cor_vidro,
                    status_medidas: "2"
                };

                const response = await axios.post('orcamentos/item-orcamento/', data, {
                    headers: { Authorization: `Token ${token}` }
                });

                this.listaProjetosSelecionados.push({
                    projetoId: projeto.projeto.id,
                    linhaId: projeto.nomeDaLinha.id
                });

                this.idItemOrcamento = response.data.id;
                this.projetoSelecionadoPorLinha[`${projeto.abaId}_${projeto.nomeDaLinha.nome}`] = response.data.id;
                this.mostrarVariaveisPorLinha[`${projeto.abaId}_${projeto.nomeDaLinha.nome}`] = true;
            } catch (error) {
                console.error(error);
            }
        },

        selecionarLinha(linhaIndex, aba) {
            this.linhaSelecionada = linhaIndex;
            const chave = `${aba.id}_${aba.linhas[linhaIndex].nome}`;
            this.mostrarVariaveis = !!this.mostrarVariaveisPorLinha[chave];
        },

    }
};
</script>

<style scoped>
label {
    display: block;
    font-weight: bold;
}

input[type="file"],
input[type="number"],
input[type="text"],
select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.abas-container {
    display: flex;
    margin-top: 15px;
    @apply mt-4 p-2 border-b rounded-lg
}

.aba {
    padding: 10px;
    cursor: pointer;
    margin-right: 5px;
    font-weight: 700;
    transition: 0.3s ease-in-out;
    @apply rounded-lg bg-white text-corBase border border-white
}

.aba-selecionada {
    @apply bg-corBase text-white border border-white
}

.foto-tipologia {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    z-index: 10;
}

.linhas-abas-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    gap: 5px;
}

.linha-aba {
    padding: 10px;
    cursor: pointer;
    margin-top: 2px;
    margin-right: 5px;
    font-weight: 700;
    transition: 0.3s ease-in-out;
    white-space: nowrap;
    @apply rounded-lg bg-white text-corBase border border-white
}

.linha-aba-selecionada {
    @apply bg-corBase text-white border border-white
}

.modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-foto {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
}
</style>
