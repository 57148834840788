<template>
    <div>
      <v-select
        v-model="produtoPesquisado"
        :options="produtosPesquisados"
        label="codigo_fornecedor"
        class="w-80"
        :clearable="false"
        @input="onSelect"
        @search="buscarProdutosPorFornecedor"
      >
        <template v-slot:option="option">
          <div @click.prevent="onSelect(option)">
            <span class="font-bold">
            {{ option.codigo_fornecedor }} 
            </span>
          </div>
        </template>
      </v-select>
    </div>
  </template>
  
  <script>
  import vSelect from "vue-select";
  import axios from 'axios'
  
  export default {
    name: 'SearchProxy',
  
    props: {
      produtoPesquisadoProxy: {
        type: Object,
        default: ''
      },
  
      produtosPesquisadosProxy: {
        type: Array,
        default: () => []
      },
  
      produtoAtual: {
        type: Object,
        default: {}
      },
    },
  
    components: {
      vSelect
    },
  
    created() {
      this.produtoPesquisado = this.produtoPesquisadoProxy;
      this.produtosPesquisados = this.produtosPesquisadosProxy;
    },
  
    data() {
      return {
        produtoPesquisado: '',
        produtosPesquisados: [],
      };
    },
  
    methods: {  
      async buscarProdutosPorFornecedor(searchQuery) {
        if (searchQuery.length > 2) {
          try {
            const token = await this.$store.dispatch("getAuthTokenCookie");
            await this.$store.dispatch("fetchURLrequest");
  
            const response = await axios.get(`produtos/produto-por-fornecedor/?codigo_fornecedor=${searchQuery}&page_size=9999`, {
              headers: {
                'Authorization': `Token ${token}`
              }
            });
            this.produtosPesquisados = response.data.results;
          } catch (error) {
            console.error(error);
          }
        }
      },
  
      onSelect(produto) {
        this.produtoAtual.codigo_fornecedor_vinculado = produto.codigo_fornecedor;
        this.$emit('select', this.produtoAtual);
      },
    },
  }
  </script>
  
  <style scoped>
  /* Estilos adicionais, se necessários */
  </style>
  