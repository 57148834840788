<template>
  <div>
    <FormularioVinculoEmEtapas :produtosXml="produtosXml" :produtosVinculados="produtosVinculados"
      :listaProdutoFornecedor="lista_produto_fornecedor" :abrir="modal_vincular"
      @addProdutosVinculados="addProdutosVinculados" @fecharRelacao="fechar" @fecharSucesso="mensagemSucesso"
      v-if="produtosXml.length > 0" />

    <div class="container-formulario">
      <div class="container-cabecalho flex justify-between mb-4">
        <div>
          <h1 class="text-xl text-corBase font-bold">Entrada Xml</h1>
        </div>

        <router-link to="/estoque/lista-entrada-nota-fiscal" class="text-xl text-corBase font-bold">
          <i class="fas fa-chevron-left"></i>
        </router-link>
      </div>

      <div class="flex flex-col">
        <label>Import XML</label>
        <input type="file" @change="handleFileChange" ref="fileInputRef" />
      </div>

      <div class="w-full flex flex-col border p-2 mt-6">
        <div class="flex w-full gap-10">
          <div class="flex flex-col">
            <label class="font-bold text-corBase">N° NF-e</label>
            <input v-model="n_nfe" class="border-b outline-none focus:border-b-corBase" />
          </div>
          <div class="flex flex-col">
            <label class="font-bold text-corBase">Modelo</label>
            <input v-model="modelo" class="border-b outline-none focus:border-b-corBase" />
          </div>
          <div class="flex flex-col">
            <label class="font-bold text-corBase">Série</label>
            <input v-model="serie" class="border-b outline-none focus:border-b-corBase" />
          </div>
          <div class="flex flex-col">
            <label class="font-bold text-corBase">Data Emissão</label>
            <input v-model="dataEmissao" class="border-b outline-none focus:border-b-corBase" />
          </div>
        </div>
        <div class="flex w-full gap-10 mt-2">
          <div class="flex flex-col">
            <label class="font-bold text-corBase">Chave Acesso</label>
            <input v-model="chave_aceeso" class="border-b outline-none w-[420px] focus:border-b-corBase" />
          </div>
        </div>
      </div>

      <div class="w-full flex flex-col border p-2 mt-6">
        <div class="flex w-full gap-10">
          <div class="flex flex-col w-[30%]">
            <label class="font-bold text-corBase">Razão</label>
            <input v-model="fornecedor.nome_razao" class="border-b outline-none w-full focus:border-b-corBase" />
          </div>
          <div class="flex flex-col">
            <label class="font-bold text-corBase">CNPJ</label>
            <input v-model="fornecedor.cnpj" class="border-b outline-none focus:border-b-corBase" />
          </div>
          <div class="flex flex-col">
            <label class="font-bold text-corBase">Inscrição Estadual</label>
            <input v-model="fornecedor.inscricao_estadual" class="border-b outline-none focus:border-b-corBase" />
          </div>
        </div>
      </div>

      <div class="flex w-full mt-6">
        <div class="overflow-x-auto w-full">
          <table class="w-full border border-collapse">
            <thead>
              <tr class="bg-gray-200">
                <th class="py-1 px-2 border text-sm">COD. Fornecedor [XML]</th>
                <th class="py-1 px-2 border text-sm">Nome Produto [XML]</th>
                <th class="py-1 px-2 border text-sm">Produto [FORNECEDOR]</th>
                <th class="py-1 px-2 border text-sm">Unidade de medida [XML]</th>
                <th class="py-1 px-2 border text-sm">Quantidade [XML]</th>
                <th class="py-1 px-2 border text-sm">Preço Custo</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(produto, index) in produtosXml" :key="produto.id"
                :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-100'">
                <td class="p-2 border text-xs font-bold">
                  {{ produto.codigo_fornecedor_XML }}
                </td>
                <td class="p-2 border text-xs font-bold">
                  <span>{{ produto.nome }}</span>
                </td>
                <td class="p-2 border text-center text-xs font-bold">
                  <v-select v-model="produto.selecionado" :options="lista_produto_fornecedor" label="codigo_fornecedor"
                    @search="buscarProdutosPorFornecedor" class="w-full">
                    <!-- @select="updateProdutoId(produto, $event, index)" -->
                    <template v-slot:option="option">
                      <div>
                        <strong>{{ option.codigo_fornecedor }}</strong> - {{ option.nome_especifico }}
                      </div>
                    </template>
                  </v-select>
                  <!-- v-if="!produto.codigo_fornecedor_vinculado" -->
                  <!-- <span v-else>
                    {{ produto.codigo_fornecedor_vinculado }}
                  </span> -->
                </td>

                <td class="p-2 border text-xs font-bold">
                  <div>{{ produto.unidade_medida }}</div>
                </td>
                <td class="p-2 border text-center text-xs font-bold">
                  <div>{{ produto.estoque }}</div>
                </td>
                <td class="p-2 border text-center text-xs font-bold">
                  <div>{{ produto.preco_custo }}</div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="flex w-full justify-end">
            <button @click.prevent="addEntrada()" class="btn-enviar">
              Adicionar Entrada
            </button>
            <!-- v-if="produtosVinculados.length === produtosXml.length || produtosVinculados.length > 0" -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <SucessoRapido v-if="sucesso === true" mensagem="Produtos XML adicionado com sucesso." />
  <ErroRapido v-if="erro === true" mensagem="Ocorreu um erro ao adicionar o XML." />
</template>

<script>
import VincularEstoqueProduto from "@/components/importacao/xml_produtos/VincularEstoqueProduto";
import axios from "axios";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido";
import ErroRapido from "@/components/toasts/toast_erro/ErroRapido.vue";
import FormularioVinculoEmEtapas from './FormularioVinculoEmEtapas.vue';
import vSelect from "vue-select";

export default {
  name: "ImprotacaoXmlEntrada",

  components: {
    VincularEstoqueProduto,
    SucessoRapido,
    ErroRapido,
    FormularioVinculoEmEtapas,
    vSelect,
  },

  data() {
    return {
      fileInput: null,
      fornecedor: {},
      produtosVinculados: [],
      n_nfe: "",
      chave_aceeso: "",
      modelo: "",
      serie: "",
      dataEmissao: "",
      id_fornecedor: "",
      lista_produto_fornecedor: [],
      modal_vincular: false,
      sucessoMensagem: false,
      produtosXml: [],

      sucesso: false,
      sucessoMessage: '',
      erro: false,
      errorMessage: '',
    };
  },

  methods: {
    async buscarProdutosPorFornecedor(searchQuery) {
      if (searchQuery.length > 2) {
        const token = await this.$store.dispatch("getAuthTokenCookie");
        await this.$store.dispatch("fetchURLrequest");
        try {
          const response = await axios.get(`produtos/produto-por-fornecedor/?codigo_fornecedor=${searchQuery}&page_size=9999`, {
            headers: {
              Authorization: `Token ${token}`,
            },
          });
          this.lista_produto_fornecedor = response.data.results.map((item) => ({
            id: item.id,
            nome_especifico: item.nome_especifico,
            codigo_fornecedor: item.codigo_fornecedor,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    },

    handleFileChange(event) {
      this.fileInput = event.target;
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = async () => {
        await this.parseXML(reader.result);
      };

      reader.readAsText(file);
    },

    parseXML(xmlData) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlData, "text/xml");
      const infNFeElement = xmlDoc.querySelector("infNFe");

      if (infNFeElement) {
        this.chave_aceeso = infNFeElement.getAttribute("Id");
      } else {
        console.error("Elemento infNFe não encontrado");
      }

      const modelo_nfe = xmlDoc.querySelector("mod");
      this.modelo = modelo_nfe ? modelo_nfe.textContent : '';

      const serie_nfe = xmlDoc.querySelector("serie");
      this.serie = serie_nfe ? serie_nfe.textContent : '';

      const numero_nfe = xmlDoc.querySelector("nNF");
      this.n_nfe = numero_nfe ? numero_nfe.textContent : '';

      const data_emissao = xmlDoc.querySelector("dhEmi");
      this.dataEmissao = data_emissao ? data_emissao.textContent : '';

      const emitenteCnpjElement = xmlDoc.querySelector("CNPJ");
      const emitenteNomeElement = xmlDoc.querySelector("xNome");

      if (emitenteNomeElement) {
        this.emitente = {
          cnpj: emitenteCnpjElement.textContent,
        };
      }

      this.getFornecedor(this.emitente.cnpj);

      const produtoElements = xmlDoc.querySelectorAll("prod");
      const produtos = [];

      produtoElements.forEach((produtoElement) => {
        const codigoElement = produtoElement.querySelector("cProd");
        const nomeElement = produtoElement.querySelector("xProd");
        const unidadeMedidaElement = produtoElement.querySelector("uCom");
        let quantidadeElement = produtoElement.querySelector("qCom");
        let valorProdutoElement = produtoElement.querySelector("vUnCom");
        const valorNcm = produtoElement.querySelector("NCM");

        valorProdutoElement = Number(valorProdutoElement.textContent);
        quantidadeElement = Number(quantidadeElement.textContent);

        produtos.push({
          produto_id: '',
          nome_especifico: '',
          codigo_fornecedor_XML: codigoElement ? codigoElement.textContent : '',
          codigo_fornecedor_vinculado: '',
          nome: nomeElement ? nomeElement.textContent : '',
          unidade_medida: unidadeMedidaElement ? unidadeMedidaElement.textContent : '',
          estoque: quantidadeElement.toFixed(3),
          preco_custo: valorProdutoElement.toFixed(2),
          ncm: valorNcm ? valorNcm.textContent : '',
        });
      });

      this.produtosXml = produtos;
      this.getVinculoXml(this.produtosXml);
      this.modal_vincular = true;
    },

    fechar() {
      this.modal_vincular = false;
    },

    updateProdutoId(produto, selecionado, index) {
      console.log("to aqui")
      console.log(produto)
      console.log(selecionado)
      produtosXml[index].produto_id = selecionado.id; // Captura o ID do produto
      produtosXml[index].codigo_fornecedor_vinculado = selecionado.codigo_fornecedor; // Captura o código do fornecedor
    },

    addProdutosVinculados(novosVinculos) {
      for (let i = 0; i < this.produtosXml.length; i++) {
        for (let j = 0; j < novosVinculos.length; j++) {
          if (this.produtosXml[i].codigo_fornecedor_XML === novosVinculos[j].codigo_fornecedor_XML) {
            this.produtosXml[i].produto_id = novosVinculos[j].produto_por_fornecedor.id
          }
        }
      }

      this.produtosVinculados = [...this.produtosVinculados, novosVinculos]
    },

    async getFornecedor(cnpj) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");
      try {
        const response = await axios.get(`pessoas/fornecedor?pessoa=${cnpj}`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        this.fornecedor = response.data.results[0].pessoa.pessoa_juridica;
        this.id_fornecedor = response.data.results[0].id;
      } catch (error) {
        console.error(error.message);
      }
      this.$store.dispatch('setLoading', false);
    },

    async getVinculoXml(produtos) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      const vinculoXmlPromises = produtos.map((produto) =>
        axios.get(`/produtos/vinculo-xml-produto-fornecedor/?codigo_fornecedor_XML=${produto.codigo_fornecedor_XML}&page_size=999`, {
          headers: { Authorization: `Token ${token}` },
        })
      );

      const response = await Promise.all(vinculoXmlPromises);
      const result = response.map((res) => res.data.results[0]);
      this.produtosVinculados = result.filter((res) => res !== undefined);

      for (let i = 0; i < this.produtosXml.length; i++) {
        for (let j = 0; j < this.produtosVinculados.length; j++) {
          console.log(this.produtosVinculados)
          if (this.produtosXml[i].codigo_fornecedor_XML === this.produtosVinculados[j].codigo_fornecedor_XML) {
            const vinculo = this.produtosVinculados[j];
            this.produtosXml[i].codigo_fornecedor_vinculado = vinculo.codigo_fornecedor_produto_por_fornecedor;
            this.produtosXml[i].produto_id = vinculo.produto_por_fornecedor.id;

            // Atualizando o valor do 'selecionado' com o objeto completo
            this.produtosXml[i].selecionado = {
              id: vinculo.produto_por_fornecedor.id,
              nome_especifico: vinculo.produto_por_fornecedor.nome_especifico,
              codigo_fornecedor: vinculo.codigo_fornecedor_produto_por_fornecedor,
            };
          }
        }
      }
    },

    async addEntrada() {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      const totalQuantidade = this.produtosXml.reduce((total, produto) => total + parseFloat(produto.preco_custo), 0);

      console.log(this.produtosXml)
      const data = {
        tipo_movimentacao: "ENTRADA",
        motivo_movimentacao: 'ENTRADA NF-E',
        numero_nf: this.n_nfe,
        modelo_nf: this.modelo,
        serie_nf: this.serie,
        chave_acesso_nf: this.chave_aceeso,
        data_emissao_nf: this.dataEmissao,
        fornecedor: this.id_fornecedor,
        produtos: this.produtosXml.some(item => item.selecionado)
          ? this.produtosXml
            .filter(item => item.selecionado)
            .map((item) => ({
              produto: item.selecionado.id,
              qtd_produtos: item.estoque,
              preco_custo: item.preco_custo,
            }))
          : this.produtosXml.map((item) => ({
            produto: item.produto_id,
            qtd_produtos: item.estoque,
            preco_custo: item.preco_custo,
          })),
        total_nf: totalQuantidade.toFixed(2),
      };


      this.$store.dispatch("setLoading", true);
      try {
        await axios.post("/produtos/movimentacao/", data, {
          headers: { Authorization: `Token ${token}` },
        });
        this.$store.dispatch("setLoading", false);
        this.sucesso = true;
        this.sucessoMessage = 'Movimentação realizada com sucesso.';
        setTimeout(() => {
          this.sucesso = false;
          this.sucessoMessage = '';
        }, 3000);
      } catch (err) {
        this.$store.dispatch("setLoading", false);
        this.erro = true;
        this.errorMessage = 'Erro ao realizar movimentação.';
        setTimeout(() => {
          this.erro = false;
          this.errorMessage = '';
        }, 3000);
      }
    },
  },
};
</script>

<style scoped lang="scss">
/* Estilos adicionais aqui, se necessários */
</style>
