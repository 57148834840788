<template>

  <!-- Lista de pessoas até 768 pixels-->
  <preview-image v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />
  <div class="container-formulario">
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Produtos Base</h1>
      </div>

      <router-link to="/produtos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="flex items-end mb-3 gap-8">
      <div class="w-52">
        <label class="text-xl text-corBase">Produto:</label>

        <div class="w-full">
          <v-select class="" v-model="tipo_produto"
            :options="['Perfil', 'Chapa', 'Vidro', 'Uso Consumo', 'Componente', 'Serviço']"></v-select>
        </div>

      </div>

      <div>
        <label class="text-xl text-corBase">Código Base:</label>
        <input type="text" class="input-form-chapa" v-model="codigo_base">
      </div>
      <div>
        <button @click="$router.push('/produto-base/importacao-base')" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 ">Importar Produtos</button>
      </div>


      <div class="flex items-end gap-3">

        <div class="flex gap-8">
          <div>
            <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="getListaProdutos(paginaAtual)">Pesquisar
            </button>
          </div>
        </div>
        <div>
          <button @click="$router.push(`/produto-base/cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 ">Adicionar
          </button>
        </div>
      </div>
    </div>


    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              #
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Tipo Produto
            </th>
            <th class="px-6 py-3">
              COD. Base
            </th>
            <th class="px-6 py-3">
              Nome Base
            </th>
            <th scope="col" class="px-6 py-3">
              Unidade Medida
            </th>
            <th scope="col" class="px-6 py-3">
              Peso
            </th>
            <th scope="col" class="px-6 py-3">
              Imagem
            </th>
            <th v-if="isadmin === true" scope="col" class="px-6 py-3">
              Status
            </th>
            <th v-if="isadmin === true" scope="col" class="px-6 py-3">
              Ação
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(produto, index) in lista_produto_base" :key="index" class="escopo-tabela">

            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                  v-model="produto.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-xs text-center">
              {{ produto.tipo_produto ? produto.tipo_produto.nome : 'Nome não disponível' }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ produto.codigo_base }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ produto.nome_base }}
            </td>
            <td v-if="produto.unidade_medida !== null" class="px-6 py-2 text-xs">
              {{ produto.unidade_medida.nome }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ produto.peso_base }}
            </td>
            <td class="px-6 py-2 text-xs">
              <button v-if="produto.imagem && produto.imagem.url_documento"
                @click.prevent="openPreviewImage(produto.imagem.url_documento)">
                <img class="w-12" :src="produto.imagem.url_documento" :alt="produto.imagem.descricao" />
              </button>
              <span v-else>Sem imagem</span>
            </td>


            <template v-if="produto.ativo === true && isadmin === true">
              <td class="px-6 py-2 text-xs">
                <i class="fa-solid fa-circle-check  text-ativo ml-3"></i>
              </td>
            </template>
            <template v-if="produto.ativo === false && isadmin === true">
              <td class="px-6 py-2">
                <i class="fa-solid fa-circle-xmark text-inativo ml-3"></i>
              </td>
            </template>

            <td class="px-6 py-2 text-xs" v-if="isadmin === true">
              <editar-produto-base :produto="produto" :paginaAtual="paginaAtual"></editar-produto-base>
            </td>

          </tr>
        </tbody>
      </table>

      <!-- Paginação -->
      <Pagination 
        v-if="lista_produto_base.length > 0" 
        :offset="paginaAtual" 
        :total="countItens" 
        :limit="20"
        @change-page="mudarPagina" 
      />
    </div>
  </div>
  <!-- Cabeçalho lista de pessoas depois de 768 pixels-->


</template>

<script>
import axios from "axios";
import vSelect from 'vue-select'
import EditarProdutoBase from "@/components/midais/Editar/produtos/EditarProdutoBase";
import PreviewImage from "@/components/midais/ged/PreviewImage";
import Pagination from "@/utils/Pagination.vue";

export default {
  name: "ListaProdutoBase",

  components: {
    EditarProdutoBase,
    vSelect,
    PreviewImage,
    Pagination
  },

  data() {
    return {
      checkAll: false,
      // lista usada para salvar o retorno da requisição do get
      lista_produto_base: [],
      // lista usada para salvar esse retorno depois de dividido
      listaProdutoBasePaginada: [],
      // varival que inicializa o numero de paginas
      itemsPorPagina: 5,
      tipo_produto: '',
      nome_produto: '',
      codigo_base: '',
      preview_imagem: '',

      countItens: 0,
      paginaAtual: 1,

      error: []
    }
  },

  created() {
  },

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },

    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },
  methods: {

    mudarPagina(pagina) {
      this.paginaAtual = pagina;
      this.getListaProdutos(pagina);
    },

    closed_preview_image() {
      this.preview_imagem = ''
    },
    openPreviewImage(url) {
      this.preview_imagem = url
    },
    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    async getListaProdutos(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get(
          `produtos/produto?tipo_produto=${this.tipo_produto}&nome_base=${this.nome_produto}&codigo_base=${this.codigo_base}&page=${pagina}`,
          {
            headers: {
              Authorization: `Token ${token}`
            }
          }
        );

        this.lista_produto_base = response.data.results;
        this.countItens = response.data.count;

      } catch (error) {
        console.error("Erro ao buscar produtos:", error);
      }
    }
  }
}
</script>

<style lang="scss"></style>