<template>

  <cadastro-imagem-componente />

</template>

<script>
import CadastroImagemComponente from "@/components/ged/CadastroImagemComponente";

export default {
  name: "CadastroImagem",
  components: {
    CadastroImagemComponente
  }
}
</script>

<style lang="scss">

</style>